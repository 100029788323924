import React, { Component } from "react";
import { NavigationType } from "@custom-types/NavigationType";
import { Platform, ViewStyle } from "react-native";
import i18n from "@i18n/i18n";
import { StyleSheet, View } from "react-native";
import { settings } from "@styles/globalStyles";
import ImageBase from "@components/image/ImageBase";
import FlatListBase from "@base/FlatListBase";
import { HomeNavigatorScreens } from "@navigation/HomeNavigator";
import * as Linking from "expo-linking";
import store from "@store/index";
import { loading, ready, showSnackbar } from "@store/actions/global";
import SocialNetworkService from "@screens/social/service/SocialNetworkService";
import { Banner } from "@custom-types/BannerModel";
import { setButtonActionsType } from "@store/actions/wallet";
import { ButtonActionsType } from "@components/wallet/WalletsButtons";
import { ModuleControlService, Services } from "@core/services/ModuleControlService";
import { TabsNavigatorScreens } from "@navigation/TabsNavigator";
import PressableBase from "@base/PressableBase";
import { connect } from "react-redux";

interface Props {
    navigation: NavigationType;
    containerStyle?: ViewStyle;
    banners: Array<Banner>;
}

const { t } = i18n;

class _BannersSection extends Component<Props> {
    constructor(props: Props) {
        super(props);
        this.onPress = this.onPress.bind(this);
        this.openChat = this.openChat.bind(this);
        this.openExternalLink = this.openExternalLink.bind(this);
        this.openXoConnect = this.openXoConnect.bind(this);
    }

    onPress(item: Banner) {
        if (item?.action == "chat") {
            this.openChat(item?.chatUser || "");
            return;
        }

        if (item?.action == "buy") {
            if (!ModuleControlService.getInstance().isServiceEnabled(Services.buyService)) {
                return;
            }
            this.navigateToWallet(ButtonActionsType.BUY);
            return;
        }

        if (item?.action == "sell") {
            if (!ModuleControlService.getInstance().isServiceEnabled(Services.sellService)) {
                return;
            }
            this.navigateToWallet(ButtonActionsType.SELL);
            return;
        }

        switch (true) {
            case item?.xoConnect:
                this.openXoConnect(item);
                break;
            case item.externalLink:
                this.openExternalLink(item);
                break;
            default:
                return;
        }
    }

    openXoConnect(item) {
        this.props.navigation.navigate(HomeNavigatorScreens.XoConnectScreen.routeName, {
            ...item,
        });
    }

    openExternalLink(item) {
        if (Platform.OS == "web") {
            window.open(item.url, "_blank");
        } else {
            Linking.openURL(item.url);
        }
    }

    async navigateToWallet(type: ButtonActionsType) {
        store.dispatch(loading());
        this.props.navigation.navigate(TabsNavigatorScreens.Wallets.routeName);
        store.dispatch(setButtonActionsType(type));
        setTimeout(() => {
            this.props.navigation.navigate("Wallets", { screen: "SelectCurrencyButtons" });
            store.dispatch(ready());
        }, 500);
    }

    async openChat(client_id) {
        try {
            store.dispatch(loading());
            var status = await SocialNetworkService.getInstance().contactUser(client_id);
            if (status == "redirect") {
                this.props.navigation.navigate("Messages", {
                    screen: "Chat",
                });
            }
            store.dispatch(ready());
        } catch (e) {
            store.dispatch(ready());
            store.dispatch(
                showSnackbar({
                    type: "ERROR",
                    message: t("an_error_has_occurred"),
                }),
            );
        }
    }

    renderBanner = ({ item }) => {
        return (
            <PressableBase style={styles.card} onPress={() => this.onPress(item)}>
                <ImageBase style={{ height: 80 }} uri={item.image.carousel} autoSize={true}></ImageBase>
            </PressableBase>
        );
    };

    render() {
        return (
            <View>
                {this.props.banners?.length > 0 && (
                    <View style={this.props.containerStyle}>
                        <FlatListBase
                            data={this.props.banners}
                            renderItem={this.renderBanner}
                            keyExtractor={(item) => item.id}
                            contentContainerStyle={{}}
                            initialNumToRender={2}
                            horizontal={true}
                            maxToRenderPerBatch={10}
                            showsHorizontalScrollIndicator={false}
                        />
                    </View>
                )}
            </View>
        );
    }
}

const styles = StyleSheet.create({
    card: {
        flex: 1,
        borderRadius: settings.cardRadius,
        overflow: "hidden",
        marginRight: 10,
    },
});

const mapStateToProps = (state) => {
    return {
        banners: state.banners?.banners,
    };
};

const mapDispatchToProps = (dispatch) => ({});

const BannersSection = connect(mapStateToProps, mapDispatchToProps)(_BannersSection);

export default BannersSection;
