import React, { Component } from "react";
import { StyleSheet, View } from "react-native";
import { connect } from "react-redux";
import i18n from "@i18n/i18n";
import { trimHelper } from "@utils/helpers/chat/chat.helper";
import ImageBase from "@components/image/ImageBase";
import { colors, settings } from "@styles/globalStyles";
import RegularText from "@base/RegularText";
import AvatarBase from "@components/avatar/AvatarBase";
import { NavigationType } from "@custom-types/NavigationType";
import Row from "@base/Row";
import { Blog } from "@custom-types/BlogModel";
import { BlogNavigatorScreens } from "@navigation/BlogNavigator";
import BoldText from "@base/BoldText";
import moment from "moment";
import PressableBase from "@base/PressableBase";
import { LinearGradient } from "expo-linear-gradient";
import { getColorOpacity } from "@utils/helpers/global/global";

interface Props {
    navigation: NavigationType;
    blog: Blog;
}

interface State {}

const { t } = i18n;

export class _BlogCard extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.navigateToBlog = this.navigateToBlog.bind(this);
        this.state = {};
    }

    navigateToBlog() {
        this.props.navigation.navigate("Blogs", {
            screen: BlogNavigatorScreens.Blog.routeName,
            params: { blog: this.props.blog },
        });
    }

    render() {
        return (
            <PressableBase
                onPress={this.navigateToBlog}
                style={{
                    borderRadius: settings.cardRadius,
                    marginRight: 10,
                    overflow: "hidden",
                    flex: 1,
                }}
            >
                <ImageBase
                    style={{ height: 350, width: 350, resizeMode: "cover" }}
                    uri={this.props.blog.image?.square || ""}
                ></ImageBase>
                <LinearGradient
                    style={{
                        position: "absolute",
                        width: "100%",
                        height: "100%",
                    }}
                    colors={[
                        getColorOpacity("#000000", 0.5),
                        "transparent",
                        "transparent",
                        getColorOpacity("#000000", 0.5),
                    ]}
                >
                    <View style={{ position: "absolute", bottom: 15, left: 10 }}>
                        <View style={{ paddingRight: 15 }}>
                            <BoldText fontSize={24} color={colors.white}>
                                {trimHelper(this.props.blog?.title, 45)}
                            </BoldText>
                        </View>

                        {this.props.blog?.tags && (
                            <Row style={{ paddingTop: 10 }}>
                                {this.props.blog?.tags?.split(",").map((h) => {
                                    return (
                                        <View style={styles.tag} key={`${h}${Math.random()}`}>
                                            <RegularText color={colors.white} fontSize={12}>
                                                {"#"}
                                                {h}
                                            </RegularText>
                                        </View>
                                    );
                                })}
                            </Row>
                        )}
                    </View>
                </LinearGradient>

                <View style={{ position: "absolute", top: 15, left: 10 }}>
                    <Row style={{ alignItems: "center" }}>
                        <AvatarBase
                            alias={this.props.blog?.author?.alias}
                            uri={this.props.blog?.author?.profileImagePath?.thumbnail}
                            size={25}
                        ></AvatarBase>
                        <BoldText style={{ paddingLeft: 6 }} fontSize={16} color={colors.white}>
                            <RegularText fontSize={16} color={colors.white}>
                                {"By "}
                            </RegularText>
                            {trimHelper(this.props.blog?.author?.alias, 18)}
                        </BoldText>
                    </Row>
                </View>

                <View style={{ position: "absolute", top: 5, right: 10 }}>
                    <View style={{ justifyContent: "center", flex: 1 }}>
                        <BoldText color={colors.white} align="center" fontSize={30}>
                            {moment(this.props.blog?.createdAt).format("D")}
                        </BoldText>
                        <BoldText color={colors.white} numberOfLines={1} fontSize={14} align="center">
                            {moment(this.props.blog?.createdAt).format("MMMM").toUpperCase()}
                        </BoldText>
                    </View>
                </View>
            </PressableBase>
        );
    }
}

const styles = StyleSheet.create({
    tag: {
        color: colors.white,
        backgroundColor: colors.complementary,
        marginRight: 10,
        paddingHorizontal: 10,
        paddingVertical: 2,
        borderRadius: 10,
        opacity: 0.9,
    },
});

const mapStateToProps = (state) => {
    return {
        client: state.auth.client,
    };
};

const mapDispatchToProps = (dispatch) => ({});

const BlogCard = connect(mapStateToProps, mapDispatchToProps)(_BlogCard);

export default BlogCard;
