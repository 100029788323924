import React, { Component } from "react";
import { AppState, Linking, Platform, ScrollView, StyleSheet, View } from "react-native";
import { NavigationType } from "@custom-types/NavigationType";
import { HeaderType } from "@custom-types/HeaderType";
import ScreenWrapper from "@components/wrapper/ScreenWrapper";
import Header from "@components/header/Header";
import Container from "@base/Container";
import { connect } from "react-redux";
import i18n from "@i18n/i18n";
import { Client } from "@custom-types/Client";
import { colors } from "@styles/globalStyles";
import InlineButton from "@base/InlineButton";
import { CartRedeemable, EXPERIENCE, REDEEMABLE } from "@custom-types/ExpercienceModel";
import BoldText from "@base/BoldText";
import Row from "@base/Row";
import store from "@store/index";
import {
    hideModalBottom,
    hidePopupMessage,
    loading,
    ready,
    showModalBottom,
    showPopupMessage,
    showSnackbar,
} from "@store/actions/global";
import FlatListBase from "@base/FlatListBase";
import RedeemableOptionCard from "./components/RedeemableOptionCard";
import RedeemableDetailModal from "./components/RedeemableDetailModal";
import RegularText from "@base/RegularText";
import Card from "@base/Card";
import Icon from "@components/icons";
import ExperienceService from "@core/services/ExperienceService";
import { emptyRedeemableCart } from "@store/actions/experiences.actions";
import CurrencyIcon from "@components/accessories/CurrencyIcon";
import SelectCurrencyModal from "./components/SelectCurrencyModal";
import Wallet from "@core/wallet/Wallet";
import SemiBoldText from "@base/SemiBold";
import { FiatCurrency } from "@core/fiat/FiatCurrency";
import Currency from "@core/currencies/Currency";
import CurrencyBalanceIcon from "@components/accessories/CurrencyBalanceIcon";
import ActivityService from "@core/services/ActivityService";
import { OrderModel } from "@custom-types/ActivityModel";
import ExpirationComponent from "./components/ExpirationComponent";

interface Props {
    navigation: NavigationType;
    client: Client;
    checkoutRedeemables: Array<CartRedeemable>;
    selectedCurrency: { currency: Currency | FiatCurrency; gatewayId: string };
    route: any;
}

interface State {
    checkoutRedeemables: Array<CartRedeemable>;
    total: number;
    experience: any;
    gatweyName: string;
    checkOutId: string;
    appState;
    expirationDate: string;
}

const { t } = i18n;

export class _CheckoutScreen extends Component<Props, State> {
    focusListener;
    statusCallback;
    statusCallbackArray: Array<any> = [];
    appState;
    constructor(props: Props) {
        super(props);
        this.completePurchase = this.completePurchase.bind(this);
        this.clearWatcher = this.clearWatcher.bind(this);
        this.getStatusAsync = this.getStatusAsync.bind(this);
        this.state = {
            checkoutRedeemables: this.props.checkoutRedeemables,
            total: 0,
            experience: this.props.route?.params?.experience,
            gatweyName: "",
            checkOutId: "",
            appState: null,
            expirationDate: "",
        };
    }

    componentDidMount() {
        this.setGetway();
        // if (Platform?.OS == "web") {
        //     this.focusListener = document.addEventListener("visibilitychange", () => {
        //         if (this.state.checkOutId?.length > 5) {
        //             this.getStatusAsync();
        //         }
        //     });
        //     return;
        // }

        // this.appState = AppState.addEventListener("change", (nextAppState) => {
        //     if (this.state.checkOutId?.length > 5 && nextAppState === "active") {
        //         this.getStatusAsync();
        //     }
        // });
    }

    componentDidUpdate(props) {
        if (props.selectedCurrency.currency != this.props.selectedCurrency.currency) {
            this.setGetway();
        }
    }

    componentWillUnmount() {
        this.clearWatcher();
    }

    clearWatcher() {
      
        try {
            this.setState({
                checkoutRedeemables: this.props.checkoutRedeemables,
                total: 0,
                experience: this.props.route?.params?.experience,
                gatweyName: "",
                checkOutId: "",
                appState: null,
                expirationDate: "",
            });
          
            this.statusCallbackArray?.map((t) => {
                try {
                    
                    const clear = clearTimeout(t);
                
                } catch (e) {
                  
                }
            });
          
            if (this.focusListener) this.focusListener.remove();
            if (this.appState) this.appState.remove();
        } catch (e) {
            console.warn(e);
        }
    }

    renderRedeemable = ({ item }) => {
        return item.quantity > 0 ? (
            <RedeemableOptionCard
                quantity={item.quantity}
                redeemable={item.redeemable}
                onPress={() => this.redeemableDetail(item.redeemable)}
                fromExperience={false}
                currency={this.props.selectedCurrency.currency}
            />
        ) : null;
    };

    redeemableDetail = (redeemable: REDEEMABLE) => {
        store.dispatch(
            showModalBottom({
                modalContent: <RedeemableDetailModal navigation={this.props.navigation} redeemable={redeemable} />,
            }),
        );
    };

    getPrice = () => {
        let total = 0;
        this.state.checkoutRedeemables.map((item) => {
            if (item.redeemable?.prices) {
                total +=
                    item.quantity *
                    Number(
                        item.redeemable.prices[
                        this.props.selectedCurrency.currency instanceof Currency
                            ? "digitalCurrency"
                            : "fiatCurrency"
                        ][this.props.selectedCurrency.currency.getId()],
                    );
            }
        });
        return total.toString();
    };

    getQuantity = () => {
        let total = 0;
        this.state.checkoutRedeemables.map((item) => {
            if (item.quantity) {
                total += item.quantity;
            }
        });
        return total;
    };

    checkQuantity = (redeemables: Array<CartRedeemable>) => {
        return redeemables.filter((item) => item.quantity > 0);
    };

    setGetway = () => {
        this.state.experience.merchant.gateways.map((item: any) => {
            if (this.props.selectedCurrency.gatewayId == item.gateway._id) {
                this.setState({ gatweyName: item.gateway.name });
            }
        });
    };

    completePurchase = async () => {
        const redeemables = this.checkQuantity(this.state.checkoutRedeemables);

        if (redeemables.length) {
            try {
                store.dispatch(loading());
                // store.dispatch(
                //     showPopupMessage({
                //         type: "LOADING",
                //         message: t("processing_payment"),
                //     }),
                // );

                const checkout = await ExperienceService.getInstance().checkout({
                    currency: {
                        id: this.props.selectedCurrency.currency.getId(),
                        type: this.props.selectedCurrency.currency instanceof FiatCurrency ? "fiat" : "digital",
                    },
                    gateway: this.props.selectedCurrency.gatewayId,
                    items: (redeemables || []).map((i) => {
                        return { id: i.redeemable._id, quantity: i.quantity };
                    }),
                });

                if (checkout) {
                    store.dispatch(ready());
                    const expirationTime = new Date(new Date().getTime() + checkout.expirationTime).getTime();

                    store.dispatch(
                        showModalBottom({
                            modalContent: (
                                <ExpirationComponent
                                    navigation={this.props.navigation}
                                    expirationTime={expirationTime}
                                />
                            ),
                        }),
                    );

                    this.setState({ checkOutId: checkout?._id }, () => {
                        this.getStatusAsync();
                    });
                    await Linking.openURL(checkout?.payment.externalUrl);
                }
            } catch (error) {
                console.error(error);
                store.dispatch(ready());
            }
        } else {
            store.dispatch(showSnackbar({ type: "MESSAGE", message: t("add_item_warning") }));
        }
    };

    async getStatusAsync() {
        const order: OrderModel = await ExperienceService.getInstance().getOrder(this.state.checkOutId);
        if (!order) {
            return;
        }

        try {
            this.statusCallbackArray?.map((t) => {
                return clearTimeout(t);
            });
        } catch { }
        switch (order?.status) {
            case "completed":
                store.dispatch(hideModalBottom());
                setTimeout(() => {
                    store.dispatch(loading());
                }, 200);

                this.setState({ checkOutId: "" });
                this.clearWatcher();
                await ExperienceService.getInstance().getRedeemables();
                ActivityService.getInstance().getActivity();

                return setTimeout(() => {
                    this.props.navigation.navigate("HomeMain");
                    this.props.navigation.navigate("Home", {
                        screen: "Experience",
                    });
                    store.dispatch(ready());
                    store.dispatch(
                        showPopupMessage({
                            type: "SUCCESS",
                            title: t("congratulations"),
                            message: t("enjoy_purchase"),
                            onPressAccept: () => {
                                store.dispatch(emptyRedeemableCart());
                                store.dispatch(hidePopupMessage());
                            },
                        }),
                    );
                }, 1250);
                break;

            case "pending":
                store.dispatch(hideModalBottom());
                ExperienceService.getInstance().getRedeemables();
                ActivityService.getInstance().getActivity();

                store.dispatch(
                    showPopupMessage({
                        type: "SUCCESS",
                        title: t("congratulations"),
                        message: t("payment_pending"),
                        onPressAccept: () => {
                            store.dispatch(emptyRedeemableCart());
                            store.dispatch(hidePopupMessage());
                            this.props.navigation.navigate("HomeMain");
                            this.props.navigation.navigate("Home", {
                                screen: "Activity",
                            });
                        },
                    }),
                );

                break;

            case "created":
                let interval = setTimeout(() => {
                    this.getStatusAsync();
                }, 15000);
              
                this.statusCallbackArray.push(interval);

                break;

            case "canceled":
                store.dispatch(hideModalBottom());

                store.dispatch(
                    showPopupMessage({
                        type: "ERROR",
                        message: t("payment_error"),
                        onPressAccept: () => {
                            store.dispatch(hidePopupMessage());
                        },
                    }),
                );

                break;
        }
    }

    showSelectCurrency() {
        store.dispatch(
            showModalBottom({
                modalContent: (
                    <SelectCurrencyModal
                        fiatCurrency={this.state?.experience?.availableFiatCurrencies}
                        currencies={this.state?.experience?.availableDigitalCurrencies}
                    />
                ),
            }),
        );
    }

    render() {
        return (
            <ScreenWrapper>
                <Header title="Checkout" type={HeaderType.Light} {...this.props} />
                <ScrollView>
                    <Container style={{ flex: 1 }}>
                        <View style={{ marginBottom: 20, marginTop: 10 }}>
                            <Row style={{ flex: 1, justifyContent: "space-between", width: "100%" }}>
                                <SemiBoldText style={{ marginBottom: 10 }}>{t("payment_method")}</SemiBoldText>
                            </Row>
                            {this.state.experience?.availableFiatCurrencies?.length +
                                this.state.experience?.availableDigitalCurrencies?.length >
                                1 ? (
                                <Card onPress={() => this.showSelectCurrency()}>
                                    <Row
                                        style={{ justifyContent: "space-between", alignItems: "center", width: "100%" }}
                                    >
                                        <View style={{ flex: 1, flexDirection: "row", alignItems: "center", gap: 14 }}>
                                            <CurrencyIcon
                                                size={28}
                                                iconSize={20}
                                                currency={this.props.selectedCurrency.currency}
                                            ></CurrencyIcon>
                                            <View>
                                                <SemiBoldText>
                                                    {this.props.selectedCurrency.currency.getSymbol()}
                                                </SemiBoldText>
                                                <RegularText fontSize={14} color={colors.grey}>
                                                    {this.state.gatweyName}
                                                </RegularText>
                                            </View>
                                        </View>
                                        <Icon name="right" size={16} color={colors.text} />
                                    </Row>
                                </Card>
                            ) : (
                                <Card>
                                    <Row
                                        style={{ justifyContent: "space-between", alignItems: "center", width: "100%" }}
                                    >
                                        <View style={{ flex: 1, flexDirection: "row", alignItems: "center", gap: 14 }}>
                                            <CurrencyIcon
                                                size={28}
                                                iconSize={20}
                                                currency={this.props.selectedCurrency.currency}
                                            ></CurrencyIcon>
                                            <View>
                                                <SemiBoldText>
                                                    {this.props.selectedCurrency.currency.getSymbol()}
                                                </SemiBoldText>
                                                <RegularText fontSize={14} color={colors.grey}>
                                                    {this.state.gatweyName}
                                                </RegularText>
                                            </View>
                                        </View>
                                    </Row>
                                </Card>
                            )}
                        </View>

                        <View style={{ marginBottom: 20 }}>
                            <SemiBoldText style={{ marginBottom: 10 }}>{t("purchase_detail")}</SemiBoldText>
                            <FlatListBase
                                data={this.state.checkoutRedeemables}
                                renderItem={this.renderRedeemable}
                                keyExtractor={(item) => item._id}
                            />
                            <Card>
                                <Row style={{ justifyContent: "space-between", width: "100%", marginBottom: 10 }}>
                                    <RegularText fontSize={14}>{t("quantity")}</RegularText>
                                    <RegularText fontSize={14}>{this.getQuantity()}</RegularText>
                                </Row>
                                <Row style={{ justifyContent: "space-between", width: "100%", marginBottom: 10 }}>
                                    <RegularText fontSize={14}>Subtotal</RegularText>
                                    <RegularText fontSize={14}>
                                        {this.getPrice()} {this.props.selectedCurrency.currency.getSymbol()}
                                    </RegularText>
                                </Row>
                                <Row style={{ justifyContent: "space-between", width: "100%", marginTop: 10 }}>
                                    <SemiBoldText>Total</SemiBoldText>
                                    <Row>
                                        {this.getPrice() == "0" && (
                                            <View style={styles.bubble}>
                                                <SemiBoldText
                                                    align="center"
                                                    fontSize={10}
                                                    color={colors.labelText || colors.white}
                                                    style={{ textTransform: "uppercase" }}
                                                >
                                                    {t("free")}
                                                </SemiBoldText>
                                            </View>
                                        )}
                                        {this.props.selectedCurrency.currency instanceof Currency ? (
                                            <CurrencyBalanceIcon
                                                amount={this.getPrice()}
                                                currency={this.props.selectedCurrency.currency}
                                                fontWeight="bold"
                                                fontSize={16}
                                                iconSize={16}
                                                style={{ alignItems: "center" }}
                                            />
                                        ) : (
                                            <CurrencyBalanceIcon
                                                amount={this.getPrice()}
                                                fiatCurrency={this.props.selectedCurrency.currency}
                                                fontWeight="bold"
                                                fontSize={16}
                                                iconSize={16}
                                                style={{ alignItems: "center" }}
                                            />
                                        )}
                                    </Row>
                                </Row>
                            </Card>
                        </View>

                        <InlineButton
                            style={{ marginVertical: 20 }}
                            title={t("complete_purchase")}
                            onPress={this.completePurchase}
                        />
                    </Container>
                </ScrollView>
            </ScreenWrapper>
        );
    }
}

const styles = StyleSheet.create({
    container: {
        flexDirection: "row",
        flex: 1,
    },
    addItem: {
        justifyContent: "center",
        alignItems: "center",
        height: 30,
        width: 30,
        backgroundColor: colors.secondary,
        borderRadius: 50,
        alignContent: "center",
        margin: "auto",
    },
    quantityContainer: {
        alignItems: "center",
        borderRadius: 6,
        height: 30,
    },
    bubble: {
        backgroundColor: colors.complementary || colors.secondary,
        paddingHorizontal: 8,
        paddingVertical: 3,
        borderRadius: 4,
        marginRight: 10,
    },
});

const mapStateToProps = (state) => {
    return {
        client: state.auth.client,
        checkoutRedeemables: state.experiences.checkoutRedeemables,
        selectedCurrency: state.experiences.selectedCurrency,
    };
};

const mapDispatchToProps = (dispatch) => ({});

const CheckoutScreen = connect(mapStateToProps, mapDispatchToProps)(_CheckoutScreen);

export default CheckoutScreen;
