import React, { Component } from "react";
import { ActivityIndicator, Platform, StyleSheet, View } from "react-native";
import { connect } from "react-redux";
import { NavigationType } from "@custom-types/NavigationType";
import Container from "@base/Container";
import i18n from "@i18n/i18n";
import { colors, settings } from "@styles/globalStyles";
import RegularText from "@base/RegularText";
import BoldText from "@base/BoldText";
import { SkypeIndicator } from "react-native-indicators";

interface Props {
    navigation: NavigationType;
    expirationTime: number;
}

interface State {
    expirationDate: string;
}

const { t } = i18n;

export class _ExpirationComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            expirationDate: ''
        };
    }

    componentDidMount(): void {
        const timer = setInterval(() => {
            const now = new Date().getTime();
            const distance = this.props.expirationTime - now;
            const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            const seconds = Math.floor((distance % (1000 * 60)) / 1000);
            this.setState({ expirationDate: `${hours}h ${minutes}m ${seconds}s` })

            if (distance < 0) {
                clearInterval(timer);
            }
        }, 1000);
    }

    render() {
        return (
            <Container style={{ flex: 1 }}>
                <View style={{ marginBottom: 20 }}>
                    <View>
                        <View style={{ padding: 20, alignItems: "center" }}>
                            <View style={{ paddingVertical: 10 }}>
                                <BoldText align="center" style={{ paddingBottom: 20 }}>
                                    {t('expire')}
                                </BoldText>
                                <BoldText align="center" fontSize={20}>
                                    {this.state.expirationDate}
                                </BoldText>
                            </View>
                            <View style={{ height: 80, alignItems: "center", justifyContent: "center", marginVertical: 20 }}>
                                {Platform.OS == "web" ? (
                                    <ActivityIndicator size={60} color={colors.complementary} />
                                ) : (
                                    <SkypeIndicator
                                        size={60}
                                        animationDuration={2000}
                                        color={colors.complementary}
                                    />
                                )}
                            </View>
                            <View style={{ paddingVertical: 10 }}>
                                <RegularText align="center" fontSize={14}>
                                    {t('order_created')}
                                </RegularText>
                            </View>
                        </View>
                    </View>
                </View>
            </Container>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        data: state.experiences.experiences.docs,
        client: state.auth.client,
    };
};

const mapDispatchToProps = (dispatch) => ({});

const EExpirationComponent = connect(mapStateToProps, mapDispatchToProps)(_ExpirationComponent);

export default EExpirationComponent;

const styles = StyleSheet.create({
    filterContainer: {
        width: '100%',
        paddingVertical: 10
    },
    dateCard: {
        flex: 1,
        backgroundColor: colors.shadow,
        borderRadius: settings.cardRadius,
        paddingHorizontal: 15,
        paddingVertical: 14,
        marginTop: 5
    },
    tag: {
        justifyContent: "center",
        alignItems: "center",
        width: "auto",
        backgroundColor: colors.complementary,
        borderRadius: settings.cardRadius,
        paddingHorizontal: 10,
        paddingVertical: 5,
        marginTop: 10
    }
});
