import React, { Component } from "react"
import { StyleSheet, View, Image } from "react-native"
import { NavigationType } from "@custom-types/NavigationType"
import { connect } from "react-redux"
import i18n from "@i18n/i18n"
import RegularText from "@base/RegularText"
import Card from "@base/Card"
import { colors } from "@styles/globalStyles"
import moment from "moment"
import { SemiBoldText } from "@base/SemiBold"
import store from "@store/index"
import { showModalBottom } from "@store/actions/global"
import ActivityRedeemableModal from "./ActivityRedeemableModal"
import { ActivityModel } from "@custom-types/ActivityModel"



interface Props {
    navigation: NavigationType
    activity: ActivityModel
}

interface State { }

const { t } = i18n

export class _ActivityCard extends Component<Props, State> {

    constructor(props: Props) {
        super(props)

        this.state = {}
    }

    openModal = () => {
        store.dispatch(
            showModalBottom({
                modalContent: (
                    <ActivityRedeemableModal activity={this.props.activity} />
                ),
            }),
        );
    }

    render() {
        return (
            <Card
                onPress={this.openModal}
                style={{ alignItems: "center" }}
                icon={
                    <Image
                        style={{
                            width: 28,
                            height: 28,
                            borderRadius: 50
                        }}
                        resizeMode="cover"
                        source={
                            this.props.activity?.icon
                                ? { uri: this.props.activity?.icon }
                                : require("@assets/images/placeholder_image.png")
                        }
                        onError={() => this.setState({ sourceError: true })}
                        fadeDuration={0}
                    />
                }
                left={
                    <View style={{ marginLeft: 10, justifyContent: "center" }}>
                        <SemiBoldText fontSize={14} style={{ marginBottom: 2 }}>{t(this.props.activity?.title)}</SemiBoldText>
                        <RegularText fontSize={12} style={{ marginTop: 2 }} color={colors.grey}>
                            {moment(this.props.activity?.createdAt).format("LL")}
                        </RegularText>
                    </View>
                }
                right={
                    <View style={{ justifyContent: "flex-end", alignItems: "flex-end" }}>
                        <SemiBoldText fontSize={14} style={{ marginBottom: 2 }}>{this.props.activity?.detail}</SemiBoldText>
                    </View>
                }
            />
        )
    }
}

const styles = StyleSheet.create({
})

const mapStateToProps = (state) => {
    return {}
}

const mapDispatchToProps = (dispatch) => ({})

const ActivityCard = connect(mapStateToProps, mapDispatchToProps)(_ActivityCard)

export default ActivityCard
