import { injectWindowPostMessage } from "@screens/dapps/carnaval/carnaval.helper";
import { colors } from "@styles/globalStyles";
import React, { Component } from "react";
import { Platform, RefreshControl, ScrollView, TextStyle, View, ViewStyle } from "react-native";
import { WebView } from "react-native-webview";
import WebViewBase from "./WebViewBase";
import XOConnectService from "@core/services/XOConnectService";
import { connect } from "react-redux";
import { Client } from "@custom-types/Client";

interface Props {
    source: string
    client: Client
}

interface State {
    loading: boolean;
    enabledReload: boolean;
}

class _WebViewXOConnect extends Component<Props, State> {
    WebViewBaseRef: WebViewBase;
    XOService: XOConnectService;

    constructor(props: Props) {
        super(props);
        this.listenOnMessage = this.listenOnMessage.bind(this);
    }

    componentDidMount(): void {
     
    }

    listenOnMessage = (payload) => {
  
        
        this.XOService.handleRequests(payload)
    };
  

    render() {
        return (
            <WebViewBase
                ref={(r) => {
                    this.WebViewBaseRef = r;
                    this.XOService = new XOConnectService(r, this.props.client)
                }}
                onMessage={this.listenOnMessage}
                source={this.props.source}
                onLoadJavascript={XOConnectService.xoConnectListener(this.WebViewBaseRef)}
                style={{ flex: 1}}
            ></WebViewBase>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        client: state.auth.client,
    };
};

const mapDispatchToProps = (dispatch) => ({});

const WebViewXOConnect = connect(mapStateToProps, mapDispatchToProps)(_WebViewXOConnect);

export default WebViewXOConnect;
