import {
    ADD_CHATS,
    ADD_CHAT,
    SELECT_CHAT,
    SELECT_CONTACT,
    SET_CHAT_STATUS,
    SET_CHATS,
    SET_CONTACTS,
    SET_CUSTOM_MESSAGE,
    SET_UNREAD_MESSAGES, UPDATE_CHAT, UPDATE_ORDER,
} from "@store/actions/chat.actions";
import { Chat } from "@custom-types/Chat";
import { Contact } from "@custom-types/Contact";
import { CustomMessageModel } from "@custom-types/CustomMessageModel";
import { Message } from "@custom-types/Message";
import { createSelector } from "reselect";
import { RELOAD } from "@store/actions/global";

interface chatState {
    selected: Chat;
    items: {};
    order: Array<string>;
    status: string;
    contacts: Array<Contact>;
    selectContact: Contact;
    customMessage: CustomMessageModel;
    unread: any;
    page: number;
}

const initialState: chatState = {
    selected: null,
    items: {},
    order: [],
    status: "disconnected",
    contacts: [],
    selectContact: null,
    customMessage: null,
    unread: 0,
    page: 1
};

const chatReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADD_CHAT:
            return {
                ...state,
                items: {...state.items, ...{[action.chat.id]: action.chat}},
                order: [ ...new Set ([...state.order, action.chat.id])]
            }

        case ADD_CHATS:
            return {
                ...state,
                items: {...state.items, ...action.items},
                order: [ ...new Set([...state.order, ...action.order])]
            }

        case UPDATE_CHAT:
            return {
                ...state,
                items: {...state.items, ...{[action.chat.id]: action.chat}}
            }

        case UPDATE_ORDER:
            return  {
                ...state,
                order: action.order
            }


        case SELECT_CHAT:
            return {
                ...state,
                selected: action.chat,
            };

        case SET_CHATS:
            return {
                ...state,
                items: {...action.items},
                order: action.order
            };

        case SET_CHAT_STATUS:
            return {
                ...state,
                status: action.status,
            };

        case SET_UNREAD_MESSAGES:
            return {
                ...state,
                unread: action.unread,
            };

        case SET_CONTACTS:
            return {
                ...state,
                contacts: action.contacts,
            };

        case SELECT_CONTACT:
            return {
                ...state,
                selectContact: action.contact,
            };

        case SET_CUSTOM_MESSAGE:
            return {
                ...state,
                customMessage: action.customMessage,
            };

        case RELOAD:
            return {
                ...initialState,
            };

        default:
            return state;
    }
};

const chatsSelector = state => state.chat.items;
const orderChatSelector = state => state.chat.order;
const messagesSelector = state => state.message.items;

export const chatSelectedSelector = state => state.chat.selected;
export const chatByIdSelector = (state, id) => state.chat.items[id.toString()];
export const selectChatsAsArray = state => Object.values(state.chat.items);

export const selectOrderedAndFilteredChats = createSelector(
    chatsSelector,
    messagesSelector,
    (chats, messages) => {
        //@ts-ignore
        const filteredChat: Array<any> = Object.values(chats).filter(c => c.messages.length > 0);
        const chatWithLastMessage = filteredChat.map(c => {
            const firstMessageId = c.messages[0] || null;
            if(firstMessageId) c.lastMessage = messages[firstMessageId]?.createdAt || null;
            return c;
        });
        //@ts-ignore
        return  chatWithLastMessage.sort((a,b) => new Date(b.lastMessage) - new Date(a.lastMessage));
    }
)

export const selectMessagesFromSelectedChat = createSelector(
    chatSelectedSelector,
    messagesSelector,
    (chat: Chat, messages) => {
        const uniqueMessageIds = [...new Set(chat.messages)];
        const uniqueMessages = uniqueMessageIds.map(id => messages[id]).filter(Boolean);
        return uniqueMessages;
    }
)

export const selectMessagesFromChat = createSelector(
    chatByIdSelector,
    messagesSelector,
    (chat: Chat, messages) => {
        const uniqueMessageIds = [...new Set(chat.messages)];
        const uniqueMessages = uniqueMessageIds.map(id => messages[id]).filter(Boolean);
        return uniqueMessages;
    }
)


export const selectSerializedMessagesFromSelectedChat = createSelector(
    chatSelectedSelector,
    messagesSelector,
    selectMessagesFromSelectedChat,
    (s, m, messages) => messages.map((m: Message) => m.serialized)
)


export default chatReducer;
