import Currency from "@core/currencies/Currency";
import Wallet from "@core/wallet/Wallet";
import i18n from "@i18n/i18n";
import WalletConnectModal from "@screens/walletconnect/WalletConnectModal";
import { hideModalBottom, loading, ready, showModalBottom, showPopupMessage, showSnackbar } from "@store/actions/global";
import { setConnections } from "@store/actions/walletconnect.actions";
import store from "@store/index";
import { trimHelper } from "@utils/helpers/chat/chat.helper";
import { Core } from "@walletconnect/core";
import { SessionTypes } from "@walletconnect/types";
import { getSdkError } from "@walletconnect/utils";
import { IWeb3Wallet, Web3Wallet } from "@walletconnect/web3wallet";
import Constants from "expo-constants";
import React from "react";
import Web3 from "web3";

const { t } = i18n;

interface PeerMetadata {
    description: string;
    icons: string[];
    name: string;
    url: string;
    verifyUrl: string;
}

export default class WalletConnectService {
    public static instance: WalletConnectService;
    core;
    web3wallet: IWeb3Wallet;
    activeSessions;

    public static getInstance(): WalletConnectService {
        if (!WalletConnectService.instance) {
            WalletConnectService.instance = new WalletConnectService();
            WalletConnectService.instance.initCore();
        }
        return WalletConnectService.instance;
    }

    async initCore() {
        this.core = new Core({
            projectId: Constants.expoConfig?.extra?.walletconnect?.projectId,
        });

        await this.initWeb3Wallet();
        await this.getActiveSessions();
        this.addListeners();
    }

    async getSession() {
        const clientID = await this.core.crypto.getClientId();
    }

    async initWeb3Wallet() {
        const core = this.core;

        this.web3wallet = await Web3Wallet.init({
            core,
            metadata: {
                description: `${Constants.expoConfig.name} App`,
                url: `https://app.${Constants.expoConfig?.extra?.url}`,
                icons: [`https://app.${Constants.expoConfig?.extra?.url}favicon-32.png`],
                name: Constants.expoConfig.name,
            },
        });
    }

    async getActiveSessions() {
        const data = this.web3wallet.getActiveSessions();

        this.activeSessions = Object.values(data);
        this.setConnections();
    }

    async pairDapp(uri) {
        await this.core.pairing.pair({ uri: uri });
    }

    async addListeners() {
        this.web3wallet.on("session_proposal", async (proposal) => {
            this.handleSessionProposal(proposal);
        });

        this.web3wallet.on("session_delete", async (event) => {
            if (event) {
                this.onDisconnect(event);
            }
        });

        this.web3wallet.on("session_request", async (event) => {
            if (event) {
                this.getRequest(event);
            }
        });
    }

    /// HANDELE SESSIONS ///

    handleSessionProposal(currentProposal) {
        const requiredChains = currentProposal?.params?.requiredNamespaces?.eip155?.chains || [];
        let currencies: Array<Currency> = [];
        requiredChains.map((c) => {
            const currency = Wallet.getInstance().findCurrencyByChainId(parseInt(c.split(":")[1]));
            if (currency == undefined) {
                store.dispatch(
                    showPopupMessage({
                        type: "ERROR",
                        message: `Dapp is requesting a network (${c}) that is not available in this Wallet`,
                    }),
                );
            } else {
                currencies.push(currency);
            }
        });

        if (currencies?.length == 0 || currencies?.length !== requiredChains.length) {
            const optionalNamespaces = currentProposal?.params?.optionalNamespaces?.eip155?.chains || [];

            optionalNamespaces.map((c) => {
                const currency = Wallet.getInstance().findCurrencyByChainId(parseInt(c.split(":")[1]));
                if (currency == undefined) {
                    store.dispatch(
                        showPopupMessage({
                            type: "ERROR",
                            message: `Dapp is requesting a network (${c}) that is not available in this Wallet`,
                        }),
                    );
                } else {
                    currencies.push(currency);
                }
            });

            if (currencies?.length == 0) {
                this.handleRejectProposal(currentProposal);
                return;
            }
        }

        const proposer: PeerMetadata = currentProposal?.params?.proposer?.metadata;

        store.dispatch(
            showModalBottom({
                modalContent: (
                    <WalletConnectModal
                        avatar={proposer?.icons[0]}
                        title={proposer?.name}
                        description={t("wallet_connect_question", {
                            name: trimHelper(proposer?.name, 50),
                        })}
                        btnTitle={t("confirm")}
                        onPress={() => this.handleAcceptProposal(currentProposal, currencies)}
                        currencies={currencies}
                    />
                ),
            }),
        );
    }

    async handleAcceptProposal(currentProposal, currencies: Array<Currency>) {
        store.dispatch(hideModalBottom());

        const { id, params } = currentProposal;
        let { requiredNamespaces, relays, optionalNamespaces } = params;

        if (currentProposal && currencies) {
            const namespaces: SessionTypes.Namespaces = {};

            Object.keys(requiredNamespaces).forEach((key) => {
                const accounts: string[] = [];

                requiredNamespaces[key].chains.map((chain) => {
                    [
                        Wallet.getInstance()
                            .findCurrencyByChainId(parseInt(chain.split(":")[1]))
                            .getAddress(),
                    ].map((acc) => accounts.push(`${chain}:${acc}`));
                });

                if (optionalNamespaces[key]?.chains?.length > 0) {
                    const supportedNamespaces = optionalNamespaces[key].chains.filter((c) => {
                        return requiredNamespaces[key]?.chains?.includes(c)
                            ? false
                            : Wallet.getInstance().findCurrencyByChainId(parseInt(c.split(":")[1]));
                    });

                    supportedNamespaces?.map((chain) => {
                        [
                            Wallet.getInstance()
                                .findCurrencyByChainId(parseInt(chain.split(":")[1]))
                                .getAddress(),
                        ].map((acc) => accounts.push(`${chain}:${acc}`));
                    });
                }

                namespaces[key] = {
                    accounts,
                    methods: requiredNamespaces[key].methods,
                    events: requiredNamespaces[key].events,
                };
            });

            if (Object.keys(requiredNamespaces).length < 1) {
                Object.keys(optionalNamespaces).forEach((key) => {
                    const accounts: string[] = [];

                    const supportedNamespaces = optionalNamespaces[key]?.chains.filter((c) => {
                        return Wallet.getInstance().findCurrencyByChainId(parseInt(c.split(":")[1]));
                    });

                    supportedNamespaces.map((chain) => {
                        [
                            Wallet.getInstance()
                                .findCurrencyByChainId(parseInt(chain.split(":")[1]))
                                ?.getAddress(),
                        ].map((acc) => accounts.push(`${chain}:${acc}`));
                    });

                    namespaces[key] = {
                        accounts,
                        methods: ["eth_signTypedData", "eth_signTypedData_v4", "eth_sendTransaction", "personal_sign"],
                        events: ["message", "disconnect", "connect"],
                    };
                });
            }

            await this.web3wallet.approveSession({
                id,
                relayProtocol: relays[0].protocol,

                namespaces: namespaces,
            });
            await this.getActiveSessions();
        }
        store.dispatch(ready());
    }

    async handleRejectProposal(currentProposal) {
        store.dispatch(hideModalBottom());
        await this.web3wallet.rejectSession({
            id: currentProposal.id,
            reason: getSdkError("USER_REJECTED_METHODS"),
        });
    }

    /// HANDELE DISCONECT ///

    async onDisconnect(event) {
        const session = this.activeSessions.find((s) => {
            return s.topic == event?.topic;
        });
        if (!session) return;
        const proposer: PeerMetadata = session?.peer?.metadata;

        store.dispatch(
            showModalBottom({
                modalContent: (
                    <WalletConnectModal
                        avatar={proposer?.icons[0]}
                        title={proposer?.name}
                        description={t("wallet_connect_disconnected")}
                    />
                ),
            }),
        );

        this.getActiveSessions();
        setTimeout(() => {
            store.dispatch(hideModalBottom());
        }, 6000);
    }

    /// REQUEST DISCONNECT ///

    async disconnectDapp(topic) {
        try {
            await this.web3wallet.disconnectSession({
                topic,
                reason: getSdkError("USER_DISCONNECTED"),
            });
        } catch (e) { }
        this.getActiveSessions();
    }

    /// HANDLE DAPPS REQUEST ///

    async getRequest(event) {
        const isCompatibleRequest = await this.filterSessionByAddress(event);

        if (isCompatibleRequest) {
            const session = this.activeSessions.find((s) => {
                return s.topic == event?.topic;
            });

            if (!session) return;

            const currency: Currency = Wallet.getInstance().findCurrencyByChainId(
                parseInt(event?.params?.chainId.split(":")[1]),
            );

            if (!currency) return;

            const proposerData: PeerMetadata = session?.peer?.metadata;

            switch (event?.params?.request?.method) {
                case "personal_sign":
                    return store.dispatch(
                        showModalBottom({
                            modalContent: (
                                <WalletConnectModal
                                    avatar={proposerData?.icons[0]}
                                    title={proposerData?.name}
                                    description={t("personal_sign_subtitle")}
                                    subtitle={t("personal_sign_description")}
                                    message={`${t("message")}: ${Web3.utils.hexToAscii(
                                        event?.params?.request?.params[0],
                                    )}`}
                                    btnTitle={t("wallet_connect_sign")}
                                    onPress={() => this.personalSign(event, currency)}
                                    currency={currency}
                                    client={store.getState().auth.client}
                                    clientMessage={t("signed_by")}
                                />
                            ),
                            onPressClose: () => this.rejectRequest(event),
                        }),
                    );

                    break;

                case "eth_sendTransaction":
                    return store.dispatch(
                        showModalBottom({
                            modalContent: (
                                <WalletConnectModal
                                    avatar={proposerData?.icons[0]}
                                    title={proposerData?.name}
                                    description={t("eth_Transaction_subtitle")}
                                    subtitle={t("eth_Transaction_question")}
                                    btnTitle={t("continue")}
                                    onPress={() => this.ethPreSendTransaction(event, currency, proposerData)}
                                    currency={currency}
                                    client={store.getState().auth.client}
                                />
                            ),
                            onPressClose: () => this.rejectRequest(event),
                        }),
                    );

                    break;

                case "eth_signTypedData_v4":
                    return store.dispatch(
                        showModalBottom({
                            modalContent: (
                                <WalletConnectModal
                                    avatar={proposerData?.icons[0]}
                                    title={proposerData?.name}
                                    description={t("personal_sign_subtitle")}
                                    subtitle={t("personal_sign_description")}
                                    message={`${t("message")}: ${Web3.utils.hexToAscii(
                                        event?.params?.request?.params[0],
                                    )}`}
                                    btnTitle={t("wallet_connect_sign")}
                                    onPress={() => this.signTypedData(event, currency)}
                                    currency={currency}
                                    client={store.getState().auth.client}
                                    clientMessage={t("signed_by")}
                                />
                            ),
                            onPressClose: () => this.rejectRequest(event),
                        }),
                    );

                    break;

                case "eth_signTypedData":
                    return store.dispatch(
                        showModalBottom({
                            modalContent: (
                                <WalletConnectModal
                                    avatar={proposerData?.icons[0]}
                                    title={proposerData?.name}
                                    description={t("personal_sign_subtitle")}
                                    subtitle={t("personal_sign_description")}
                                    message={`${t("message")}: ${Web3.utils.hexToAscii(
                                        event?.params?.request?.params[0],
                                    )}`}
                                    btnTitle={t("wallet_connect_sign")}
                                    onPress={() => this.signTypedData(event, currency)}
                                    currency={currency}
                                    client={store.getState().auth.client}
                                    clientMessage={t("signed_by")}
                                />
                            ),
                            onPressClose: () => this.rejectRequest(event),
                        }),
                    );
                    break;
                default:
                    return store.dispatch(
                        showModalBottom({
                            modalContent: (
                                <WalletConnectModal
                                    avatar={proposerData?.icons[0]}
                                    title={proposerData?.name}
                                    description={t("no_proccesed_request")}
                                />
                            ),
                            onPressClose: () => this.rejectRequest(event),
                        }),
                    );
            }
        }
    }

    approveRequest(event, response) {
        try {
            const { topic, params, id } = event;
            this.web3wallet.respondSessionRequest({ topic, response });
            store.dispatch(hideModalBottom());
        } catch {
            store.dispatch(
                showSnackbar({
                    type: "ERROR",
                    message: t("an_error_has_occurred"),
                }),
            );
        }
    }

    rejectRequest(event) {
        const { topic, params, id } = event;
        const response = {
            id,
            jsonrpc: "2.0",
            error: {
                code: 5000,
                message: "User rejected.",
            },
        };

        this.web3wallet.respondSessionRequest({ topic, response });
        store.dispatch(hideModalBottom());
    }

    /// EXECUTE REQUEST ///

    async personalSign(event, currency) {
        const { topic, params, id } = event;
        const { request } = params;
        const requestParamsMessage = request.params[0];

        const signedMessage = await currency.signMessage(Web3.utils.hexToAscii(requestParamsMessage));

        const response = { id, result: signedMessage.toString().toLowerCase(), jsonrpc: "2.0" };
        this.approveRequest(event, response);
        store.dispatch(hideModalBottom());
    }

    async signTypedData(event, currency) {
        const { topic, params, id } = event;
        const { request } = params;
        const requestParamsMessage = request.params[1];

        const signedMessage = await currency.signTypedData(requestParamsMessage);

        const response = { id, result: signedMessage.toString().toLowerCase(), jsonrpc: "2.0" };
        this.approveRequest(event, response);
        store.dispatch(hideModalBottom());
    }

    async ethPreSendTransaction(event, currency, proposerData) {
        const { topic, params, id } = event;
        const { request } = params;
        const data = request.params[0];
        store.dispatch(loading());
        try {
            const transaction = await currency.newTransaction({
                currency: currency,
                amount: currency.fromDecimals(data.value || 0),
                addressFrom: data.from,
                addressTo: data.to,
                data: data.data,
                estimateGas: data.gas,
            });

            const skeleton = await currency.getImplementation().parseSkeleton(transaction.data);

            store.dispatch(
                showModalBottom({
                    modalContent: (
                        <WalletConnectModal
                            avatar={proposerData?.icons[0]}
                            title={proposerData?.name}
                            message={t("eth_sendTransaction", {
                                from: trimHelper(skeleton.sendingFrom, 40, true) || "",
                                to: trimHelper(skeleton.sendingTo, 40, true) || "",
                                gas: skeleton.fee || "",
                            })}
                            btnTitle={t("confirm")}
                            onPress={() => this.ethSendTransaction(event, currency, transaction)}
                            currency={currency}
                            amount={skeleton.amount}
                            client={store.getState().auth.client}
                            clientMessage={t("signed_by")}
                        />
                    ),
                    onPressClose: () => this.rejectRequest(event),
                }),
            );
        } catch (e) {
            console.warn(e);
            this.rejectRequest(event);
            store.dispatch(
                showSnackbar({
                    type: "ERROR",
                    message: t("an_error_has_occurred"),
                }),
            );
        }
        store.dispatch(ready());
    }
    async ethSendTransaction(event, currency, transaction) {
        store.dispatch(hideModalBottom());
        store.dispatch(loading());
        try {
            const { topic, params, id } = event;
            const { request } = params;
            const requestParamsMessage = request.params[0];
            const res = await currency.sendTransaction(transaction.data);
            const response = { id, result: res?.data?.hash, jsonrpc: "2.0" };
            this.approveRequest(event, response);
            store.dispatch(showSnackbar({ type: "SUCCESS", message: t('transaction_success') }));
        } catch (e: any) {
            this.rejectRequest(event);
            store.dispatch(
                showSnackbar({
                    type: "ERROR",
                    message: e.response.data.message || t("an_error_has_occurred"),
                }),
            );
        }
        store.dispatch(ready());
    }

    /// FILTER SESSION BY CLIENT ///
    async filterSessionByAddress(event) {
        const currency: Currency = Wallet.getInstance().findCurrencyByChainId(
            parseInt(event?.params?.chainId.split(":")[1]),
        );
        if (!currency) {
            return;
        }

        const address =
            (event?.params?.request?.method == "personal_sign" && event?.params?.request?.params[1].toLowerCase()) ||
            (event?.params?.request?.method == "eth_sendTransaction" &&
                event?.params?.request?.params[0]?.from.toLowerCase()) ||
            (event?.params?.request?.method == "eth_signTypedData_v4" &&
                event?.params?.request?.params[0]?.toLowerCase()) ||
            (event?.params?.request?.method == "eth_signTypedData" &&
                event?.params?.request?.params[0]?.toLowerCase()) ||
            "";

        const isCompatibleAddress = (await currency?.getAddress().toLowerCase()) == address;

        if (!isCompatibleAddress) {
            this.rejectRequest(event);
            const session = this.activeSessions.find((s) => {
                return s.topic == event?.topic;
            });

            if (!session) return;

            const proposerData: PeerMetadata = session?.peer?.metadata;

            store.dispatch(
                showModalBottom({
                    modalContent: (
                        <WalletConnectModal
                            avatar={proposerData?.icons[0]}
                            title={proposerData?.name}
                            description={t("wallet_connect_requesting_error", {
                                name: trimHelper(proposerData?.name, 50),
                            })}
                            message={t("wallet_connect_switch_account", { address: event?.params?.request?.params[1] })}
                        />
                    ),
                    onPressClose: () => this.rejectRequest(event),
                }),
            );

            setTimeout(() => {
                store.dispatch(hideModalBottom());
            }, 8000);
        }
        return isCompatibleAddress;
    }

    async setConnections() {
        if (!this.activeSessions) return;
        try {
            const sessions = this.activeSessions.filter((s) => {
                const account = s?.namespaces?.eip155?.accounts[0];
                const currency = Wallet.getInstance().findCurrencyByChainId(parseInt(account.split(":")[1]));
                return currency.getAddress().toLowerCase() == account.split(":")[2].toLowerCase();
            });
            store.dispatch(setConnections(sessions || []));
        } catch (e) { }
    }
}
