import React, { Component, useState } from "react";
import { View, StyleSheet, Platform } from "react-native";
import RegularText from "@base/RegularText";
import { colors } from "@styles/globalStyles";
import { connect } from "react-redux";
import { BackHandler } from "react-native";
import { SimpleLineIcons } from "react-native-vector-icons";
import i18n from "@i18n/i18n";
import Container from "@base/Container";

interface State {}

interface Props {
    popup?: PopupOptions;
}

const { t } = i18n;

export class _Popup extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
    }

    render() {
        return (
            <View style={this.props.popup != null ? styles.wrapper : styles.hidden}>
                <View style={styles.wrapper && styles.background}></View>
                {this.props.popup && this.props.popup.type == "SUCCESS" && (
                    <SimpleLineIcons name={"check"} color={colors.white} style={styles.icon}></SimpleLineIcons>
                )}
                {this.props.popup && this.props.popup.type == "ERROR" && (
                    <Container style={{ alignItems: "center" }}>
                        <SimpleLineIcons name={"close"} color={colors.white} style={styles.icon}></SimpleLineIcons>
                        {this.props.popup.message && (
                            <RegularText align="center">
                                {this.props.popup?.message == "Internal server error"
                                    ? t("internal_server_error")
                                    : this.props.popup.message}
                            </RegularText>
                        )}
                    </Container>
                )}
                {this.props.popup && this.props.popup.type == "MESSAGE" && (
                    <Container style={{ alignItems: "center" }}>
                        <SimpleLineIcons name={"check"} color={colors.white} style={styles.icon}></SimpleLineIcons>
                        {this.props.popup.message && (
                            <RegularText align="center">{this.props.popup.message}</RegularText>
                        )}
                    </Container>
                )}
            </View>
        );
    }
}

const styles = StyleSheet.create({
    hidden: {
        display: "none",
    },
    wrapper: {
        flex: 1,
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        alignContent: "center",
        alignItems: "center",
        justifyContent: "center",
    },
    background: {
        position: "absolute",
        backgroundColor: colors.popupBackground,
        opacity: 0.8,
        height: "100%",
        width: "100%",
    },
    icon: {
        fontSize: 80,
        zIndex: 999,
    },
});

const mapStateToProps = (state) => {
    return state.global;
};

const mapDispatchToProps = (dispatch) => ({});

const Popup = connect(mapStateToProps, mapDispatchToProps)(_Popup);

export default Popup;
