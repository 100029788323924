import React, { Component } from "react";
import { View, StyleSheet, FlatList, Platform, ActivityIndicator } from "react-native";
import { colors, settings } from "@styles/globalStyles";
import RegularText from "@base/RegularText";
import * as Clipboard from "expo-clipboard";
import i18n from "@i18n/i18n";
import Row from "@base/Row";
import PressableBase from "@base/PressableBase";
import Icon from "@components/icons";
import SemiBoldText from "@base/SemiBold";
import { ActivityModel, OrderModel } from "@custom-types/ActivityModel";
import moment from "moment";
import ImageBase from "@components/image/ImageBase";
import ActivityService from "@core/services/ActivityService";
import AvatarBase from "@components/avatar/AvatarBase";
import FlatListBase from "@base/FlatListBase";
import { SkypeIndicator } from "react-native-indicators";

interface Props {
    activity: ActivityModel
}

interface State {
    orderDetail: OrderModel
}

const { t } = i18n;

export default class ActivityRedeemableModal extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            orderDetail: null
        };
    }

    async componentDidMount() {
        const response = await ActivityService.getInstance().getOrder(this.props.activity.itemId)

        if (response) {
            this.setState({
                orderDetail: response
            })
        }
    }

    render() {
        return (
            <>
                {this.props.activity && this.state.orderDetail ? (
                    <View>
                        <View >
                            <View style={{ marginBottom: 10, paddingBottom: 10 }}>
                                <Row style={{ alignItems: "center", gap: 15 }}>
                                    <ImageBase style={{ width: 50, height: 50, borderRadius: 50 }} uri={this.props.activity?.icon} />
                                    <View>
                                        <SemiBoldText fontSize={22}>
                                            {this.props.activity.detail}
                                        </SemiBoldText>
                                        <RegularText color={colors.grey}>
                                            {t(this.props.activity.title)}
                                        </RegularText>
                                        <RegularText color={colors.grey}>
                                            {`${moment(this.props.activity?.createdAt).format("LL")}, ${moment(this.props.activity?.createdAt).format("hh:mm")}`}
                                        </RegularText>
                                    </View>
                                </Row>
                            </View>

                            <View style={styles.container}>
                                <RegularText style={{ marginBottom: 10 }}>{t('purchase_detail')}</RegularText>
                                {this.state.orderDetail?.items.length > 0 &&
                                    <View>
                                        <FlatListBase
                                            data={this.state.orderDetail?.items}
                                            renderItem={(item: any) => {
                                                return (
                                                    <Row style={{ marginTop: 10 }}>
                                                        <AvatarBase
                                                            uri={item.item.item.image.thumbnail || item.item.item.image.cover || ''}
                                                            size={40}
                                                        ></AvatarBase>
                                                        <View style={{ paddingLeft: 10, justifyContent: "center" }}>
                                                            <RegularText>
                                                                {item.item.title}
                                                            </RegularText>
                                                            <RegularText color={colors.grey}>
                                                                $ {item.item.price} x {item.item.quantity}
                                                            </RegularText>
                                                        </View>
                                                    </Row>
                                                );
                                            }}
                                            keyExtractor={(item: any) => item._id}
                                        />
                                    </View>
                                }
                            </View>

                            <View style={styles.container}>
                                <RegularText style={{ marginBottom: 10 }}>{t('payment_detail')}</RegularText>
                                {this.state.orderDetail?.payment?.status &&
                                    <Row style={{ justifyContent: "space-between", marginBottom: 5 }}>
                                        <RegularText color={colors.grey}>
                                            {t("status")}
                                        </RegularText>
                                        {this.state.orderDetail?.payment?.status == "approved" && (
                                            <Row style={{ alignItems: "center" }}>
                                                <Icon
                                                    color={colors.text}
                                                    size={16}
                                                    name="check-circle"
                                                    style={{ marginRight: 5 }}
                                                />
                                                <SemiBoldText fontSize={16}>{t("confirmed")}</SemiBoldText>
                                            </Row>
                                        )}
                                        {this.state.orderDetail?.payment?.status == "pending" && (
                                            <RegularText color={colors.grey}>
                                                {t("pending")}
                                            </RegularText>
                                        )}
                                        {this.state.orderDetail?.payment?.status == "rejected" && (
                                            <RegularText color={colors.grey}>
                                                {t("cancel")}
                                            </RegularText>
                                        )}
                                    </Row>
                                }

                                {this.state.orderDetail?.payment?.gateway &&
                                    <Row style={{ justifyContent: "space-between", marginBottom: 5 }}>
                                        <RegularText color={colors.grey}>
                                            {t("payment_method")}
                                        </RegularText>
                                        <RegularText style={{ textTransform: "uppercase" }} color={colors.grey}>
                                            {this.state.orderDetail.payment.gateway}
                                        </RegularText>
                                    </Row>
                                }

                                <Row style={{ justifyContent: "space-between", marginBottom: 5 }}>
                                    <RegularText color={colors.grey}>
                                        {t("amount")}
                                    </RegularText>
                                    <RegularText color={colors.grey}>
                                        {this.props.activity.detail}
                                    </RegularText>
                                </Row>
                            </View>

                            <View style={styles.container}>
                                <RegularText style={{ marginBottom: 5 }}>{t("transaction_id")}</RegularText>
                                {this.state.orderDetail?._id &&
                                    <PressableBase onPress={async () => await Clipboard.setStringAsync(this.props.activity._id)} style={{ paddingVertical: 2 }}>
                                        <Row style={{ alignItems: "baseline" }}>
                                            <RegularText color={colors.grey}>
                                                {this.state.orderDetail?._id}
                                            </RegularText>
                                            <Icon size={16} name="content-copy" color={colors.grey} style={{ marginLeft: 10 }} />
                                        </Row>
                                    </PressableBase>
                                }
                            </View>
                        </View>
                    </View >
                ) :
                    <View style={{ height: 80, alignItems: "center", justifyContent: "center", marginVertical: 40 }}>
                        {Platform.OS == "web" ? (
                            <ActivityIndicator size={60} color={colors.complementary} />
                        ) : (
                            <SkypeIndicator
                                size={60}
                                animationDuration={2000}
                                color={colors.complementary}
                            />
                        )}
                    </View>
                }
            </>
        );
    }
}

const styles = StyleSheet.create({
    container: {
        borderTopColor: colors.grey,
        borderTopWidth: 0.5,
        paddingVertical: 20,
    },
});
