import React, { Component } from "react";
import { View, StyleSheet,  ScrollView, Modal, Platform, Share } from "react-native";
import { connect } from "react-redux";
import { NavigationType } from "@custom-types/NavigationType";
import Container from "@base/Container";
import ScreenWrapper from "@components/wrapper/ScreenWrapper";
import i18n from "@i18n/i18n";
import { HeaderType } from "@custom-types/HeaderType";
import Header from "@components/header/Header";
import NFT from "@custom-types/NFTModel";
import { trimHelper } from "@utils/helpers/chat/chat.helper";
import BoldText from "@base/BoldText";
import RegularText from "@base/RegularText";
import Row from "@base/Row";
import AvatarBase from "@components/avatar/AvatarBase";
import { colors, settings } from "@styles/globalStyles";
import InlineButton from "@base/InlineButton";
import Icon from "@components/icons";
import Currency from "@core/currencies/Currency";
import Wallet from "@core/wallet/Wallet";
import { FiatCurrency } from "@core/fiat/FiatCurrency";
import { getColorOpacity } from "@utils/helpers/global/global";
import CircleButton from "@base/CircleButton";
import store from "@store/index";
import { hideModal, loading, ready, showModal, showPopup, showSnackbar } from "@store/actions/global";
import NFTService, { default_currency_NFTS } from "@core/services/NFTService";
import { setRequestStatusNFTs, setSelectedNFT } from "@store/actions/nfts.action";
import BotCard from "@base/BotCard";
import { MarketPlaceStatus } from "@store/reducers/nfts.reducer";
import ImageBase from "@components/image/ImageBase";
import VideoBase from "@components/image/VideoBase";
import { Client } from "@custom-types/Client";
import { TabsNavigatorScreens } from "@navigation/TabsNavigator";
import ProfileService from "@core/services/ProfileService";
import { NTFNavigatorScreens } from "@navigation/NFTNavigator";
import { ProfileNavigatorScreens } from "@navigation/ProfileNavigator";
import Constants from "expo-constants";
import { ProfileSections } from "@custom-types/ProfileType";
import ShareService from "@core/services/ShareService";
import { ModuleControlService, NFTsModules } from "@core/services/ModuleControlService";
import CurrencyIcon from "@components/accessories/CurrencyIcon";
import PressableBase from "@base/PressableBase";

interface Props {
    navigation: NavigationType;
    route: any;
    fiatCurrency: FiatCurrency;
    selectedNFT: NFT;
    client: Client;
}

interface State {
    currency: Currency;
    seeMore: boolean;
    isModalActive: boolean;
    isOwner: boolean;
    showVideoControls: boolean;
    redirectUrl: string;
    linkCopied: string;
}

const { t } = i18n;

export class _NFTScreen extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.redirectToApprove = this.redirectToApprove.bind(this);
        this.redirectToSale = this.redirectToSale.bind(this);
        this.redirectToBuy = this.redirectToBuy.bind(this);
        this.showModalCancelSale = this.showModalCancelSale.bind(this);
        this.onConfirmCancelSale = this.onConfirmCancelSale.bind(this);
        this.onPressAvatar = this.onPressAvatar.bind(this);
        this.onReport = this.onReport.bind(this);
        this.onRemove = this.onRemove.bind(this);
        this.onEdit = this.onEdit.bind(this);
        this.showModalRemove = this.showModalRemove.bind(this);
        this.mintNFT = this.mintNFT.bind(this);
        const clientId = store.getState().auth.client?._id;
        const currency = Wallet.getInstance().findCurrencyByBlockchain(this.props.selectedNFT.blockchain);
        this.state = {
            currency,
            seeMore: false,
            isModalActive: false,
            isOwner: this.props.selectedNFT.owner._id == clientId,
            showVideoControls: false,
            redirectUrl:
                `${ModuleControlService.getInstance().getShareUrl()}?type=nft&id=${
                    this.props.selectedNFT._id
                }&blockchain=${currency.getBlockchain()}&network=${currency.getNetworkType()}` || "",
            linkCopied: t("copy_link"),
        };
    }

    componentDidMount() {
        if (this.props.selectedNFT.description && this.props.selectedNFT.description.length < 90) {
            this.setState({ seeMore: true });
        }
    }

    componentDidUpdate(props) {
        if (props != this.props) {
            const clientId = store.getState().auth.client?._id;
            this.setState({
                currency: Wallet.getInstance().findCurrencyByBlockchain(this.props.selectedNFT.blockchain),
                seeMore: false,
                isModalActive: false,
                isOwner: this.props.selectedNFT.owner._id == clientId,
            });
        }
    }

    redirectToApprove() {
        this.props.navigation.navigate(NTFNavigatorScreens.ApproveNFT.routeName, {
            tokenId: this.props.selectedNFT.tokenId,
            contractAddress: this.props.selectedNFT.contractAddress,
            currency: this.state.currency.getId(),
        });
    }

    redirectToSale() {
        if (this.props.selectedNFT?.isApproved) {
            this.props.navigation.navigate(NTFNavigatorScreens.OfferForSale.routeName);
        } else {
            this.redirectToApprove();
        }
    }

    redirectToBuy() {
        this.props.navigation.navigate(NTFNavigatorScreens.ConfirmBuy.routeName, {
            tokenId: this.props.selectedNFT.tokenId,
            contractAddress: this.props.selectedNFT.contractAddress,
            currency: this.state.currency.getId(),
        });
    }

    async showModalCancelSale() {
        store.dispatch(loading());
        const tokenId = this.props.selectedNFT.tokenId;
        const contractAddress = this.props.selectedNFT.contractAddress;
        const resp = await NFTService.getInstance().requestCancelNFT(this.state.currency, tokenId, contractAddress);

        const skeleton = this.state.currency.getImplementation().parseSkeleton(resp);

        store.dispatch(
            showModal({
                title: this.props.selectedNFT.name,
                avatar: this.props.selectedNFT.image?.square,
                message: t("cancel_sale_question"),
                description: t("cancel_sale_description"),
                btnTitle: t("confirm"),
                onPress: () => {
                    this.onConfirmCancelSale(resp);
                },
            })
        );
    }

    async onConfirmCancelSale(skeleton) {
        try {
            const res = await this.state.currency.sendTransaction(skeleton);
            store.dispatch(showPopup({ type: "SUCCESS" }));
            store.dispatch(hideModal());
            store.dispatch(
                setRequestStatusNFTs({ status: MarketPlaceStatus.WaitingCancel, nft: this.props.selectedNFT })
            );
            this.props.navigation.goBack();
            this.props.navigation.navigate(TabsNavigatorScreens.NFTs.routeName, {
                screen: "NFTsMarketplace",
            });
        } catch (e: any) {
            console.warn(e.response.data.message);
            store.dispatch(
                showPopup({
                    type: "ERROR",
                    message: e.response.data.message || t("an_error_has_occurred"),
                })
            );
            store.dispatch(hideModal());
        }
    }

    async mintNFT() {
        store.dispatch(loading());
        const data = {
            id: this.props.selectedNFT._id,
            blockchain: this.props.selectedNFT?.blockchain,
            network: this.state.currency.getNetworkType(),
        };

        var skeleton = await NFTService.getInstance().mintNFT(data);
        if (skeleton) {
            delete skeleton.extra;

            this.props.navigation.navigate(NTFNavigatorScreens.ConfirmMintNFT.routeName, {
                data: skeleton,
                currency: this.state.currency.getId(),
                id: this.props.selectedNFT._id,
            });
        }
        store.dispatch(ready());
    }

    showModalReport() {
        store.dispatch(
            showModal({
                title: this.props.selectedNFT.name,
                avatar: this.props.selectedNFT.image.square,
                message: t("message_report"),
                question: t("question_report"),
                btnTitle: t("report"),
                onPress: () => {
                    this.onReport();
                },
            })
        );
    }

    showModalRemove() {
        store.dispatch(
            showModal({
                title: this.props.selectedNFT.name,
                avatar: this.props.selectedNFT.image?.thumbnail || this.props.selectedNFT.image?.original,
                message: t("message_remove"),
                question: t("question_remove"),
                btnTitle: t("remove"),
                onPress: () => {
                    this.onRemove();
                },
            })
        );
    }

    async onRemove() {
        store.dispatch(hideModal());
        const resp = await NFTService.getInstance().removeDraft(this.state.currency, this.props.selectedNFT._id);
        if (resp) {
            store.dispatch(showPopup({ type: "SUCCESS" }));
            ProfileService.getInstance().setFullProfile(this.props.client?._id);
            this.props.navigation.navigate(ProfileNavigatorScreens.ProfileMain.routeName, {
                clientID: this.props.client?._id,
            });
        }
    }

    async onReport() {
        store.dispatch(hideModal());
        const resp = await NFTService.getInstance().reportNFT(this.state.currency, this.props.selectedNFT._id);
        const updatedNft = await NFTService.getInstance().getNFT(
            this.props.selectedNFT._id,
            this.state.currency.getBlockchain(),
            true
        );
        store.dispatch(setSelectedNFT(updatedNft));
    }

    onPressAvatar() {
        this.props.navigation.navigate("Profile", {
            screen: ProfileNavigatorScreens.ProfileMain.routeName,
            params: {
                clientID: this.props.selectedNFT.owner._id,
                profileSection: ProfileSections.nftProfile,
            },
        });
    }

    onEdit() {
        this.props.navigation.navigate(NTFNavigatorScreens.newNFT.routeName, { nftEdit: this.props.selectedNFT });
    }

    handleShare = () => {
        ShareService.getInstance().handleShare(t("check_nft"), this.state.redirectUrl);
    };

    render() {
        return (
            <ScreenWrapper>
                <Header
                    title={trimHelper(this.props.selectedNFT.name, 16)}
                    type={HeaderType.Light}
                    backTo={this.props.route?.params ? this.props.route.params.backTo : null}
                    rightBtn={[
                        !this.state.isOwner &&
                            !this.props.selectedNFT.isReported &&
                            this.props.selectedNFT.isListed && {
                                onPress: () => {
                                    this.handleShare();
                                },
                                icon: "share",
                                size: 22,
                            },
                        !this.state.isOwner &&
                            !this.props.selectedNFT.isReported &&
                            this.props.selectedNFT.isListed && {
                                onPress: () => {
                                    this.showModalReport();
                                },
                                icon: "eye-slash",
                                iconType: "custom",
                                size: 26,
                            },
                    ]}
                    {...this.props}
                />
                <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
                    <Container style={{ flex: 1, justifyContent: "space-between" }}>
                        <View>
                            {this.state.isOwner &&
                                this.props.selectedNFT.isDraft &&
                                !this.props.selectedNFT.isListed && (
                                    <View
                                        style={{
                                            display: "flex",
                                            zIndex: 9999,
                                            width: "100%",
                                            flex: 1,
                                            justifyContent: "flex-end",
                                            flexDirection: "row",
                                        }}
                                    >
                                        <CircleButton
                                            style={{
                                                height: 35,
                                                width: 35,
                                            }}
                                            iconSize={18}
                                            icon={"edit"}
                                            onPress={this.onEdit}
                                        ></CircleButton>
                                        <CircleButton
                                            style={{
                                                height: 35,
                                                width: 35,
                                            }}
                                            iconSize={18}
                                            icon={"trash"}
                                            onPress={this.showModalRemove}
                                        ></CircleButton>
                                    </View>
                                )}

                            {this.state.isOwner && !this.props.selectedNFT.isDraft && (
                                <View
                                    style={{
                                        display: "flex",
                                        zIndex: 9999,
                                        width: "100%",
                                        flex: 1,
                                        justifyContent: "flex-end",
                                        flexDirection: "row",
                                    }}
                                >
                                    <CircleButton
                                        style={{
                                            height: 35,
                                            width: 35,
                                        }}
                                        iconSize={18}
                                        icon={"share"}
                                        onPress={this.handleShare}
                                    ></CircleButton>
                                </View>
                            )}

                            {this.props.selectedNFT.isDraft && (
                                <View style={{ paddingTop: 0 }}>
                                    <BotCard
                                        title={t("warning")}
                                        message={
                                            this.props.selectedNFT.draftState == "minted"
                                                ? t("bot_draft_minted")
                                                : t("bot_draft")
                                        }
                                    ></BotCard>
                                </View>
                            )}

                            {this.props.selectedNFT.isReported && this.props.selectedNFT.isListed && (
                                <BotCard
                                    title={t("hi")}
                                    message={this.state.isOwner ? t("bot_report_owner") : t("bot_report")}
                                ></BotCard>
                            )}

                            <View>
                                <BoldText fontSize={24} style={{ paddingTop: 5, paddingBottom: 10 }}>
                                    {this.props.selectedNFT.name}
                                    {this.props.selectedNFT.tokenId && ` #${this.props.selectedNFT.tokenId} `}
                                    {"  "}

                                    {this.props.selectedNFT.isReported && (
                                        <View
                                            style={{
                                                backgroundColor: getColorOpacity(colors.red, 0.4),
                                                borderRadius: 5,
                                                paddingHorizontal: 8,
                                                paddingVertical: 2,
                                                overflow: "hidden",
                                            }}
                                        >
                                            <RegularText fontSize={10}>{t("reported")}</RegularText>
                                        </View>
                                    )}
                                </BoldText>

                                {this.props.selectedNFT?.network && this.props.selectedNFT.network == "testnet" && (
                                    <View style={{ flex: 1 }}>
                                        <BoldText
                                            fontSize={14}
                                            style={{
                                                alignSelf: "flex-end",
                                                paddingHorizontal: 8,
                                                paddingVertical: 4,
                                                borderRadius: settings.cardRadius,
                                                backgroundColor: colors.grey,
                                            }}
                                        >
                                            TESTNET
                                        </BoldText>
                                    </View>
                                )}

                                {this.state.isModalActive ? (
                                    <Modal visible={true} transparent={true} animationType="fade">
                                        <View
                                            style={{
                                                position: "absolute",
                                                zIndex: 99999,
                                                alignSelf: "center",
                                                bottom: 50,
                                            }}
                                        >
                                            <CircleButton
                                                style={{ backgroundColor: getColorOpacity(colors.grey, 0.6) }}
                                                icon={"x-lg"}
                                                iconSize={22}
                                                onPress={() =>
                                                    this.setState({ isModalActive: !this.state.isModalActive })
                                                }
                                            ></CircleButton>
                                        </View>

                                        <ImageBase
                                            enableLoader={true}
                                            enableImageZoom={true}
                                            onClick={() => this.setState({ isModalActive: !this.state.isModalActive })}
                                            uri={this.props.selectedNFT.image?.square}
                                        />
                                    </Modal>
                                ) : (
                                    <View>
                                        <View style={{ flex: 1 }}>
                                            {this.props.selectedNFT?.video?.video ? (
                                                <PressableBase
                                                    onPress={() => this.setState({ showVideoControls: true })}
                                                    style={{ flex: 1 }}
                                                >
                                                    <VideoBase
                                                        style={
                                                            Platform.OS == "web"
                                                                ? {
                                                                      marginTop: 10,
                                                                      marginBottom: 20,
                                                                      borderRadius: settings.cardRadius,
                                                                  }
                                                                : {
                                                                      borderRadius: settings.cardRadius,
                                                                      marginVertical: 10,
                                                                  }
                                                        }
                                                        uri={this.props.selectedNFT?.video.video}
                                                        posterStyle={{
                                                            borderRadius: settings.cardRadius,
                                                            marginVertical: 0,
                                                            resizeMode: "cover",
                                                        }}
                                                        posterUri={this.props.selectedNFT?.image?.square}
                                                        useNativeControls={this.state.showVideoControls}
                                                        autoSize={true}
                                                        maxAutoSizeHight={450}
                                                    />
                                                </PressableBase>
                                            ) : (
                                                <ImageBase
                                                    autoSize={true}
                                                    maxAutoSizeHight={450}
                                                    enableLoader={true}
                                                    blurRadius={this.props.selectedNFT.isReported ? 10 : 0}
                                                    style={{
                                                        borderRadius: settings.cardRadius,
                                                        height: 320,
                                                    }}
                                                    onClick={() =>
                                                        this.setState({ isModalActive: !this.state.isModalActive })
                                                    }
                                                    uri={this.props.selectedNFT.image?.square}
                                                />
                                            )}
                                        </View>
                                        {/* )} */}
                                    </View>
                                )}
                                {this.props.selectedNFT.owner && (
                                    <PressableBase onPress={this.onPressAvatar}>
                                        <Row style={{ alignItems: "center", marginTop: 15 }}>
                                            <AvatarBase
                                                size={26}
                                                uri={
                                                    this.props.selectedNFT.owner.profileImagePath?.thumbnail
                                                        ? this.props.selectedNFT.owner.profileImagePath.thumbnail
                                                        : ""
                                                }
                                                alias={this.props.selectedNFT.owner.alias}
                                                overlayContainerStyle={colors.secondaryShadow}
                                            ></AvatarBase>
                                            <RegularText style={{ paddingLeft: 10 }}>{t("owned_by")}</RegularText>
                                            <BoldText style={{ paddingLeft: 6 }}>
                                                {this.props.selectedNFT.owner.alias}
                                            </BoldText>
                                        </Row>
                                    </PressableBase>
                                )}

                                {this.props.selectedNFT.isListed && (
                                    <Row style={{ marginTop: 15, alignItems: "center" }}>
                                        <CurrencyIcon
                                            currency={this.state.currency}
                                            iconSize={18}
                                            size={26}
                                            styles={{ marginRight: 15 }}
                                        />

                                        <BoldText fontSize={18} style={{ paddingLeft: 10 }}>
                                            {this.state.currency.fromDecimalsToString(this.props.selectedNFT.price)}
                                        </BoldText>
                                        <RegularText fontSize={18} style={{ paddingLeft: 6 }}>
                                            {this.state.currency.getPName()}
                                        </RegularText>
                                        <RegularText
                                            align="center"
                                            fontSize={10}
                                            style={{
                                                paddingVertical: 1,
                                                paddingHorizontal: 4,
                                                borderRadius: 2,
                                                marginLeft: 8,
                                                backgroundColor: getColorOpacity(colors.grey, 0.5),
                                                overflow: "hidden",
                                            }}
                                        >
                                            {this.props.fiatCurrency.getSymbol()}{" "}
                                            {this.props.fiatCurrency.fiatFormat(
                                                this.state.currency.getFiat() *
                                                    this.state.currency.fromDecimals(this.props.selectedNFT.price)
                                            )}
                                        </RegularText>
                                    </Row>
                                )}

                                {this.props.selectedNFT.contractType?.length > 0 && (
                                    <View style={{ paddingTop: 15 }}>
                                        <Row style={{ alignItems: "center" }}>
                                            {!this.props.selectedNFT.isListed && (
                                                <CurrencyIcon
                                                    currency={this.state.currency}
                                                    styles={{ marginRight: 10 }}
                                                    size={25}
                                                    iconSize={12}
                                                />
                                                
                                            )}
                                            <RegularText>{this.props.selectedNFT?.contractType}</RegularText>
                                        </Row>
                                    </View>
                                )}

                                {this.props.selectedNFT.description?.length > 0 && (
                                    <View style={{ paddingTop: 15 }}>
                                        {this.state.seeMore ? (
                                            <View style={{ alignContent: "center" }}>
                                                <RegularText align="justify">
                                                    {this.props.selectedNFT.description}
                                                </RegularText>
                                            </View>
                                        ) : (
                                            <View style={{ alignContent: "center" }}>
                                                <RegularText align="justify">
                                                    {trimHelper(this.props.selectedNFT.description, 78)}
                                                    <PressableBase onPress={() => this.setState({ seeMore: true })}>
                                                        <RegularText
                                                            style={{
                                                                color: "#4796fb",
                                                                marginBottom: -4,
                                                                marginLeft: 2,
                                                            }}
                                                        >
                                                            {" "}
                                                            {t("see_more")}
                                                        </RegularText>
                                                    </PressableBase>
                                                </RegularText>
                                            </View>
                                        )}
                                    </View>
                                )}

                                {this.props.selectedNFT.attributes?.length > 0 &&
                                    this.props.selectedNFT?.attributes[0]?.trait_type && (
                                        <View style={styles.propietiesContainer}>
                                            {this.props.selectedNFT.attributes.map((a) => {
                                                return (
                                                    <View key={a.trait_type} style={styles.propietiesWrapper}>
                                                        <View style={styles.propieties}>
                                                            <BoldText align="center" style={{ flex: 0.5 }}>
                                                                {a.trait_type.toUpperCase()}
                                                            </BoldText>
                                                            <RegularText
                                                                align="center"
                                                                style={{ flex: 0.5, textTransform: "capitalize" }}
                                                            >
                                                                {a.value.toLocaleLowerCase()}
                                                            </RegularText>
                                                        </View>
                                                    </View>
                                                );
                                            })}
                                        </View>
                                    )}
                            </View>
                        </View>
                        <View style={{ paddingVertical: 10 }}>
                            {this.state.isOwner &&
                                !this.props.selectedNFT.isListed &&
                                !this.props.selectedNFT.isDraft &&
                                this.props.selectedNFT?.contractType !== "ERC1155" &&
                                ModuleControlService.getInstance().isNFTsModuleEnabled(NFTsModules.marketplace) && (
                                    <InlineButton
                                        style={{ marginBottom: 10, marginTop: 25, marginHorizontal: 0 }}
                                        title={t("offer_for_sale")}
                                        onPress={this.redirectToSale}
                                    ></InlineButton>
                                )}

                            {this.state.isOwner &&
                                ModuleControlService.getInstance().isNFTsModuleEnabled(NFTsModules.mint) &&
                                !this.props.selectedNFT.isListed &&
                                this.props.selectedNFT.isDraft &&
                                this.props.selectedNFT?.contractType !== "ERC1155" &&
                                this.props.selectedNFT.draftState !== "minted" && (
                                    <InlineButton
                                        style={{ marginBottom: 10, marginTop: 25, marginHorizontal: 0 }}
                                        title={"Mint"}
                                        onPress={this.mintNFT}
                                    ></InlineButton>
                                )}

                            {this.state.isOwner &&
                                this.props.selectedNFT.isListed &&
                                ModuleControlService.getInstance().isNFTsModuleEnabled(NFTsModules.marketplace) && (
                                    <View>
                                        {Constants.expoConfig?.extra?.id !==
                                            this.props.selectedNFT?.marketplace?.wallet && (
                                            <BotCard
                                                title={t("important") + "!"}
                                                message={t("bot_marketplace_warning", {
                                                    name: this.props.selectedNFT?.marketplace?.wallet,
                                                })}
                                            ></BotCard>
                                        )}
                                        <InlineButton
                                            style={{ marginBottom: 10, marginTop: 25, marginHorizontal: 0 }}
                                            title={t("cancel_sale")}
                                            onPress={this.showModalCancelSale}
                                        ></InlineButton>
                                    </View>
                                )}

                            {!this.state.isOwner &&
                                this.props.selectedNFT.isListed &&
                                this.props.selectedNFT?.contractType !== "ERC1155" &&
                                Constants.expoConfig?.extra?.id == this.props.selectedNFT?.marketplace?.wallet &&
                                ModuleControlService.getInstance().isNFTsModuleEnabled(NFTsModules.marketplace) &&
                                (this.props.route?.params?.activeBuyable ? true : Platform.OS !== "ios") && (
                                    <InlineButton
                                        style={{ marginBottom: 10, marginTop: 15, marginHorizontal: 0 }}
                                        title={t("buy")}
                                        onPress={this.redirectToBuy}
                                    ></InlineButton>
                                )}
                        </View>
                    </Container>
                </ScrollView>
            </ScreenWrapper>
        );
    }
}

const mapStateToProps = (state) => {
    return { fiatCurrency: state.wallet.fiatCurrency, selectedNFT: state.nfts.selectedNFT, client: state.auth.client };
};

const mapDispatchToProps = (dispatch) => ({});

const NFTScreen = connect(mapStateToProps, mapDispatchToProps)(_NFTScreen);

export default NFTScreen;

const styles = StyleSheet.create({
    propietiesContainer: {
        marginVertical: 15,
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "space-between",
    },
    propietiesWrapper: {
        width: "48%",
        paddingVertical: 5,
        padding: 0,
    },
    propieties: {
        borderRadius: settings.cardRadius,
        paddingVertical: 15,
        paddingHorizontal: 5,
        backgroundColor: colors.shadow,
        overflow: "hidden",
    },
});
