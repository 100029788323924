import React, { Component } from "react";
import i18n from "@i18n/i18n";
import RegularText from "@base/RegularText";
import { StyleSheet, View } from "react-native";
import BoldText from "@base/BoldText";
import Logo from "@assets/images/Logo";
import Constants from "expo-constants";

interface Props { }

interface State { }

const { t } = i18n;

export default class CustomWelcomeContent extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <View style={styles.wrapper}>
                <Logo style={{ marginTop: 50, marginBottom: 25 }} width={200} height={200} />
                <RegularText fontSize={20} style={{ marginBottom: 10 }} align="center">
                    {t("welcome_to")}
                   
                </RegularText>
                <BoldText fontSize={20} style={{ marginBottom: 10 }} align="center">
                   GUARDIANS OF THE BALL                   
                </BoldText>
                <RegularText style={{paddingTop: 10}}  fontSize={16} align="center">{t("gob_subtitle")}</RegularText>
            </View>
        );
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: "space-between",
    },
    wrapper: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
    },
});
