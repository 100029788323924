import React, { Component } from "react";
import { View, StyleSheet } from "react-native";
import { colors, settings } from "@styles/globalStyles";
import RegularText from "@base/RegularText";
import * as Clipboard from "expo-clipboard";
import Currency from "@core/currencies/Currency";
import BoldText from "@base/BoldText";
import i18n from "@i18n/i18n";
import Row from "@base/Row";
import BigNumber from "bignumber.js";
import { trimHelper } from "@utils/helpers/chat/chat.helper";
import ProfileService from "@core/services/ProfileService";
import { Client } from "@custom-types/Client";
import AvatarBase from "@components/avatar/AvatarBase";
import PressableBase from "@base/PressableBase";
import Icon from "@components/icons";
import CurrencyIcon from "@components/accessories/CurrencyIcon";
import NetworkCurrencySymbol from "@components/accessories/NetworkCurrencySymbol";
import CurrencyBalanceIcon from "@components/accessories/CurrencyBalanceIcon";
import { FiatCurrency } from "@core/fiat/FiatCurrency";
import SemiBoldText from "@base/SemiBold";

interface Props {
    fiatCurrency?: FiatCurrency;
    currency: Currency;
    transaction: any;
}

interface State {
    fromClient: Partial<Client>;
    toClient: Partial<Client>;
    showMessage: boolean;
}

const { t } = i18n;

export default class TransactionDetailComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.onCopy = this.onCopy.bind(this);
        this.state = {
            fromClient: null,
            toClient: null,
            showMessage: false,
        };
    }

    componentDidMount = async () => {
        const fromClient: any = await this.getProfileByAddress(this.props.transaction?.from);
        this.setState({ fromClient: fromClient || null });
        const toClient: any = await this.getProfileByAddress(this.props.transaction?.to);
        this.setState({ toClient: toClient || null });
    };

    onCopy = (item) => {
        Clipboard.setStringAsync(item);
        this.setState({ showMessage: true });
        setTimeout(() => {
            this.setState({ showMessage: false });
        }, 3000);
    };

    getProfileByAddress = async (address: string) => {
        return await ProfileService.getInstance().getProfileByAddress(this.props.currency, address || "");
    };

    render() {
        return (
            <>
                {this.props.transaction && (
                    <View>
                        <View>
                            <View style={{ marginBottom: 10, paddingBottom: 10 }}>
                                <Row style={{ alignItems: "center" }}>
                                    <CurrencyIcon
                                        styles={{ marginRight: 15 }}
                                        iconSize={35}
                                        size={50}
                                        currency={this.props.currency}
                                    />
                                    <View>
                                        <Row style={{ alignItems: "center" }}>
                                            <BoldText fontSize={22}>
                                                {`${new BigNumber(
                                                    this.props.transaction.amount
                                                ).toString()} ${this.props.currency.getSymbol()}`}
                                            </BoldText>
                                            <NetworkCurrencySymbol
                                                currency={this.props.currency}
                                            ></NetworkCurrencySymbol>
                                        </Row>
                                        <Row style={{ alignItems: "center" }}>
                                            <SemiBoldText
                                                style={{
                                                    backgroundColor: colors.secondary,
                                                    paddingHorizontal: 8,
                                                    paddingVertical: 3,
                                                    borderRadius: 10,
                                                    overflow: "hidden",
                                                    opacity: 0.8,
                                                    marginRight: 5
                                                }}
                                                fontSize={10}
                                                color={colors.white}
                                            >
                                                {this.props.transaction.type == 1
                                                    ? t("sent").toUpperCase()
                                                    : t("received").toUpperCase()}
                                            </SemiBoldText>
                                            <RegularText fontSize={12} color={colors.grey}>
                                                {this.props.currency
                                                    .getImplementation()
                                                    .parseDate(this.props.transaction.time)}
                                            </RegularText>



                                        </Row>
                                    </View>
                                </Row>
                            </View>
                            <View style={styles.container}>
                                <RegularText style={{ marginBottom: 10 }}>{t("status")}</RegularText>
                                {this.props.transaction.confirmations >= 6 ? (
                                    <Row style={{ alignItems: "center" }}>
                                        <Icon
                                            color={colors.text}
                                            size={24}
                                            name="check-circle"
                                            style={{ marginRight: 10 }}
                                        />
                                        <BoldText fontSize={16}>{t("confirmed")}</BoldText>
                                    </Row>
                                ) : (
                                    <BoldText color={colors.grey}>
                                        {`${t("pending")} ${this.props.transaction.confirmations}/6`}
                                    </BoldText>
                                )}
                            </View>
                            <View style={styles.container}>
                                <RegularText style={{ marginBottom: 5 }}>{t("transaction_detail")}</RegularText>
                                <Row style={{ justifyContent: "space-between", paddingBottom: 10 }}>
                                    <RegularText color={colors.grey} style={{ marginBottom: 15 }}>
                                        {t("amount")}
                                    </RegularText>
                                    <View
                                        style={{
                                            justifyContent: "flex-end",
                                            alignContent: "flex-end",
                                            alignItems: "flex-end",
                                        }}
                                    >
                                        <CurrencyBalanceIcon
                                            currency={this.props.currency}
                                            fontSize={16}
                                            iconSize={18}
                                            amount={new BigNumber(this.props.transaction?.amount || 0).toString()}
                                        ></CurrencyBalanceIcon>
                                        {this.props.fiatCurrency && (
                                            <CurrencyBalanceIcon
                                                fontWeight={"regular"}
                                                fontColor={colors.grey}
                                                fiatCurrency={this.props.fiatCurrency}
                                                amount={this.props.currency.toFiat(this.props.transaction.amount)}
                                            ></CurrencyBalanceIcon>
                                        )}
                                    </View>
                                </Row>
                                <Row style={{ justifyContent: "space-between" }}>
                                    <RegularText color={colors.grey}>{t("fee")}</RegularText>
                                    <View
                                        style={{
                                            justifyContent: "flex-end",
                                            alignContent: "flex-end",
                                            alignItems: "flex-end",
                                        }}
                                    >
                                        <CurrencyBalanceIcon
                                            fontSize={16}
                                            iconSize={18}
                                            amount={new BigNumber(this.props.transaction?.fee || 0).toString()}
                                            currency={this.props.currency.getUnderlyingCurrency()}
                                        />
                                        {this.props.fiatCurrency && (
                                            <CurrencyBalanceIcon
                                                fontColor={colors.grey}
                                                fontWeight={"regular"}
                                                fiatCurrency={this.props.fiatCurrency}
                                                amount={this.props.currency
                                                    .getUnderlyingCurrency()
                                                    .toFiat(this.props.transaction?.fee || 0)}
                                            ></CurrencyBalanceIcon>
                                        )}
                                    </View>
                                </Row>
                            </View>
                            <View style={styles.container}>
                                <RegularText style={{ marginBottom: 5 }}>{t("origin_wallet")}</RegularText>
                                {this.state.fromClient ? (
                                    <Row style={{ marginTop: 10 }}>
                                        <AvatarBase
                                            alias={this.state.fromClient?.alias}
                                            uri={this.state.fromClient?.profileImagePath?.thumbnail}
                                            size={45}
                                        ></AvatarBase>

                                        <View style={{ paddingLeft: 10, justifyContent: "center" }}>
                                            <BoldText color={colors.text}>
                                                {"@"}
                                                {trimHelper(this.state.fromClient?.alias, 35, true)}
                                            </BoldText>
                                            <PressableBase
                                                onPress={async () => await Clipboard.setStringAsync(this.props.transaction.from)}
                                                style={{ paddingVertical: 2 }}
                                            >
                                                <Row style={{ alignItems: "baseline" }}>
                                                    <RegularText color={colors.grey} align="left" selectable={true}>
                                                        {trimHelper(this.props.transaction.from, 30, true)}
                                                    </RegularText>
                                                    <Icon
                                                        size={16}
                                                        name="content-copy"
                                                        color={colors.grey}
                                                        style={{ marginLeft: 10 }}
                                                    />
                                                </Row>
                                            </PressableBase>
                                        </View>
                                    </Row>
                                ) : (
                                    <RegularText fixedWidth={425} color={colors.grey}>
                                        {this.props.transaction.from}
                                    </RegularText>
                                )}
                            </View>
                            <View style={styles.container}>
                                <RegularText style={{ marginBottom: 5 }}>{t("target_wallet")}</RegularText>
                                {this.state.toClient ? (
                                    <Row style={{ marginTop: 10 }}>
                                        <AvatarBase
                                            alias={this.state.toClient?.alias}
                                            uri={this.state.toClient?.profileImagePath?.thumbnail}
                                            size={45}
                                        ></AvatarBase>

                                        <View style={{ paddingLeft: 10, justifyContent: "center" }}>
                                            <BoldText color={colors.text}>
                                                {"@"}
                                                {trimHelper(this.state.toClient?.alias, 35, true)}
                                            </BoldText>
                                            <PressableBase
                                                onPress={async () => await Clipboard.setStringAsync(this.props.transaction.to)}
                                                style={{ paddingVertical: 2 }}
                                            >
                                                <Row style={{ alignItems: "baseline" }}>
                                                    <RegularText color={colors.grey} align="left" selectable={true}>
                                                        {trimHelper(this.props.transaction.to, 30, true)}
                                                    </RegularText>
                                                    <Icon
                                                        size={16}
                                                        name="content-copy"
                                                        color={colors.grey}
                                                        style={{ marginLeft: 10 }}
                                                    />
                                                </Row>
                                            </PressableBase>
                                        </View>
                                    </Row>
                                ) : (
                                    <RegularText fixedWidth={425} color={colors.grey}>
                                        {this.props.transaction.to}
                                    </RegularText>
                                )}
                            </View>
                            <View style={styles.container}>
                                <RegularText style={{ marginBottom: 5 }}>{t("transaction_id")}</RegularText>
                                <PressableBase
                                    onPress={async () => await Clipboard.setStringAsync(this.props.transaction.id)}
                                    style={{ paddingVertical: 2 }}
                                >
                                    <Row style={{ alignItems: "baseline" }}>
                                        <RegularText color={colors.grey}>
                                            {trimHelper(this.props.transaction.id, 35, true)}
                                        </RegularText>
                                        <Icon
                                            size={16}
                                            name="content-copy"
                                            color={colors.grey}
                                            style={{ marginLeft: 10 }}
                                        />
                                    </Row>
                                </PressableBase>
                            </View>
                        </View>
                    </View>
                )}
            </>
        );
    }
}

const styles = StyleSheet.create({
    buttons: {
        marginVertical: 15,
    },
    addressContainer: {
        marginTop: 5,
        backgroundColor: colors.shadow,
        padding: 10,
        borderRadius: settings.cardRadius,
        justifyContent: "center",
    },
    qr: {
        flexDirection: "column",
        alignItems: "center",
        marginVertical: 20,
    },
    button: {
        width: 40,
        marginHorizontal: 5,
        backgroundColor: colors.secondaryShadow,
        borderRadius: 10,
        justifyContent: "center",
        padding: 10,
    },
    container: {
        borderTopColor: colors.grey,
        borderTopWidth: 0.5,
        paddingVertical: 20,
    },
    card: {
        flex: 1,
        borderRadius: settings.cardRadius,
        marginRight: 10,
        overflow: "hidden",
        backgroundColor: colors.red,
    },
    bubble: {
        position: "absolute",
        top: -23,
        marginLeft: 3,
        backgroundColor: colors.complementary || colors.secondary,
        paddingLeft: 7,
        paddingRight: 8,
        paddingTop: 1,
        paddingBottom: 2,
        borderRadius: 10,
        borderBottomLeftRadius: 2,
    },

    circle: {
        height: 30,
        width: 30,
        borderRadius: 15,
        backgroundColor: colors.tertiaryShadow,
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        marginTop: -5,
        marginRight: 5,
    },
});
