import React, { Component } from "react";
import { FlatList, RefreshControl, StyleSheet } from "react-native";
import { NavigationType } from "@custom-types/NavigationType";
import { HeaderType } from "@custom-types/HeaderType";
import ScreenWrapper from "@components/wrapper/ScreenWrapper";
import Header from "@components/header/Header";
import Container from "@base/Container";
import { connect } from "react-redux";
import i18n from "@i18n/i18n";
import { Client } from "@custom-types/Client";
import { Route } from "@react-navigation/native";
import { REDEEMABLE } from "@custom-types/ExpercienceModel";
import ExperienceService from "@core/services/ExperienceService";
import { trimHelper } from "@utils/helpers/chat/chat.helper";
import { colors } from "@styles/globalStyles";
import RegularText from "@base/RegularText";
import Card from "@base/Card";
import BotCard from "@base/BotCard";
import SocialNetworkService from "@screens/social/service/SocialNetworkService";
import SearchBase from "@components/search/SearchBase";
import AvatarBase from "@components/avatar/AvatarBase";
import { debounce } from "lodash";
import { ExperienceNavigatorScreens } from "@navigation/ExperienceNavigator";

interface Props {
    navigation: NavigationType;
    client: Client;
    route: Route<string, { redeemable: REDEEMABLE }>;
}

interface State {
    search: string;
    syncing: boolean;
    users: Array<Client>;
}

const { t } = i18n;

export class _TransferRedeemableScreen extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.onSearch = this.onSearch.bind(this);
        this.onPress = this.onPress.bind(this);
        this.debouncedSearch = debounce(this.debouncedSearch, 1000);
        this.state = { syncing: false, users: [], search: "" };
    }

    renderItemUser = ({ item }) => {
        return (
            <Card onPress={() => this.onPress(item)} style={{ flexDirection: "row" }}>
                <AvatarBase
                    uri={item?.profileImagePath ? item?.profileImagePath.thumbnail : ""}
                    alias={item?.alias}
                    size={35}
                />

                <RegularText style={{ textAlignVertical: "center", paddingLeft: 10 }}>
                    @{trimHelper(item?.alias, 18)}
                </RegularText>
            </Card>
        );
    };

    onSearch = async (search) => {
        this.setState({ search: search, syncing: true  }, () => {
            this.debouncedSearch();
        });
    };

    async debouncedSearch() {
        if (this.state.search?.length > 1) {
            const resp = await SocialNetworkService.getInstance().searchUser(this.state.search, 1);
            if (resp) {
                this.setState({ users: resp.docs, syncing: false });
            }
        }
    }

    async onPress(client) {
        const resp = await ExperienceService.getInstance().getTransferDataRedeemable(
            this.props.route.params?.redeemable?._id,
            client._id,
        );
        if (resp) {
            this.props.navigation.navigate(ExperienceNavigatorScreens.ConfirmTransferRedeemable.routeName, {
                redeemable: this.props.route.params?.redeemable,
                client_to: client,
                data: resp,
            });
        }
    }

    render() {
        return (
            <ScreenWrapper>
                <Header title={t("transfer")} type={HeaderType.Light} {...this.props} />

                <Container style={{ flex: 1 }}>
                    <BotCard title={t("hi")} message={t("transfer_bot")}></BotCard>
                    <SearchBase value={this.state.search}  searching={this.state.syncing} onSearch={(value) => this.onSearch(value)} />
                    <FlatList
                        data={this.state.users}
                        renderItem={this.renderItemUser}
                        keyExtractor={(item) => item._id}
                        contentContainerStyle={{ paddingVertical: 20 }}
                        initialNumToRender={10}
                        maxToRenderPerBatch={10}
                        refreshControl={<RefreshControl tintColor={colors.text} refreshing={false} />}
                        onEndReachedThreshold={1}
                    />
                </Container>
            </ScreenWrapper>
        );
    }
}

const styles = StyleSheet.create({});

const mapStateToProps = (state) => {
    return {
        client: state.auth.client,
    };
};

const mapDispatchToProps = (dispatch) => ({});

const TransferRedeemableScreen = connect(mapStateToProps, mapDispatchToProps)(_TransferRedeemableScreen);

export default TransferRedeemableScreen;
