import React, { Component } from "react";
import { StyleSheet, View } from "react-native";
import { connect } from "react-redux";
import i18n from "@i18n/i18n";
import { EXPERIENCE } from "@custom-types/ExpercienceModel";
import ImageBase from "@components/image/ImageBase";
import { colors, settings } from "@styles/globalStyles";
import BoldText from "@base/BoldText";
import RegularText from "@base/RegularText";
import Row from "@base/Row";
import moment from "moment";
import { NavigationType } from "@custom-types/NavigationType";
import Card from "@base/Card";
import AvatarBase from "@components/avatar/AvatarBase";
import { ExperienceNavigatorScreens } from "@navigation/ExperienceNavigator";
import PressableBase from "@base/PressableBase";

interface Props {
    experience: EXPERIENCE;
    navigation: NavigationType;
}

interface State {
}

const { t } = i18n;

export class _ExperienceCard extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
        };
    }

    async componentDidMount() { }

    getDate = (start: Date | string, end: Date | string) => {
        const startDate = moment(start);
        const endDate = moment(end);

        if (startDate.isSame(endDate, "day")) {
            return startDate.format("DD/MM");
        }
        if (startDate.isSame(endDate, "month")) {
            return `${startDate.format("DD/MM")} - ${endDate.format("DD/MM")}`;
        }
        if (startDate.isSame(endDate, "year")) {
            return `${startDate.format("DD/MM")} - ${endDate.format("DD/MM")}`;
        }
        return `${startDate.format("DD/MM")} - ${endDate.format("DD/MM")}`
    }

    navigateToExperience = (experience) => {
        this.props.navigation.navigate('Experience', {
            screen: ExperienceNavigatorScreens.ExperienceDetail.routeName,
            params: { experience: experience },
        });
    }

    render() {
        return (
            <PressableBase
                onPress={() => this.navigateToExperience(this.props.experience)}
                style={styles.searchCard}
            >
                <Card style={{ marginVertical: 0, paddingVertical: 0, paddingHorizontal: 0 }}>
                    <View style={{ position: "relative" }}>
                        <ImageBase
                            uri={this.props.experience?.image?.carousel || this.props.experience?.image?.cover || ""}
                            autoSize={true}
                        ></ImageBase>
                        <View style={styles.tag}>
                            <Row style={{ justifyContent: "center", alignItems: "center" }}>
                                <AvatarBase uri={this.props.experience.merchant?.image?.thumbnail} size={18}></AvatarBase>
                                <RegularText
                                    style={{ paddingHorizontal: 5 }}
                                    color={colors.labelText || colors.white}
                                    fontSize={12}
                                >
                                    {this.props.experience.merchant?.name}
                                </RegularText>
                            </Row>
                        </View>
                    </View>
                    <View style={{ padding: 15, width: "100%" }}>
                        {this.props.experience?.name && <BoldText style={{ marginBottom: 5, textTransform: "uppercase" }} align="left" fontSize={18} numberOfLines={1} >
                            {this.props.experience.name}
                        </BoldText>}
                        {this.props.experience?.startDate && this.props.experience?.endDate &&
                            <RegularText style={{ textTransform: "uppercase", marginBottom: 5 }} fontSize={12} color={colors.grey}>
                                {this.getDate(this.props.experience?.startDate, this.props.experience?.endDate)}
                            </RegularText>
                        }
                        {this.props.experience?.location && this.props.experience?.location?.description &&
                            <RegularText fontSize={12} color={colors.grey}>
                                {this.props.experience.location.description}
                            </RegularText>
                        }
                    </View>
                </Card>

            </PressableBase>
        )
    }
}

const styles = StyleSheet.create({
    searchCard: {
        borderRadius: settings.cardRadius,
        overflow: "hidden",
        maxWidth: "100%",
        minWidth: 150,
        marginBottom: 20
    },
    sectionCard: {
        borderRadius: settings.cardRadius,
        marginRight: 10,
        overflow: "hidden",
        maxWidth: 300,
        minWidth: 150,
    },
    tag: {
        position: "absolute",
        top: 10,
        left: 10,
        backgroundColor: colors.complementary || colors.secondary,
        paddingHorizontal: 6,
        paddingVertical: 4,
        borderRadius: 20,
    }
});

const mapStateToProps = (state) => {
    return {
        client: state.auth.client,
    };
};

const mapDispatchToProps = (dispatch) => ({});

const ExperienceCard = connect(mapStateToProps, mapDispatchToProps)(_ExperienceCard);

export default ExperienceCard;
