import React, { Component } from "react";
import { View, StyleSheet } from "react-native";
import { colors, settings } from "@styles/globalStyles";
import store from "@store/index";
import { prePrepareTransaction, selectCurrency, cleanOperation } from "@store/actions/wallet";
import { NavigationType } from "@custom-types/NavigationType";
import { trimHelper } from "@utils/helpers/chat/chat.helper";
import AppStorage from "@utils/storage";
import Wallet from "@core/wallet/Wallet";
import Currency from "@core/currencies/Currency";
import CustomMessageCard from "@components/cards/CustomMessageCard";
import PayOrderCustomMessage from "./PayOrderCustomMessage";
import { CustomMessageType } from "@custom-types/CustomMessageType";
import TransactionCustomMessage from "./TransactionCustomMessage";
import { setCustomMessage } from "@store/actions/chat.actions";
import { CustomMessageModel } from "@custom-types/CustomMessageModel";
import { MessagesNavigatorScreens } from "@navigation/MessagesNavigator";
import NFTsMessage from "./NFTsMessage";
import Constants from "expo-constants";
import ReddemableMessage from "./ReddemableMessage";
import BoldText from "@base/BoldText";
import RegularText from "@base/RegularText";
import { REDEEMABLE, ASSET } from "@custom-types/ExpercienceModel";
import AssetMessage from "./AssetMessage";
import PressableBase from "@base/PressableBase";

interface Props {
    currentMessage: any;
    user: any;
    navigation: NavigationType;
    onPressNFT: (nftID: string, currencyId: string) => void;
    openTransaction: (id: string, data) => void;
    onPressReddemable?: (redeemable: REDEEMABLE) => void;
    onPressAsset?: (asset: ASSET) => void;
}

interface State {
    currency: Currency;
}

export default class CustomMessage extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.onPress = this.onPress.bind(this);
        this.state = {
            currency: Wallet.getInstance().findCurrencyById(this.props.currentMessage.data?.currencyId || "") || null,
        };
    }

    onPress = async () => {
        if (!this.isOwner()) {
            const wallet = Wallet.getInstance();
            const customMessage: Partial<CustomMessageModel> = {
                type: CustomMessageType.SendCrypto,
                client: store.getState().auth.client,
                address: this.props.currentMessage.data.address,
                currencyId: this.props.currentMessage.data.currencyId,
                currencyName: this.props.currentMessage.data.currencyName,
                network: this.props.currentMessage.data.network,
            };
            store.dispatch(setCustomMessage(customMessage));

            if (
                (!wallet.isTestnet() && this.props.currentMessage.data.network === "testnet") ||
                (wallet.isTestnet() && this.props.currentMessage.data.network === "mainnet")
            ) {
                this.props.navigation.navigate(MessagesNavigatorScreens.NetworkOption.routeName, {
                    data: this.props.currentMessage.data,
                    testnet: wallet.isTestnet(),
                });
            } else {
                if (
                    this.props.currentMessage.data.type == CustomMessageType.PayOrder ||
                    this.props.currentMessage.data.type == CustomMessageType.CryptoAddress
                ) {
                    store.dispatch(cleanOperation());
                    store.dispatch(selectCurrency(customMessage.currencyId));
                    store.dispatch(
                        prePrepareTransaction({
                            address: customMessage.address,
                            amount: this.props.currentMessage.data.amount,
                        }),
                    );
                    this.props.navigation.navigate(MessagesNavigatorScreens.SendCrypto.routeName, {
                        selectedCurrency: this.state.currency.getId(),
                    });
                }
            }
        }
    };

    isOwner() {
        return this.props.user._id == this.props.currentMessage.user.id;
    }

    render() {
        return (
            <View>
                {this.state.currency && (
                    <View>
                        <PressableBase onPress={this.onPress} disableOpacity={this.isOwner()}>
                            {/* ADDRESS MESSAGE */}

                            {this.props.currentMessage.data?.type === CustomMessageType.CryptoAddress && (
                                <View style={styles.customMessageContainer}>
                                    <CustomMessageCard
                                        address={trimHelper(this.props.currentMessage.data.address, 30)}
                                        currencyName={this.props.currentMessage.data.currencyName}
                                        currency={this.state.currency}
                                    />
                                </View>
                            )}

                            {/* PAY ORDER MESSAGE */}
                            {this.props.currentMessage.data?.type === CustomMessageType.PayOrder && (
                                <View style={styles.customMessageContainer}>
                                    <PayOrderCustomMessage
                                        currency={this.state.currency}
                                        currencyName={this.props.currentMessage.data.currencyName}
                                        amount={this.props.currentMessage.data.amount}
                                        address={trimHelper(this.props.currentMessage.data.address, 30)}
                                        owner={this.isOwner()}
                                    />
                                </View>
                            )}
                        </PressableBase>

                        {/* SEND CRYPTO MESSAGE */}
                        {this.props.currentMessage.data?.type === CustomMessageType.SendCrypto && (
                            <PressableBase
                                onPress={() =>
                                    this.props.openTransaction(
                                        this.state.currency?.getId() || "",
                                        this.props.currentMessage.data,
                                    )
                                }
                                disableOpacity={this.isOwner()}
                            >
                                <View style={styles.customMessageContainer}>
                                    {/* <BoldText>{this.props.currentMessage.data?.hash}</BoldText> */}
                                    <TransactionCustomMessage
                                        currencyName={this.props.currentMessage.data.currencyName}
                                        currency={this.state.currency}
                                        amount={this.props.currentMessage.data.amount}
                                        address={trimHelper(this.props.currentMessage.data.address, 30)}
                                        //transaction={messageLengthHelper(this.props.currentMessage.data.transactionId)}
                                        owner={this.isOwner()}
                                    />
                                </View>
                            </PressableBase>
                        )}

                        {/* SEND NFT MESSAGE */}

                        {this.props.currentMessage.data?.type === CustomMessageType.NFT && (
                            <PressableBase
                                onPress={() =>
                                    this.props.onPressNFT(
                                        this.props.currentMessage.data?.nft?._id,
                                        this.props.currentMessage.data?.currencyId,
                                    )
                                }
                            >
                                <View style={styles.customMessageContainer}>
                                    <NFTsMessage
                                        isTransfer={false}
                                        currency={this.state.currency}
                                        owner={this.isOwner()}
                                        nft={this.props.currentMessage?.data?.nft}
                                        network={this.props.currentMessage?.data?.network}
                                    />
                                </View>
                            </PressableBase>
                        )}

                        {/* SEND TRANSFER NFT MESSAGE */}

                        {this.props.currentMessage.data?.type === CustomMessageType.SendNFT && (
                            <PressableBase
                                onPress={() =>
                                    this.props.onPressNFT(
                                        this.props.currentMessage.data?.nft?._id,
                                        this.props.currentMessage.data?.currencyId,
                                    )
                                }
                            >
                                <View style={styles.customMessageContainer}>
                                    <NFTsMessage
                                        isTransfer={true}
                                        currency={this.state.currency}
                                        owner={this.isOwner()}
                                        nft={this.props.currentMessage?.data?.nft}
                                        network={this.props.currentMessage?.data?.network}
                                    />
                                </View>
                            </PressableBase>
                        )}
                    </View>
                )}
                {/* REDEEMABLE MESSAGE */}
                {this.props.currentMessage.data?.type === CustomMessageType.SendRedeemable && (
                    <PressableBase
                        onPress={() => this.props.onPressReddemable(this.props.currentMessage.data?.redeemable)}
                    >
                        <View style={styles.customMessageContainer}>
                            <ReddemableMessage
                                owner={this.isOwner()}
                                redeemable={this.props.currentMessage?.data?.redeemable}
                            />
                        </View>
                    </PressableBase>
                )}
                {/* ASSET MESSAGE */}
                {this.props.currentMessage.data?.type === CustomMessageType.SendAsset && (
                    <PressableBase onPress={() => this.props.onPressAsset(this.props.currentMessage.data?.asset)}>
                        <View style={styles.customMessageContainer}>
                            <AssetMessage owner={this.isOwner()} asset={this.props.currentMessage?.data?.asset} />
                        </View>
                    </PressableBase>
                )}
            </View>
        );
    }
}
const styles = StyleSheet.create({
    customMessageContainer: {
        borderRadius: settings.cardRadius,
        color: colors.white,
        marginVertical: 5,
        padding: 0,
    },
    icon: {
        width: 35,
        height: 35,
        marginRight: 15,
        borderRadius: 5,
        alignItems: "center",
        justifyContent: "center",
    },
});
