import { getEnv } from "@utils/helpers/global/global";
import * as mqtt from "mqtt";
import store from "@store/index";
import { setChatStatus } from "@store/actions/chat.actions";


export class MQTTClient {
    private static instance;

    private client: mqtt.Client;
    private mqttClientId;

    private constructor() {
    }

    public static getInstance(): MQTTClient {
        if (!MQTTClient.instance) {
            MQTTClient.instance = new MQTTClient();
            MQTTClient.instance.init();
        }

        return MQTTClient.instance;
    }

    public static restartInstance() {
        MQTTClient.instance?.client?.end();
        MQTTClient.instance = null;
        return MQTTClient.getInstance();
    }

    private init() {
        const clientId = store.getState().auth.client?._id;

        if (!this.mqttClientId) this.mqttClientId = `mqtt-${clientId}-${Math.random().toString(16).substr(2, 8)}`

        this.client = mqtt.connect(getEnv('MQTT_URL'), { clientId: this.mqttClientId, clean: false, resubscribe: true });
        
        this.client.on('connect', () => {
            store.dispatch(setChatStatus('connected'))
        })
        this.client.on('disconnect', () => {
            store.dispatch(setChatStatus('disconnected'))
            //if (store.getState().chat.status !== 'disconnected') store.dispatch(setChatStatus('disconnected'))
        })
        this.client.on('close', () => {
            store.dispatch(setChatStatus('disconnected'))
            //if (store.getState().chat.status !== 'disconnected') store.dispatch(setChatStatus('disconnected'))
        })

    }

    getClient = () => this.client;

    publish = (topic, message, opts: mqtt.IClientPublishOptions = { qos: 2, retain: true }, callback = () => null) => {
        this.client.publish(topic, message, opts, callback);
    }

    subscribe = (topic, callback = () => null) => this.client.subscribe(topic, callback);


}
