import store from "@store/index";
import axios from "axios";
import { ApiService } from "@core/services/ApiService";
import { appendFileToFormData, getEnv } from "@utils/helpers/global/global";
import { loading, ready, setMessageLoader, setProgresiveLoader } from "@store/actions/global";

import i18n from "@i18n/i18n";
import { Client } from "@custom-types/Client";
import AuthStorage from "@utils/storage/storages/AuthStorage";
import { setAuthLocalStorage } from "@core/utils/AuthAux";
import { setClient } from "@store/actions/auth";
import { setNFTs, setPOAPs, setPOSTs, setProfile } from "@store/actions/profile.actions";
import SocialNetworkService from "@screens/social/service/SocialNetworkService";
import NFTService from "./NFTService";
import { ModuleControlService, Modules, NFTsModules } from "./ModuleControlService";
import POAPService from "./POAPService";
import Wallet from "@core/wallet/Wallet";
import { Platform } from "react-native";
import { OAuthService } from "./oauth/OAuthService";
import Currency from "@core/currencies/Currency";

const { t } = i18n;

export default class ProfileService {
    private static instance: ProfileService;

    constructor() { }

    static getInstance(): ProfileService {
        if (!ProfileService.instance) {
            ProfileService.instance = new ProfileService();
        }
        return ProfileService.instance;
    }

    async setFullProfile(clientID: string) {
        try {
            const data = await this.setProfileData(clientID);
            this.setProfilePOSTs(clientID, data);
            this.setProfilePOAPs(clientID);
            return await this.setProfileNFTs(clientID);
        } catch (e) {
            console.warn(e);
        }
    }

    async getProfileByAddress(currency: Currency, address: string) {
        try {
            if (currency.getAddress() == address) {
                return store.getState().auth.client;
            }

            const url = `address/${address}/client`;
            const res = await ApiService.get(currency, url, {
                headers: await ApiService.getAuthHeaders(),
                timeout: 7000,
            });
            if (res) {
                return res.data;
            }
        } catch (e) {
            console.warn(e);
        }
    }

    async setProfileData(clientID) {
        if (!store.getState().profile.profiles[clientID]) {
            store.dispatch(loading());
        }

        const url = `${getEnv("API_URL")}profile/${clientID}`;
        const profile = await axios.get(url, {
            headers: await ApiService.getAuthHeaders(),
        });

        if (profile?.data) {
            store.dispatch(setProfile(profile?.data));
            store.dispatch(ready());
            return profile?.data;
        }
        store.dispatch(ready());
    }

    async setProfilePOSTs(clientID, data?) {
        if (!ModuleControlService.getInstance().isNecessaryUserVerified(data?.verified)) return;

        const posts = await SocialNetworkService.getInstance().getUserPosts(clientID, 1);
        store.dispatch(
            setPOSTs({
                id: clientID,
                dataPOSTs: {
                    page: posts?.page || 1,
                    totalDocs: posts?.totalDocs || 0,
                    totalPages: posts?.totalPages || 0,
                    posts: posts?.docs || [],
                },
            }),
        );
    }

    async setProfileNFTs(clientID) {
        if (!ModuleControlService.getInstance().isNFTsModuleEnabled(NFTsModules.list)) return;
        const nfts = await NFTService.getInstance().getUserNFTs(clientID);
        store.dispatch(
            setNFTs({
                id: clientID,
                dataNFTs: nfts || [],
            }),
        );
    }

    async setProfilePOAPs(clientID) {
        if (!ModuleControlService.getInstance().isModuleEnabled(Modules.poapsModule)) return;

        const poaps = await POAPService.getInstance().getProfilePOAPs(clientID);
        store.dispatch(
            setPOAPs({
                id: clientID,
                dataPOAPs: poaps || [],
            }),
        );
    }

    async setProfileImage(clientData: Partial<Client>) {
        store.dispatch(loading());

        const url = `${getEnv("API_URL")}v3/client`;

        try {
            if (clientData?.image) {
                const form = new FormData();
                if (Platform.OS == "web") {
                    const base64 = await fetch(clientData?.image.uri);
                    const blob = await base64.blob();
                    form.append("image", blob);
                } else {
                    form.append("image", {
                        uri: clientData?.image.uri,
                        type: "image/jpeg",
                        name: "file" + Math.random(),
                    } as any);
                }

                const resp = await axios.patch(url, form, {
                    headers: { ...(await ApiService.getAuthHeaders()), "Content-Type": "multipart/form-data" },
                });

                let client = resp?.data;

                client.profileImagePath = {
                    square: `${client.profileImagePath?.square}?timestamp=${Date.now()}`,
                    thumbnail: `${client.profileImagePath?.thumbnail}?timestamp=${Date.now()}`,
                };
                store.dispatch(ready());
                OAuthService.getInstance().setClient(Client.create(client));
                store.dispatch(ready());
            }
        } catch (error) {
            store.dispatch(ready());
        }
    }

    async setProfile(clientData: Partial<Client>) {
        store.dispatch(loading());
        const url = `${getEnv("API_URL")}v3/client`;
        const body = clientData || {};

        const resp = await axios.patch(url, body, {
            headers: { ...(await ApiService.getAuthHeaders()) },
        });

        let client = resp?.data;

        client.profileImagePath = {
            square: `${client.profileImagePath?.square}?timestamp=${Date.now()}`,
            thumbnail: `${client.profileImagePath?.thumbnail}?timestamp=${Date.now()}`,
        };

        OAuthService.getInstance().setClient(Client.create(client));
        store.dispatch(ready());

        return resp;
    }
}
