import { colors } from "@styles/globalStyles";
import React, { Component } from "react";
import { View, StyleSheet } from "react-native";
import Icon from "@components/icons";
import { NavigationType } from "@custom-types/NavigationType";
import Container from "@base/Container";
import { Client } from "@custom-types/Client";
import BoldText from "@base/BoldText";
import { trimHelper } from "@utils/helpers/chat/chat.helper";
import AvatarBase from "@components/avatar/AvatarBase";
import { SocialNetworkNavigatorScreens } from "@navigation/SocialNetworkNavigator";
import { UsersViewType } from "@custom-types/UsersViewType";
import { ProfileSections } from "@custom-types/ProfileType";
import { ProfileNavigatorScreens } from "@navigation/ProfileNavigator";
import SocialNetworkService from "../service/SocialNetworkService";
import Badge from "@base/Badge";
import { connect } from "react-redux";
import { FollowerRequestType } from "@custom-types/FollowerRequestType";
import PressableBase from "@base/PressableBase";

interface Props {
    navigation: NavigationType;
    client: Client;
    followerRequests: Array<FollowerRequestType>;
}

interface State { }

export class _SocialHeader extends Component<Props, State> {
    focusListener: any;
    constructor(props: Props) {
        super(props);
    }

    componentDidMount = () => {
        SocialNetworkService.getInstance().getFollowerRequests();
    };

    openProfile = () => {
        this.props.navigation.navigate(SocialNetworkNavigatorScreens.Profile.routeName, {
            screen: ProfileNavigatorScreens.ProfileMain.routeName,
            params: { clientID: this.props.client?._id, profileSection: ProfileSections.socialNetwork },
        });
    };

    setActive = (screen) => {
        const state: any = this.props.navigation.getState();

        if (state.routeNames[state.index] == screen) {
            return colors.text;
        } else {
            return colors.grey;
        }
    };

    componentDidUpdate(props) {
        if (props != this.props) {
            this.forceUpdate();
        }
        this.focusListener = this.props.navigation.addListener("focus", () => {
            this.forceUpdate();
        });
    }

    componentWillUnmount() {
        try {
            this.focusListener.remove();
        } catch (e) { }
    }

    openRequests = (UsersViewType: UsersViewType) => {
        this.props.navigation.navigate(SocialNetworkNavigatorScreens.Users.routeName, {
            UsersViewType,
            // postID: this.props.post.id,
        });
    };

    render() {
        return (
            <View style={styles.container}>
                <Container>
                    {/* HEADER */}
                    <View style={styles.row}>
                        <View style={styles.left}>
                            <PressableBase
                                onPress={this.openProfile}
                                style={{ flexDirection: "row" }}
                                accessibilityLabel="discover-profile"
                            >
                                <View style={styles.buttonContainer}>
                                    <AvatarBase
                                        uri={
                                            this.props.client?.profileImagePath
                                                ? this.props.client?.profileImagePath.thumbnail
                                                : ""
                                        }
                                        alias={this.props.client?.alias}
                                        size={35}
                                        isOwner={true}
                                        onPress={this.openProfile}
                                    />
                                </View>

                                <BoldText fontSize={16} style={{ alignSelf: "center", marginLeft: 5 }}>
                                    @{trimHelper(this.props.client?.alias, 11)}
                                </BoldText>
                                <View style={{ alignSelf: "center" }}>{/* <VerifiedIcon verified={true} /> */}</View>
                            </PressableBase>
                        </View>
                        <View style={styles.right}>
                            {this.props.client.isPrivateProfile &&
                                <PressableBase
                                    style={styles.touchable}
                                    onPress={() => this.openRequests(UsersViewType.REQUESTS)}
                                    accessibilityLabel="follower-discover-botton"
                                >
                                    <View>
                                        <Icon
                                            name="heart"
                                            size={24}
                                            color={this.setActive(SocialNetworkNavigatorScreens.Users.routeName)}
                                            align="center"
                                            style={styles.icon}
                                        />
                                        <Badge
                                            size={18}
                                            count={this.props.followerRequests?.length}
                                            color={colors.complementary || colors.secondary}
                                        />
                                    </View>
                                </PressableBase>}

                            <PressableBase
                                style={styles.touchable}
                                onPress={() =>
                                    this.props.navigation.navigate(
                                        SocialNetworkNavigatorScreens.SocialNetworkSearch.routeName
                                    )
                                }
                                accessibilityLabel="search-discover-botton"
                            >
                                <Icon
                                    name="search"
                                    size={24}
                                    color={this.setActive(SocialNetworkNavigatorScreens.SocialNetworkSearch.routeName)}
                                    align="center"
                                    style={styles.icon}
                                />
                            </PressableBase>

                            {/* <PressableBase
                                style={styles.touchable}
                                onPress={() =>
                                    this.props.navigation.navigate(SocialNetworkNavigatorScreens.Discover.routeName)
                                }
                                accessibilityLabel="discover-botton"
                            >
                                <Icon
                                    name="discover"
                                    size={24}
                                    color={this.setActive(SocialNetworkNavigatorScreens.Discover.routeName)}
                                    align="center"
                                    style={styles.icon}
                                />
                            </PressableBase> */}
                        </View>
                    </View>
                </Container>
            </View>
        );
    }
}

const styles = StyleSheet.create({
    container: {
        flexDirection: "row",
        zIndex: 9999,
        height: 65,
    },
    buttonContainer: {
        borderRadius: 25,
        width: 50,
        height: 50,
        justifyContent: "center",
        alignItems: "center",
    },
    row: {
        marginLeft: -15,
        marginRight: -15,
        alignContent: "space-between",
        flexDirection: "row",
        alignItems: "center",
        minHeight: 90,
        paddingVertical: 20,
        flex: 1,
    },

    left: {
        //flex: 0.2,
        flexDirection: "row",
        alignItems: "center",
    },
    right: {
        paddingRight: 10,
        flex: 1,
        flexDirection: "row",
        justifyContent: "flex-end",
        alignItems: "flex-end",
    },
    icon: {
        alignSelf: "center",
        textAlignVertical: "center",
    },
    touchable: {
        paddingLeft: 14,
        paddingVertical: 5,
    },
});

const mapStateToProps = (state) => {
    return {
        client: state.auth.client,
        followerRequests: state.social.followerRequests,
    };
};

const mapDispatchToProps = (dispatch) => ({});

const SocialHeader = connect(mapStateToProps, mapDispatchToProps)(_SocialHeader);

export default SocialHeader;
