import { put, takeEvery, take, spawn, delay } from "redux-saga/effects";
import {
    SHOW_POPUP,
    hidePopup,
    RESET,
    hideSnackbar,
    SHOW_SNACKBAR,
    loading,
    readyToRender,
    ready,
} from "@store/actions/global";
import { Platform } from "react-native";
import * as Updates from "expo-updates";
import AppStorage from "@utils/storage";
import i18n from "@i18n/i18n";
import moment from "moment";
import { isNetworkAvailable } from "@utils/helpers/global/global";
import * as Localization from "expo-localization";
import store from "@store/index";
import { NotificationService } from "@core/services/NotificationService";
import WalletConnectV2Service from "@core/services/WalletConnectV2Service";
import { ChatService } from "@core/services/ChatService";
import { WalletService } from "@core/services/WalletService";
import { RELOAD } from "@store/actions/global";
import { sync } from "@store/actions/wallet";

function* reload(action) {
    yield put(loading());

    store.dispatch(readyToRender());
    WalletService.getInstance().syncBalance();
    NotificationService.getInstance().getNotifications();
    WalletConnectV2Service.getInstance();
    ChatService.restartInstance();
    yield put(ready());
    action.callBack();
}

function handleReset() {
    if (Platform.OS !== "web") {
        Updates.reloadAsync();
    } else {
        window.location.reload();
    }
}

function* handleShowPopup(action) {
    const options: PopupOptions = action.options;

    yield delay(options.type == "ERROR" ? 3000 : 2000);
    yield put(hidePopup());
}

function* handleShowSnackbar(action) {
    const options: SnackbarOptions = action.options;
    yield delay(options.type == "ERROR" || "MESSAGE" ? 3000 : 2000);
    yield put(hideSnackbar());
}

function* getLanguage() {
    const language = yield AppStorage.getItem("language");
    if (language) {
        // i18n.locale = "es";
        moment.locale("en");
        i18n.locale = language;
        // moment.locale(language);
    } else {
        i18n.locale = Localization.locale;
        yield AppStorage.setItem("language", Localization.locale.substring(0, 2));
    }
}

function* errorHandler() {
    if (Platform.OS !== "web") {
        const defaultErrorHandler = ErrorUtils.getGlobalHandler();
        const myErrorHandler = (e, isFatal) => {
            if (isFatal) {
                console.warn(e);
                //Updates.reloadAsync()
            }
            defaultErrorHandler(e, isFatal);
        };
        ErrorUtils.setGlobalHandler(myErrorHandler);
    }
}

function* setIsNetworkAvailable() {
    isNetworkAvailable();
}

export default function* main() {
    while (true) {
        yield spawn(errorHandler);
        yield spawn(getLanguage);
        // yield spawn(setIsNetworkAvailable);
        yield takeEvery(RELOAD, reload);
        yield takeEvery(SHOW_SNACKBAR, handleShowSnackbar);
        yield takeEvery(RESET, handleReset);
        yield spawn(handleShowPopup, yield take(SHOW_POPUP));
    }
}
function* setWalletConnect() {
    throw new Error("Function not implemented.");
}
