import React, { Component } from "react"
import { StyleSheet } from "react-native"
import { Header } from "@components/header/Header"
import Container from "@base/Container"
import { HeaderType } from "@custom-types/HeaderType"
import { NavigationType } from "@custom-types/NavigationType"
import { connect } from "react-redux"
import Currency from "@core/currencies/Currency"
import ScreenWrapper from "@components/wrapper/ScreenWrapper"
import i18n from "@i18n/i18n"
import FlatListBase from "@base/FlatListBase"
import ActivityCard from "./components/ActivityCard"
import { ActivityModel } from "@custom-types/ActivityModel"
import { Route } from "@react-navigation/native"

interface Props {
    navigation: NavigationType
    currencies: Array<Currency>
    selectedCurrency: string
    activity: Array<ActivityModel>
}

interface State {
}

const { t } = i18n

export class _ActivityScreen extends Component<Props, State> {

    constructor(props: Props) {
        super(props)

        this.state = {}
    }

    renderItem = ({ item }) => {
        return (
            <ActivityCard activity={item} navigation={this.props.navigation} />
        );
    };

    render() {
        return (
            <ScreenWrapper>
                <Header
                    title={t("activity")}
                    type={HeaderType.Light}
                    {...this.props}
                />
                <Container style={{ flex: 1, justifyContent: "space-between" }}>
                    <FlatListBase
                        data={this.props.activity}
                        renderItem={(item) => this.renderItem(item)}
                        keyExtractor={(item) => item}
                    />
                </Container>
            </ScreenWrapper>
        )
    }
}

const styles = StyleSheet.create({
})

const mapStateToProps = (state) => {
    return {
        activity: state.activity.activity
    }
}

const mapDispatchToProps = (dispatch) => ({})

const ActivityScreen = connect(mapStateToProps, mapDispatchToProps)(_ActivityScreen)

export default ActivityScreen
