import React, { Component } from "react";
import { View, StyleProp, ViewStyle } from "react-native";
import RegularText from "@base/RegularText";
import { BoldText } from "@base/BoldText";
import { colors } from "@styles/globalStyles";
import Card from "@base/Card";
import { NavigationType } from "@custom-types/NavigationType";
import AvatarBase from "@components/avatar/AvatarBase";

interface Props {
    initial: string;
    clientID?: string;
    title: string;
    selected: boolean;
    profileImagePath: string;
    navigation: NavigationType;
    subtitle?: string;
    info?: string;
    unread?: number;
    style?: StyleProp<ViewStyle>;
    onPress: () => void;
    onLongPress: () => void;
}

export class MessageCard extends Component<Props, any> {
    constructor(props: Props) {
        super(props);
    }

    redirectToImage = () => {
        // if (this.props.profileImagePath) {
        //     this.props.navigation.navigate(MessagesNavigatorScreens.ProfileImage.routeName, {
        //         imagePath: this.props.profileImagePath,
        //         name: this.props.title,
        //     });
        // }
    };

    render() {
        return (
            <Card
                onPress={this.props.onPress}
                onLongPress={this.props.onLongPress}
                selected={this.props.selected}
                left={
                    <View style={{ flexDirection: "row" }}>
                        <View style={{ alignSelf: "center" }}>
                            <AvatarBase uri={this.props.profileImagePath} alias={this.props.initial} size={35} />
                        </View>
                        <View style={{ marginLeft: 10, justifyContent: "center" }}>
                            <BoldText numberOfLines={1} fixedWidth={180}>
                                {this.props.title}
                            </BoldText>
                            {!!this.props.subtitle && (
                                <RegularText numberOfLines={1} fontSize={11} style={{ flex: 1, flexWrap: "nowrap" }}>
                                    {this.props.subtitle}
                                </RegularText>
                            )}
                        </View>
                    </View>
                }
                right={
                    <View style={{ flexDirection: "row" }}>
                        <View>
                            {!!this.props.info && (
                                <RegularText style={{ fontSize: 10, textAlign: "right" }}>
                                    {" "}
                                    {this.props.info}{" "}
                                </RegularText>
                            )}
                            {this.props.unread && (
                                <View
                                    style={{
                                        alignSelf: "flex-end",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        backgroundColor: colors.secondary,
                                        borderRadius: 10,
                                        height: 20,
                                        width: 20,
                                        marginTop: 3,
                                        overflow: "hidden",
                                    }}
                                    accessibilityLabel="new-message"
                                >
                                    <RegularText
                                        color={colors.white}
                                        align="center"
                                        style={{
                                            fontSize: 10,
                                        }}
                                    >
                                        {this.props.unread}
                                    </RegularText>
                                </View>
                            )}
                        </View>
                    </View>
                }
                accessibilityLabel="message-card"
            />
        );
    }
}
const isUpdated = (prevProps, nextProps) => {
    return prevProps == nextProps;
};

export default React.memo(MessageCard, isUpdated);
