import React, { Component } from "react";
import { NavigationType } from "@custom-types/NavigationType";
import ScreenWrapper from "@components/wrapper/ScreenWrapper";
import i18n from "@i18n/i18n";
import Container from "@base/Container";
import RegularText from "@base/RegularText";
import {
 
    SafeAreaView,
    ScrollView,
    StyleSheet,
    
    View,
    
} from "react-native";
import { colors, settings } from "@styles/globalStyles";
import Row from "@base/Row";
import Icon from "@components/icons";
import BoldText from "@base/BoldText";
import { LoginNavigatorScreens } from "@navigation/LoginNavigator";
import { HeaderType } from "@custom-types/HeaderType";
import { Header } from "@components/header/Header";
import { Route } from "@react-navigation/native";
import AvatarBase from "@components/avatar/AvatarBase";
import Initializer, { AppStatus } from "../initializer/Initializer";
import { OAuthService } from "@core/services/oauth/OAuthService";
import SeedHistoryList from "@components/history/SeedHistoryList";
import PressableBase from "@base/PressableBase";

interface Props {
    navigation: NavigationType;
    route: Route<string, { userData: any }>;
}

interface State {
    userData: any;
}

const { t } = i18n;

export default class UserOAuthScreen extends Component<Props, State> {
    oAuthService: OAuthService;

    constructor(props: Props) {
        super(props);
        this.onPressCreate = this.onPressCreate.bind(this);
        this.onPressImport = this.onPressImport.bind(this);
        this.handleBackButton = this.handleBackButton.bind(this);
        this.state = {
            userData: null,
        };
        this.oAuthService = OAuthService.getInstance();
    }

    componentDidMount(): void {
        this.init();
    }

    async init() {
        const userData = this.oAuthService.getClientData();
        this.setState({
            userData,
        });
    }

    onPressImport() {
        this.props.navigation.navigate(LoginNavigatorScreens.ImportSeed.routeName);
    }

    async onPressCreate() {
        await this.oAuthService.newAlias();
        this.props.navigation.navigate(LoginNavigatorScreens.SetProfile.routeName);
        //Initializer.redirect(this.props.navigation, { redirectToProfile: true })
    }

    async handleBackButton() {
        await this.oAuthService.signout();
        Initializer.redirect(this.props.navigation);
    }

    render() {
        return (
            <ScreenWrapper>
                <Header
                    type={HeaderType.Light}
                    {...this.props}
                    leftBtn={{
                        onPress: () => {
                            this.handleBackButton();
                        },
                        icon: "arrow-left",
                        size: 24,
                    }}
                />
                {this.state.userData && (
                    <ScrollView style={{ height: 200 }}>
                        <Container style={styles.container}>
                            <View style={{ flex: 1, paddingTop: 30 }}>
                                <AvatarBase
                                    size={100}
                                    uri={this.state.userData?.avatar || ""}
                                    alias={this.state.userData?.name}
                                ></AvatarBase>

                                <View style={{ paddingTop: 30 }}>
                                    <BoldText align="center" fontSize={18}>
                                        {this.state.userData?.email}
                                    </BoldText>
                                </View>

                                <View style={{ paddingTop: 25 }}>
                                    <View style={styles.buttonsWrapper}>
                                        <PressableBase onPress={this.onPressCreate}>
                                            <Row style={[styles.button, { backgroundColor: colors.secondary }]}>
                                                <Icon size={20} color={colors.white} name="person-plus"></Icon>
                                                <RegularText style={{ paddingLeft: 10 }} color={colors.white}>
                                                    {t("create")}{" "}
                                                    <BoldText color={colors.white}>{t("new_user")}</BoldText>
                                                </RegularText>
                                            </Row>
                                        </PressableBase>
                                        <PressableBase onPress={this.onPressImport} vibrate={true}>
                                            <Row style={[styles.button, { backgroundColor: colors.secondary }]}>
                                                <Icon size={20} color={colors.white} name="person-check"></Icon>
                                                <RegularText style={{ paddingLeft: 10 }} color={colors.white}>
                                                    {t("import")}{" "}
                                                    <BoldText color={colors.white}>{t("seed_phrase")}</BoldText>
                                                </RegularText>
                                            </Row>
                                        </PressableBase>
                                    </View>
                                    <View style={{ width: "100%" }}>
                                        <View>
                                            <SeedHistoryList
                                                listHeader={
                                                    <Row
                                                        style={{
                                                            justifyContent: "flex-start",
                                                            alignItems: "center",
                                                            marginBottom: 10,
                                                            paddingTop: 30
                                                        }}
                                                    >
                                                        <View
                                                            style={{
                                                                backgroundColor: colors.complementary,
                                                                borderRadius: 15,
                                                                height: 30,
                                                                width: 30,
                                                                alignItems: "center",
                                                                justifyContent: "center",
                                                                alignContent: "center",
                                                            }}
                                                        >
                                                            <Icon
                                                                size={18}
                                                                color={colors.white}
                                                                name={"person-list"}
                                                            ></Icon>
                                                        </View>
                                                        <SafeAreaView style={{ flex: 1 }}>
                                                            <RegularText align="justify" style={{ paddingLeft: 8 }}>
                                                                {t("use_account_associated")}{" "}
                                                                {this.state.userData?.email}
                                                            </RegularText>
                                                        </SafeAreaView>
                                                    </Row>
                                                }
                                                navigation={this.props.navigation}
                                            />
                                        </View>
                                    </View>
                                </View>
                            </View>
                        </Container>
                    </ScrollView>
                )}
            </ScreenWrapper>
        );
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,

        alignItems: "center",
        alignContent: "center",
    },
    buttonsWrapper: {
        justifyContent: "center",
        alignItems: "center",
        paddingTop: 0,
    },
    button: {
        minWidth: 230,
        padding: 10,
        alignItems: "center",
        textAlignVertical: "center",
        alignSelf: "center",
        textAlign: "center",
        borderRadius: settings.mainButtonsRadius || 25,
        overflow: "hidden",
        marginVertical: 5,
    },
});
