import { Contact } from "@custom-types/Contact";
import { Chat } from "@custom-types/Chat";
import { CustomMessageModel } from "@custom-types/CustomMessageModel";
import { runSagasUpdateChat } from "@store/sagas/message.sagas";

export const SELECT_CHAT = "SELECT_CHAT";
export const SET_CHATS = "SET_CHATS";
export const SET_CLIENT = "SET_CLIENT";
export const SET_CHAT_STATUS = "SET_CHAT_STATUS";
export const SET_CONTACTS = "SET_CONTACTS";
export const SELECT_CONTACT = "SELECT_CONTACT";
export const SET_CUSTOM_MESSAGE = "SET_CUSTOM_MESSAGE";
export const SET_UNREAD_MESSAGES = "SET_UNREAD_MESSAGES";
export const RESET_CHAT = "RESET_CHAT";
export const UPDATE_MESSAGE = "UPDATE_MESSAGE";
export const ADD_CHAT = "ADD_CHAT";
export const UPDATE_CHAT = "UPDATE_CHAT";
export const UPDATE_ORDER = "UPDATE_ORDER";
export const ADD_CHATS = "ADD_CHATS";

export const selectChat = (chat: Partial<Chat>) => {
    return { type: SELECT_CHAT, chat };
};

export const setChats = (items: [{ [id: string]: Chat }], order: Array<string>) => {
    return { type: SET_CHATS, items, order };
};

export const setChatStatus = (status) => {
    return { type: SET_CHAT_STATUS, status };
};

export const setContacts = (contacts: Contact[]) => {
    return { type: SET_CONTACTS, contacts };
};

export const selectContact = (contact: Contact) => {
    return { type: SELECT_CONTACT, contact };
};

export const setCustomMessage = (customMessage: Partial<CustomMessageModel>) => {
    return { type: SET_CUSTOM_MESSAGE, customMessage };
};

export const setUnreadMessages = (UnreadMessages: number) => {
    return { type: SET_UNREAD_MESSAGES, unread: UnreadMessages };
};

export const resetChat = () => {
    return { type: RESET_CHAT };
};

export const addChat = (chat: Chat) => {
    return { type: ADD_CHAT, chat };
};

export const updateChat = (chat: Chat) => {
    const action = { type: UPDATE_CHAT, chat };

    runSagasUpdateChat(action);
    return action;
};

export const updateOrder = (order: Array<string>) => {
    return { type: UPDATE_ORDER, order };
};

export const addChats = (items: [{ [id: string]: Chat }], order: Array<string>) => {
    return { type: ADD_CHATS, items, order };
};
