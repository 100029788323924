import React, { Component } from "react";
import { NavigationType } from "@custom-types/NavigationType";
import i18n from "@i18n/i18n";
import { StyleSheet, View, ViewStyle } from "react-native";
import { colors, settings } from "@styles/globalStyles";
import { connect } from "react-redux";
import { Client } from "@custom-types/Client";
import FlatListBase from "@base/FlatListBase";
import { SectionTitle } from "@screens/home/components/SectionTitle";
import { ActivityNavigatorScreens } from "@navigation/ActivityNavigator";
import ActivityCard from "@screens/activity/components/ActivityCard";
import { ActivityModel } from "@custom-types/ActivityModel";
import ActivityService from "@core/services/ActivityService";

interface Props {
    navigation: NavigationType;
    containerStyle?: ViewStyle;
    client: Client;
    activity: Array<ActivityModel>
}

interface State {
}

const { t } = i18n;

export class _ActivitySection extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {}
    }

    renderItem = ({ item }) => {
        return (
            <ActivityCard activity={item} navigation={this.props.navigation} />
        );
    };

    navigateToSection = () => {
        this.props.navigation.navigate('Activity', {
            screen: ActivityNavigatorScreens.ActivityMain.routeName,
        });
    }

    render() {
        return (
            this.props.activity.length > 0 &&
            <View style={[{ paddingVertical: 10 }, this.props.containerStyle]}>
                <SectionTitle
                    onPress={this.navigateToSection}
                    title={t("activity").toUpperCase()}
                    icon={"🔎"}
                    seeMore={false}
                    {...this.props}
                ></SectionTitle>

                <FlatListBase
                    data={this.props.activity.slice(0, 2)}
                    renderItem={(item) => this.renderItem(item)}
                    keyExtractor={(item) => item.id}
                    maxToRenderPerBatch={10}
                    contentContainerStyle={{ marginTop: 10 }}
                ></FlatListBase>
            </View>
        );
    }
}

const styles = StyleSheet.create({
});

const mapStateToProps = (state) => {
    return {
        client: state.auth.client,
        activity: state.activity.activity
    };
};

const mapDispatchToProps = (dispatch) => ({});

const ActivitySection = connect(mapStateToProps, mapDispatchToProps)(_ActivitySection);

export default ActivitySection;


