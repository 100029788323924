import Currency from "@core/currencies/Currency";
import store from "@store/index";
import { getEnv } from "@utils/helpers/global/global";
import axios from "axios";
import { Platform } from "expo-modules-core";
import { AuthService } from "./AuthService";
import Web3 from "web3";
import Wallet from "@core/wallet/Wallet";
const web3 = new Web3();
import { v4 as uuidv4 } from "uuid";
import LocationService from "../services/LocationService";

export class ApiService {
    static url(currency: Currency, endpoint: string) {
        return `${getEnv(
            "API_URL",
        )}v2/${currency.getBlockchain()}/${currency.getNetworkType()}/${currency.getSymbol()}/${endpoint}`;
    }

    static async getAuthHeaders() {
        const client = store.getState().auth.client?._id || null;
        const uuid = uuidv4();
        const date = new Date().toISOString();
        const data = `${uuid}/${date}`;
        const eth = Wallet.getInstance().findCurrencyById("ethereum.mainnet.native.eth");
        const signedData = await eth.signMessage(data, eth.getDefaultPath());

        const base64data = btoa(JSON.stringify({ data, signature: signedData }));

        const headers = {
            client,
            ...ApiService.headers(),
            "Seed-Authorization": base64data,
        };
        return headers;
    }

    static headers() {
        const headers = {
            "wallet": getEnv("id"),
            "accept-Language": LocationService.getInstance().getLang(),
            "timezone": LocationService.getInstance().getTimeZone(),
            "platform": Platform.OS,
        };
        return headers;
    }

    static async post(currency: Currency, endpoint: string, body: any, options?: any) {
        const url = this.url(currency, endpoint);
        return axios.post(url, body, { headers: await ApiService.getAuthHeaders() });
    }

    static async get(currency: Currency, endpoint: string, options?: any) {
        const url = this.url(currency, endpoint);
        return axios.get(url, { headers: ApiService.headers(), ...options });
    }
}
