import React, { Component } from "react";
import { StyleSheet, Text, TextStyle, ViewStyle } from "react-native";
import { colors } from "@styles/globalStyles";

interface Props {
    style?: TextStyle | Array<ViewStyle>;
    align?: "auto" | "left" | "right" | "center" | "justify";
    fontSize?: number;
    color?: string;
    fixedWidth?: number;
    selectable?: boolean;
    numberOfLines?: number;
    children?: any;
    onPress?: () => void;
}

interface State {
    inlineStyles: TextStyle;
    selectable: boolean;
}

export default class RegularText extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            inlineStyles: {
                textAlign: props.align || "left",
                fontSize: this.calcFontSize(props),
                color: props.color || colors.text,
            },
            selectable: props.selectable,
        };
    }

    calcFontSize(props) {
        let maxFontSize = props.fontSize ? props.fontSize : 16;
        let value = (this.props.children || "").toString();
        if (props.fixedWidth) {
            let fontSize = (props.fixedWidth * 1.5) / value.length;
            if (fontSize > maxFontSize) fontSize = maxFontSize;
            if (fontSize < 11) fontSize = 11;
            return fontSize;
        }
        return maxFontSize;
    }

    componentDidUpdate(props) {
        if (props != this.props) {
            this.setState({
                inlineStyles: {
                    ...this.state.inlineStyles,
                    fontSize: this.calcFontSize(props),
                },
            });
        }
    }

    addStyles: () => TextStyle = () => ({ fontWeight: "normal" });

    render() {
        return (
            <Text
                onPress={this.props?.onPress}
                numberOfLines={this.props.numberOfLines}
                style={[styles.defaultStyles, this.state.inlineStyles, this.props.style, this.addStyles()]}
                selectable={this.state.selectable}
            >
                {this.props.children}
            </Text>
        );
    }
}

const styles = StyleSheet.create({
    defaultStyles: {
        fontFamily: "NunitoRegular",
    },
});
