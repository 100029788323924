import React, { Component } from "react";
import {
    EmitterSubscription,
    FlatList,
    Keyboard,
    Platform,
    RefreshControl,
    StyleSheet,
    View,
} from "react-native";
import { NavigationType } from "@custom-types/NavigationType";
import i18n from "@i18n/i18n";
import { connect } from "react-redux";
import ScreenWrapper from "@components/wrapper/ScreenWrapper";
import { HeaderType } from "@custom-types/HeaderType";
import Header from "@components/header/Header";
import Container from "@base/Container";
import WalletConnectDataService, { IWalletConnectStore } from "./../../core/services/WalletConnectDataService";
import DappCard from "./../../components/cards/DappCard";
import { FloatButton } from "@base/FloatButton";
import { DappsNavigatorScreens } from "@navigation/DappsNavigator";
import { WalletConnectNavigatorScreens } from "@navigation/WalletConnectNavigator";
import DappsService, { IDaapData } from "@core/services/DappsService";
import BoldText from "@base/BoldText";
import store from "@store/index";
import { hideModal, loading, ready, setSearch, showModal } from "@store/actions/global";
import Wallet from "@core/wallet/Wallet";
import { colors } from "@styles/globalStyles";
import SearchBase from "@components/search/SearchBase";
import BotCard from "@base/BotCard";
import { Client } from "@custom-types/Client";

interface Props {
    navigation: NavigationType;
    dapps: Array<IWalletConnectStore>;
    selectedCurrency: string;
    client: Client;
}

interface State {
    exploreDapps: Array<IDaapData>;
    searchDapps: Array<IDaapData>;
    isSearching: boolean;
    totalPages: number;
    page: number;
    syncing: boolean;
    search: string;
    keyboardOpen: boolean;
    botWarning: boolean;
}

const { t } = i18n;

export class _DappsScreen extends Component<Props, State> {
    private keyboardWillShowSub: EmitterSubscription;
    private keyboardWillHideSub: EmitterSubscription;
    constructor(props: Props) {
        super(props);
        this.getFilteredDapps = this.getFilteredDapps.bind(this);
        this.loadMoreDapps = this.loadMoreDapps.bind(this);
        this.onRefresh = this.onRefresh.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.state = {
            exploreDapps: [],
            searchDapps: [],
            isSearching: false,
            totalPages: 1,
            page: 1,
            syncing: false,
            search: "",
            keyboardOpen: false,
            botWarning: false,
        };
    }

    async componentDidMount() {
        store.dispatch(loading());

        this.keyboardWillShowSub = Keyboard.addListener("keyboardDidShow", () => this.setState({ keyboardOpen: true }));
        this.keyboardWillHideSub = Keyboard.addListener("keyboardDidHide", () =>
            this.setState({ keyboardOpen: false })
        );
        const response = await DappsService.getInstance().getAllDapps(this.state.page);
        if (response) {
            this.setState({
                exploreDapps: response.docs,
                totalPages: response.totalPages,
                page: this.state.page + 1,
            });
        }
        if (Platform.OS == "web") {
            this.setState({ botWarning: true });
            setTimeout(() => {
                this.setState({ botWarning: false });
            }, 15000);
        }
        store.dispatch(ready());
    }

    onSearch = async (search) => {
        this.setState({ isSearching: search.length > 0, search: search });
        if (search.length > 0) {
            const response = await DappsService.getInstance().getSearchDapps(1, search);
            this.setState({ searchDapps: response.docs });
        }
    };

    getFilteredDapps(yourDapps: boolean) {
        if (!yourDapps) {
            // const filteredDapps = this.state.exploreDapps.filter((fDapp) => {
            //     return !this.props.dapps.find((dapp) => {
            //         return dapp.session?.peerMeta?.name == fDapp?.name;
            //     });
            // });
            return this.state.exploreDapps;
        } else {
            const filteredDapps = this.props.dapps.filter((fDapp: IWalletConnectStore) => {
                return (
                    fDapp.session.accounts[0]?.toLocaleLowerCase() ==
                    Wallet.getInstance().findCurrencyById(fDapp.network).getAddress().toLocaleLowerCase()
                );
            });

            return filteredDapps;
        }
    }

    async removeConnection(session) {
        store.dispatch(
            showModal({
                title: session.peerMeta.name,
                avatar: session.peerMeta.icons[0],

                question: t("wallet_connect_question", {
                    name: session.peerMeta.name,
                }),
                btnTitle: t("remove"),
                onPress: () => {
                    WalletConnectDataService.getInstance().removeSessionStored(session);
                    store.dispatch(hideModal());
                },
            })
        );
    }

    renderItemExploreDapps = ({ item }) => (
        <DappCard
            title={item.name}
            icon={item.logo}
            onPress={() => this.openDappExplore(item)}
            connected={false}
            views={item.visitsCount}
        />
    );
    renderItemYourDapps = ({ item }) => (
        <DappCard
            title={item.session.peerMeta.name}
            icon={item.session.peerMeta.icons[0]}
            network={item.network}
            onPress={() => this.openDapp(item)}
            connected={item.session.connected}
            onLongPress={() => this.removeConnection(item.session)}
        />
    );

    renderItemSearchDapps = ({ item }) => {
        const connector = this.props.dapps.find((d) => {
            return (
                d.session.peerMeta.url == item.url &&
                d.session.accounts[0]?.toLocaleLowerCase() ==
                Wallet.getInstance().findCurrencyById(d.network).getAddress().toLocaleLowerCase()
            );
        });
        if (connector !== undefined) {
            return (
                <DappCard
                    title={connector.session.peerMeta.name}
                    icon={connector.session.peerMeta.icons[0]}
                    network={connector.network}
                    onPress={() => this.openDapp(connector)}
                    connected={connector.session.connected}
                />
            );
        } else {
            return (
                <DappCard
                    title={item.name}
                    icon={item.logo}
                    onPress={() => this.openDappExplore(item)}
                    connected={false}
                    views={item.visitsCount}
                />
            );
        }
    };

    openDapp(item) {
        store.dispatch(setSearch(""));
        this.props.navigation.navigate(DappsNavigatorScreens.DaapsWebView.routeName, { session: item.session });
    }

    openDappExplore(item) {
        store.dispatch(setSearch(""));
        this.props.navigation.navigate(DappsNavigatorScreens.DaapsWebViewExplore.routeName, { dapp: item });
    }

    async loadMoreDapps() {
        if (this.state.page <= this.state.totalPages) {
            this.setState({ syncing: true });
            const response = await DappsService.getInstance().getAllDapps(this.state.page);
            if (response) {
                this.setState({
                    exploreDapps: this.state.exploreDapps.concat(response.docs),
                    totalPages: response.totalPages,
                    page: this.state.page + 1,
                    syncing: false,
                });
            }
        }
    }

    async onRefresh() {
        store.dispatch(loading());
        this.setState({ isSearching: false, search: "" });
        const response = await DappsService.getInstance().getAllDapps(1);

        this.setState({
            exploreDapps: response.docs,
            totalPages: response.totalPages,
            page: 2,
        });

        store.dispatch(ready());
    }

    render() {
        return (
            <ScreenWrapper>
                <Header
                    title={t("dapps")}
                    type={HeaderType.Light}
                    testnet={Wallet.getInstance().isTestnet()}
                    hideReturn={true}
                    {...this.props}
                    leftAvatar={{
                        onPress: () => {
                            this.props.navigation.navigate(DappsNavigatorScreens.Profile.routeName, {
                                clientID: this.props.client?._id,
                            });
                        },
                        uri:
                            this.props.client &&
                                this.props.client?.profileImagePath &&
                                this.props.client?.profileImagePath !== null
                                ? this.props.client?.profileImagePath.thumbnail
                                : "",
                        alias: this.props.client && this.props.client?.alias ? this.props.client?.alias : "",
                        size: 35,
                    }}
                />
                <Container style={{ marginBottom: 10 }}>
                    <SearchBase value={this.state.search} onSearch={(value) => this.onSearch(value)} />

                    {this.state.botWarning && (
                        <View style={{ marginTop: 10 }}>
                            <BotCard
                                style={{ marginTop: 10 }}
                                title={t("dapp_web_message")}
                                message={t("dapp_web_info")}
                            ></BotCard>
                        </View>
                    )}
                </Container>
                {this.state.isSearching ? (
                    <Container style={{ flex: 1 }}>
                        <FlatList
                            data={this.state.searchDapps}
                            renderItem={this.renderItemSearchDapps}
                            keyExtractor={(item) => item._id}
                            contentContainerStyle={{ paddingBottom: 20 }}
                            initialNumToRender={10}
                            numColumns={2}
                            maxToRenderPerBatch={10}
                            refreshControl={
                                <RefreshControl
                                    tintColor={colors.text}
                                    onRefresh={this.onRefresh}
                                    refreshing={this.state.syncing}
                                />
                            }
                        />
                    </Container>
                ) : (
                    <Container style={{ flex: 1 }}>
                        <FlatList
                            data={this.getFilteredDapps(false)}
                            renderItem={this.renderItemExploreDapps}
                            keyExtractor={(item) => item._id}
                            contentContainerStyle={{ paddingBottom: 20 }}
                            ListHeaderComponent={
                                <View>
                                    {this.getFilteredDapps(true).length > 0 && (
                                        <View>
                                            <BoldText
                                                style={{
                                                    paddingLeft: 5,
                                                    marginBottom: 10,
                                                }}
                                                fontSize={18}
                                            >
                                                {t("your_dapps")}
                                            </BoldText>
                                            <FlatList
                                                data={this.getFilteredDapps(true)}
                                                renderItem={this.renderItemYourDapps}
                                                keyExtractor={(item) => item._id}
                                                initialNumToRender={6}
                                                numColumns={2}
                                                maxToRenderPerBatch={10}
                                            />
                                            <View style={{ marginTop: 30 }}></View>
                                        </View>
                                    )}
                                    <BoldText
                                        style={{
                                            paddingLeft: 5,
                                            marginBottom: 10,
                                        }}
                                        fontSize={18}
                                    >
                                        {t("explore_dapps")}
                                    </BoldText>
                                </View>
                            }
                            initialNumToRender={8}
                            numColumns={2}
                            maxToRenderPerBatch={10}
                            onEndReached={this.loadMoreDapps}
                            refreshControl={
                                <RefreshControl
                                    tintColor={colors.text}
                                    onRefresh={this.onRefresh}
                                    refreshing={this.state.syncing}
                                />
                            }
                        />
                        {!this.state.keyboardOpen && (
                            <FloatButton
                                onPress={() =>
                                    this.props.navigation.navigate(
                                        WalletConnectNavigatorScreens.WalletConnectMain.routeName,
                                        {
                                            screen: WalletConnectNavigatorScreens.WalletConnectScanner.routeName,
                                            params: { goBackTo: DappsNavigatorScreens.DaapsMain.routeName },
                                        }
                                    )
                                }
                                iconName={"qr"}
                            />
                        )}
                    </Container>
                )}
            </ScreenWrapper>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        dapps: state.walletconnect.connections,
        selectedCurrency: state.wallet.selectedCurrency,
        client: state.auth.client,
    };
};

const mapDispatchToProps = (dispatch) => ({});

const DappsScreen = connect(mapStateToProps, mapDispatchToProps)(_DappsScreen);

export default DappsScreen;

const styles = StyleSheet.create({});
