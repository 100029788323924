import React, { Component } from "react";
import { StyleSheet, View } from "react-native";
import { connect } from "react-redux";
import i18n from "@i18n/i18n";
import { EXPERIENCE } from "@custom-types/ExpercienceModel";
import { trimHelper } from "@utils/helpers/chat/chat.helper";
import ImageBase from "@components/image/ImageBase";
import { colors, settings } from "@styles/globalStyles";
import BoldText from "@base/BoldText";
import RegularText from "@base/RegularText";
import Row from "@base/Row";
import moment from "moment";
import { NavigationType } from "@custom-types/NavigationType";
import Card from "@base/Card";
import AvatarBase from "@components/avatar/AvatarBase";
import { ExperienceNavigatorScreens } from "@navigation/ExperienceNavigator";
import PressableBase from "@base/PressableBase";
import store from "@store/index";
import { loading } from "@store/actions/global";

interface Props {
    experience: EXPERIENCE;
    navigation: NavigationType;
    searchCard?: boolean
}

interface State {
    showDescription: boolean;
}

const { t } = i18n;

export class _ExperienceCard extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            showDescription: false,
        };
    }

    async componentDidMount() { }

    getDate = (start: Date | string, end: Date | string) => {
        const startDate = moment(start);
        const endDate = moment(end);

        if (startDate.isSame(endDate, "day")) {
            return startDate.format("DD/MM");
        }
        if (startDate.isSame(endDate, "month")) {
            return `${startDate.format("DD/MM")} to ${endDate.format("DD/MM")}`;
        }
        if (startDate.isSame(endDate, "year")) {
            return `${startDate.format("DD/MM")} to ${endDate.format("DD/MM")}`;
        }
        return `${startDate.format("DD/MM")} to ${endDate.format("DD/MM")}`
    }

    navigateToExperience = (experience) => {
        store.dispatch(loading())
        this.props.navigation.navigate('Experience', {
            screen: ExperienceNavigatorScreens.ExperienceDetail.routeName,
            params: { experience: experience },
        });
    }

    render() {
        return (
            <PressableBase
                onPress={() => this.navigateToExperience(this.props.experience)}
                style={styles.sectionCard}
            >
                <View>
                    <ImageBase
                        style={{ height: 180 }}
                        uri={this.props.experience?.image?.carousel || this.props.experience?.image?.cover || ""}
                        autoSizeWidth={true}
                    ></ImageBase>
                </View>
                <View style={styles.tag}>
                    <Row style={{ justifyContent: "center", alignItems: "center" }}>
                        <AvatarBase uri={this.props.experience.merchant?.image?.thumbnail} size={18}></AvatarBase>
                        <RegularText
                            style={{ paddingHorizontal: 5 }}
                            color={colors.labelText || colors.white}
                            fontSize={12}
                        >
                            {trimHelper(this.props.experience.merchant?.name, 20)}
                        </RegularText>
                    </Row>
                </View>
            </PressableBase>
        )
    }
}

const styles = StyleSheet.create({
    sectionCard: {
        borderRadius: settings.cardRadius,
        marginRight: 10,
        overflow: "hidden",
    },
    tag: {
        position: "absolute",
        bottom: 10,
        left: 10,
        backgroundColor: colors.secondary,
        paddingHorizontal: 6,
        paddingVertical: 4,
        borderRadius: 20,
    }
});

const mapStateToProps = (state) => {
    return {
        client: state.auth.client,
    };
};

const mapDispatchToProps = (dispatch) => ({});

const ExperienceCard = connect(mapStateToProps, mapDispatchToProps)(_ExperienceCard);

export default ExperienceCard;
