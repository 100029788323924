const lightColors = {
    primary: "#d8d9db",
    secondary: "#99aa8e",
    complementary: "#99aa8e",
    tertiary: "#FFFFFF",
    background: "#F6F6F6",
    gradientFrom: "#F6F6F6",
    gradientTo: "#F6F6F6",
    grey: "#98999c",
    white: "#000000",
    black: "#333",
    blue: "#2696fb",
    green: "#1DBC60",
    yellow: "#FECA54",
    red: "#EE4540",
    text: "#434c47",
    shadow: "#rgba(153, 170, 142, 0.1)",
    secondaryShadow: "rgba(153, 170, 142, 0.7)",
    tertiaryShadow: "rgba(153, 170, 142, 0.4)",
    highlightedText: "#363853",
    networkSymbol: "#fff",
    labelText: "white",
    latamxo1: "#ceafd6",
    latamxo2: "#333",
    floatButtonText: "#000000",
    avatarBase: "#99aa8e",
};

const darkColors = lightColors;

const settings = {
    // gradientLocations: [0, 0.7],
};

export { lightColors, darkColors, settings };
