import RegularText from "@base/RegularText";
import Row from "@base/Row";
import { colors, settings } from "@styles/globalStyles";
import React, { Component } from "react";
import { View, StyleSheet, Animated, Image, Dimensions, Easing } from "react-native";
import Icon from "@components/icons";
import BoldText from "@base/BoldText";
import Post from "@custom-types/Post";
import SocialNetworkService from "@screens/social/service/SocialNetworkService";
import { NavigationType } from "@custom-types/NavigationType";
import store from "@store/index";
import { trimHelper } from "@utils/helpers/chat/chat.helper";
import AvatarBase from "@components/avatar/AvatarBase";
import DoubleTap from "@base/DoubleTap";
import { addDiscoverPosts, addMainPosts } from "@store/actions/social.actions";
import { Client } from "@custom-types/Client";
import i18n from "@i18n/i18n";
import RewardSign from "./RewardSign";
import { setTimeVariables } from "@utils/helpers/social/social.helper";
import ImageBase from "@components/image/ImageBase";
import { UsersViewType } from "@custom-types/UsersViewType";
import { ProfileNavigatorScreens } from "@navigation/ProfileNavigator";
import Constants from "expo-constants";
import ShareService from "@core/services/ShareService";
import PressableBase from "@base/PressableBase";
import { ModuleControlService } from "@core/services/ModuleControlService";
import { VibrationType, vibrate } from "@utils/vibration/vibration.helper";
import { hideModalBottom } from "@store/actions/global";
import ProfileService from "@core/services/ProfileService";

interface Props {
    navigation: NavigationType;
    post: Post;
    onFollow?: (clientID) => any;
    isLikeable?: boolean;
    modalView?: boolean;
    fromProfile?: boolean;
}

interface State {
    client: Client;
    isOwner: boolean;
    description: string;
    seeMore: boolean;
    liked: boolean;
    heartOnLike: boolean;
    heartSize: any;
    imageHight: number;
    imageWidth: number;
    options: boolean;
    deleted: boolean;
    // adsRandom: number
    reported: boolean;
    date: FunctionStringCallback | string;
    unmountGetSize: boolean;
    redirectUrl: string;
    linkCopied: string;
}

const { t } = i18n;

export default class PostCard extends Component<Props, State> {
    socialNetworkService: SocialNetworkService;
    protected fadeAnimHeartSizeStart = 0;

    constructor(props: Props) {
        super(props);

        const clientId = store.getState().auth.client.id;

        this.state = {
            client: store.getState().auth.client,
            isOwner: clientId == this.props.post.author.clientID ? true : false,
            description: this.helpLengthDescription(this.props.post.description),
            seeMore: this.props.post.description.length > 128 ? true : false,
            liked: this.props.post.likes.liked,
            heartOnLike: false,
            heartSize: new Animated.Value(this.fadeAnimHeartSizeStart),
            imageHight: 300,
            imageWidth: 0,
            options: false,
            deleted: false,
            // adsRandom: Math.floor(Math.random() * (5 - 1) + 1),
            reported: this.props.post.reported ? this.props.post.reported : false,
            date: setTimeVariables(this.props.post.date),
            unmountGetSize: false,
            redirectUrl: `${ModuleControlService.getInstance().getShareUrl()}?type=post&id=${this.props.post.id}` || "",
            linkCopied: t("copy_link"),
        };
        this.onPressSeeMore = this.onPressSeeMore.bind(this);
        this.onLike = this.onLike.bind(this);

        this.openProfile = this.openProfile.bind(this);
        this.setImage = this.setImage.bind(this);
        this.socialNetworkService = SocialNetworkService.getInstance();
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (this.state != nextState) {
            return true;
        }
        if (nextProps != this.props) {
            return true;
        }

        return false;
    }

    componentDidMount() {
        if (!this.state.unmountGetSize) {
            Image.getSize(this.props.post.image ? this.props.post.image.square : "", (width, height) =>
                this.setImage(width, height),
            );
        }
    }

    animate(animations: { value: Animated.Value; config: Partial<Animated.TimingAnimationConfig> }[]) {
        animations.map((animation) => {
            Animated.timing(animation.value, {
                ...animation.config,
                toValue: animation.config.toValue,
                useNativeDriver: false,
            }).start();
        });
    }

    helpLengthDescription(description) {
        description = description.length > 120 ? `${description.substring(0, 119)}...` : description;
        return description;
    }

    onPressSeeMore() {
        this.setState({
            seeMore: false,
            description: this.props.post.description,
        });
    }

    async onLike() {
        if (this.props.isLikeable) {
            var liked = this.state.liked;
            this.setState({
                liked: !liked,
                heartOnLike: !liked,
            });

            this.animate([
                {
                    value: this.state.heartSize,
                    config: {
                        toValue: 1,
                        duration: 700,
                        easing: Easing.elastic(3),
                    },
                },
            ]);

            setTimeout(() => {
                this.setState({ heartOnLike: false });
            }, 850);

            //this.setMainPosts(liked)
            if (!liked) {
                vibrate(VibrationType.SUCCESS);
            }
            this.socialNetworkService.likePost(this.props.post.id, liked, this.props.post.author.clientID)
        }
    }

    setMainPosts(liked) {
        const posts: Array<Post> = store.getState().social.mainPosts;
        if (posts) {
            posts.map((item: Post) => {
                if (item.id == this.props.post.id) {
                    if (item.likes.amount >= 1) {
                        return (item.likes.amount = liked ? item.likes.amount - 1 : item.likes.amount + 1);
                    } else {
                        return (
                            (item.likes.amount = liked ? item.likes.amount - 1 : item.likes.amount + 1),
                            (item.likes.firstLike.clientID = this.state.client._id),
                            (item.likes.firstLike.alias = this.state.client.alias),
                            (item.likes.firstLike.avatar = this.state.client.profileImagePath
                                ? this.state.client.profileImagePath.thumbnail
                                : "")
                        );
                    }
                }
            });

            store.dispatch(addMainPosts(posts));
        }

        const discoverPosts: Array<Post> = store.getState().social.discoverPosts;

        if (discoverPosts) {
            discoverPosts.map((item: Post) => {
                if (item.id == this.props.post.id) {
                    if (item.likes.amount >= 1) {
                        return (item.likes.amount = liked ? item.likes.amount - 1 : item.likes.amount + 1);
                    } else {
                        return (
                            (item.likes.amount = liked ? item.likes.amount - 1 : item.likes.amount + 1),
                            (item.likes.firstLike.clientID = this.state.client._id),
                            (item.likes.firstLike.alias = this.state.client.alias),
                            (item.likes.firstLike.avatar = this.state.client.profileImagePath
                                ? this.state.client.profileImagePath.thumbnail
                                : "")
                        );
                    }
                }
            });

            store.dispatch(addDiscoverPosts(discoverPosts));
        }
        this.forceUpdate();
    }

    openProfile = () => {
        store.dispatch(hideModalBottom());
        this.props.navigation.navigate("Profile", {
            screen: ProfileNavigatorScreens.ProfileMain.routeName,
            params: {
                clientID: this.props.post.author.clientID,
            },
        });
    };

    setImage = (width, height) => {
        const screenWidth = Dimensions.get("window").width < 600 ? Dimensions.get("window").width : 600;
        const autoHight = width / screenWidth;
        this.setState({ imageHight: height / autoHight, imageWidth: screenWidth, unmountGetSize: true });
    };

    onDelete = async () => {
        const delet = await this.socialNetworkService.deletePost(this.props.post.id);
        if (delet) {
            this.setState({ deleted: true });
        }
    };

    onReport = async () => {
        const report = await this.socialNetworkService.reportPost(this.props.post.id);
        this.setState({ reported: true, options: false });
    };

    openLikes = (UsersViewType) => {
        this.props.navigation.navigate("Users", {
            UsersViewType: UsersViewType,
            postID: this.props.post.id,
        });
    };

    handleShare = async () => {
        ShareService.getInstance().handleShare(t("check_post"), this.state.redirectUrl);
    };

    render() {
        return (
            <View style={{ marginBottom: 20, marginHorizontal: 15 }}>
                {!this.state.deleted && (
                    <View style={styles.wrapper}>
                        {/* USER */}
                        <Row style={{ width: "100%", justifyContent: "space-between" }}>
                            <Row style={{ alignItems: "center" }}>
                                <PressableBase
                                    onPress={this.openProfile}
                                    style={{
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignContent: "center",
                                    }}
                                >
                                    <AvatarBase
                                        uri={this.props.post.author.avatar ? this.props.post.author.avatar : ""}
                                        alias={this.props.post.author.alias}
                                        size={35}
                                    />

                                    <RegularText style={{ marginLeft: 10, textAlignVertical: "center" }}>
                                        @{trimHelper(this.props.post.author.alias)}
                                    </RegularText>

                                    {/* <VerifiedIcon verified={this.props.post.author.verified} /> */}
                                </PressableBase>

                                {!this.state.isOwner &&
                                    !this.props.post.author.follow &&
                                    !this.state.options &&
                                    this.props.onFollow && (
                                        <View style={{ alignItems: "center", marginLeft: 10 }}>
                                            <PressableBase
                                                onPress={() => this.props.onFollow(this.props.post.author.clientID)}
                                            >
                                                <RegularText fontSize={14} style={styles.follow}>
                                                    {t("follow")}
                                                </RegularText>
                                            </PressableBase>
                                        </View>
                                    )}
                            </Row>

                            <PressableBase
                                style={{
                                    marginRight: 0,
                                    overflow: "hidden",
                                    backgroundColor: this.state.options ? colors.secondary : "transparent",
                                    height: 32,
                                    width: 32,
                                    borderRadius: 16,
                                    justifyContent: "center",
                                }}
                                onPress={() => this.setState({ options: !this.state.options })}
                            >
                                <Icon
                                    name="three-dots-vertical"
                                    size={16}
                                    color={!this.state.options ? colors.text : colors.white}
                                    style={{ alignSelf: "center" }}
                                />
                            </PressableBase>

                            {this.state.options && (
                                <View
                                    style={{
                                        position: "absolute",
                                        overflow: "hidden",
                                        zIndex: 9999,
                                        right: 25,
                                        top: 28,
                                        backgroundColor: colors.secondary,
                                        borderRadius: 15,
                                        borderTopRightRadius: 5,
                                        padding: 15,
                                    }}
                                >
                                    {this.state.isOwner ? (
                                        <PressableBase onPress={this.onDelete}>
                                            <Row>
                                                <Icon
                                                    name={"trash"}
                                                    size={18}
                                                    color={colors.white}
                                                    style={{ marginRight: 10, marginTop: 2 }}
                                                ></Icon>
                                                <RegularText color={colors.white}> {t("delete")}</RegularText>
                                            </Row>
                                        </PressableBase>
                                    ) : (
                                        <PressableBase onPress={this.onReport}>
                                            <Row>
                                                <Icon
                                                    name={"eye-slash"}
                                                    size={18}
                                                    color={colors.white}
                                                    style={{ marginRight: 8, marginTop: 2 }}
                                                ></Icon>
                                                <RegularText color={colors.white}> {t("report")}</RegularText>
                                            </Row>
                                        </PressableBase>
                                    )}
                                </View>
                            )}
                        </Row>

                        {/* IMAGE */}
                        <View style={{ marginTop: 10, zIndex: -1, marginHorizontal: -15 }}>
                            <RewardSign rewards={this.props.post.rewards} />
                            {!this.state.reported && (
                                <DoubleTap delay={350} doubleTap={this.onLike}>
                                    <ImageBase
                                        uri={this.props.post.image?.square}
                                        style={{
                                            width: this.props.modalView ? 'auto' : this.state.imageWidth,
                                            maxWidth: 600,
                                            height: this.state.imageHight,
                                            maxHeight: 700,
                                            overflow: "hidden",
                                            resizeMode: "cover",
                                        }}
                                    />
                                </DoubleTap>
                            )}

                            {this.state.reported && (
                                <View>
                                    <PressableBase onPress={() => this.setState({ reported: false })}>
                                        <ImageBase
                                            uri={this.props.post.image?.square}
                                            blurRadius={10}
                                            style={{
                                                width: this.state.imageWidth,
                                                maxWidth: 600,
                                                height: this.state.imageHight,
                                                maxHeight: 700,
                                                overflow: "hidden",
                                                resizeMode: "cover",
                                            }}
                                        />
                                        <Icon
                                            name={"eye-slash"}
                                            size={80}
                                            color={colors.white}
                                            style={{
                                                position: "absolute",
                                                opacity: 0.6,
                                                top: this.state.imageHight / 2 - 50,
                                                left: this.state.imageWidth / 2 - 50,
                                            }}
                                        />
                                    </PressableBase>
                                </View>
                            )}

                            {this.state.heartOnLike && (
                                <Animated.View
                                    style={{
                                        position: "absolute",
                                        top: this.state.imageHight / 2 - 50,
                                        left: this.state.imageWidth / 2 - 50,
                                        width: 120,
                                        height: 120,
                                        overflow: "hidden",
                                        borderRadius: 40,
                                        opacity: 0.8,
                                        scaleX: this.state.heartSize,
                                        scaleY: this.state.heartSize,
                                        transform: [{ scale: this.state.heartSize }, { perspective: 1000 }],
                                    }}
                                >
                                    <Icon
                                        name="heart-fill"
                                        size={100}
                                        color={colors.secondary}
                                        style={{ alignSelf: "center" }}
                                    />
                                </Animated.View>
                            )}
                            {this.state.date && (
                                <RegularText
                                    fontSize={12}
                                    style={{
                                        position: "absolute",
                                        top: 10,
                                        right: 10,
                                        backgroundColor: colors.shadow,
                                        paddingHorizontal: 6,
                                        borderRadius: 4,
                                        paddingVertical: 2,
                                        overflow: "hidden",
                                    }}
                                >
                                    {this.state.date}
                                </RegularText>
                            )}

                            <View
                                style={{
                                    position: "absolute",
                                    bottom: 10,
                                    paddingHorizontal: 5,
                                    maxWidth: 600,
                                    width: "100%",
                                    flexDirection: "row",
                                    justifyContent: "flex-start",
                                }}
                            >
                                {this.props.post.hashtag &&
                                    this.props.post.hashtag.map((hashtag) => {
                                        return (
                                            <RegularText fontSize={12} style={styles.hashtag} key={hashtag.name}>
                                                #{hashtag.name}
                                            </RegularText>
                                        );
                                    })}
                            </View>
                        </View>

                        {/* BUTTONS */}
                        <Row style={{ marginTop: 10 }}>
                            <View style={styles.left}>
                                <View style={{ flexDirection: "row" }}>
                                    {this.props.isLikeable && !this.props.fromProfile && (
                                        <PressableBase style={{ marginTop: 5 }} onPress={this.onLike}>
                                            {this.props.post.likes.liked ? (
                                                <Icon
                                                    name="heart-fill"
                                                    size={26}
                                                    color={colors.red}
                                                    style={{ alignSelf: "center" }}
                                                />
                                            ) : (
                                                <Icon
                                                    name="heart"
                                                    size={26}
                                                    color={colors.text}
                                                    style={{ alignSelf: "center" }}
                                                />
                                            )}
                                        </PressableBase>
                                    )}
                                    {this.props.isLikeable && this.props.fromProfile && (
                                        <PressableBase style={{ marginTop: 5 }} onPress={this.onLike}>
                                            {this.state.liked ? (
                                                <Icon
                                                    name="heart-fill"
                                                    size={26}
                                                    color={colors.red}
                                                    style={{ alignSelf: "center" }}
                                                />
                                            ) : (
                                                <Icon
                                                    name="heart"
                                                    size={26}
                                                    color={colors.text}
                                                    style={{ alignSelf: "center" }}
                                                />
                                            )}
                                        </PressableBase>
                                    )}
                                    <PressableBase style={{ marginTop: 5, marginLeft: 15 }} onPress={this.handleShare}>
                                        <Icon
                                            name="share"
                                            size={24}
                                            color={colors.text}
                                            style={{ alignSelf: "center" }}
                                        />
                                    </PressableBase>
                                </View>
                            </View>
                        </Row>

                        {/* LIKES */}

                        {this.props.post.likes && this.props.post.likes.amount >= 2 && (
                            <PressableBase
                                style={{ marginTop: 10 }}
                                onPress={() => this.openLikes(UsersViewType.LIKES)}
                            >
                                <Row>
                                    <AvatarBase
                                        uri={
                                            this.props.post.likes.firstLike.avatar
                                                ? this.props.post.likes.firstLike.avatar
                                                : ""
                                        }
                                        alias={this.props.post.likes.firstLike.alias}
                                        size={16}
                                    />

                                    <RegularText fontSize={12} style={{ textAlignVertical: "center", marginLeft: 5 }}>
                                        {t("liked_by")} @{this.props.post.likes.firstLike.alias} {t("and")}{" "}
                                        {this.props.post.likes.amount} {t("others")}
                                    </RegularText>
                                </Row>
                            </PressableBase>
                        )}

                        {this.props.post.likes && this.props.post.likes.amount == 1 && (
                            <PressableBase
                                style={{ marginTop: 10 }}
                                onPress={() => this.openLikes(UsersViewType.LIKES)}
                            >
                                <Row>
                                    <AvatarBase
                                        uri={
                                            this.props.post.likes.firstLike.avatar
                                                ? this.props.post.likes.firstLike.avatar
                                                : ""
                                        }
                                        alias={this.props.post.likes.firstLike.alias}
                                        size={16}
                                    />

                                    <RegularText fontSize={12} style={{ textAlignVertical: "center", marginLeft: 5 }}>
                                        {t("liked_by")} @{this.props.post.likes.firstLike.alias}
                                    </RegularText>
                                </Row>
                            </PressableBase>
                        )}

                        {/* DESCRIPTION */}

                        <View style={{ marginTop: 10 }}>
                            <RegularText align={"justify"} fontSize={14} color={colors.grey}>
                                <BoldText style={{ fontWeight: "600" }} fontSize={14}>
                                    @{trimHelper(this.props.post.author.alias)}
                                </BoldText>{" "}
                                {this.state.description}{" "}
                                {this.state.seeMore && (
                                    <PressableBase
                                        onPress={this.onPressSeeMore}
                                        style={{
                                            //backgroundColor: colors.red,
                                            height: 18,
                                            padding: 0,
                                        }}
                                    >
                                        <RegularText align="center" style={{ color: "#4796fb" }}>
                                            {" "}
                                            {t("see_more")}
                                        </RegularText>
                                    </PressableBase>
                                )}
                            </RegularText>
                        </View>
                    </View>
                )}
                {/* ADS */}
                {/* <Ads navigation={this.props.navigation} adsRandom={this.state.adsRandom} /> */}
            </View>
        );
    }
}

const styles = StyleSheet.create({
    wrapper: {
        paddingVertical: 5,
        maxWidth: 600,
        width: "100%",
        marginHorizontal: "auto",
        alignSelf: "center",
        marginVertical: 20,
    },

    row: {
        justifyContent: "flex-start",
        width: "100%",
        alignItems: "center",
    },

    left: {
        flexDirection: "row",
        flex: 0.5,
    },

    right: {
        flexDirection: "row",

        flex: 0.5,
        justifyContent: "flex-end",
        alignItems: "flex-end",
    },

    follow: {
        textAlignVertical: "center",
        alignSelf: "center",
        textAlign: "center",
        borderWidth: 1,
        borderColor: colors.secondary,
        marginLeft: 20,
        paddingHorizontal: 15,
        borderRadius: 15,
        paddingVertical: 5,
        overflow: "hidden",
    },
    hashtag: {
        backgroundColor: colors.shadow,
        color: colors.text,
        marginRight: 10,
        alignSelf: "center",
        paddingHorizontal: 10,
        paddingVertical: 4,
        borderRadius: 10,
        textAlign: "center",
        overflow: "hidden",
    },
});
