import store from "@store/index";
import axios from "axios";
import { ApiService } from "@core/services/ApiService";
import { getEnv } from "@utils/helpers/global/global";
import { showSnackbar } from "@store/actions/global";
import i18n from "@i18n/i18n";
import { setActivity } from "@store/actions/activity.actions";
const { t } = i18n;

export default class ActivityService {
    private static instance: ActivityService;

    constructor() { }

    static getInstance(): ActivityService {
        if (!ActivityService.instance) {
            ActivityService.instance = new ActivityService();
        }
        return ActivityService.instance;
    }

    async getActivity() {
        try {
            const url = `${getEnv("API_URL")}client/activity-log`;

            const response = await axios.get(url, {
                headers: await ApiService.getAuthHeaders(),
            });

            store.dispatch(setActivity(response.data.docs))

            return response.data.docs
        } catch (error) {
            store.dispatch(
                showSnackbar({
                    type: "ERROR",
                    message: t("an_error_has_occurred"),
                }),
            );
            console.warn(error);
        }
    }

    async getOrder(orderId: string) {
        try {
            const url = `${getEnv("API_URL")}client/orders/redeemables/${orderId}`;

            const response = await axios.get(url, {
                headers: await ApiService.getAuthHeaders(),
            });

            return response.data
        } catch (error) {
            store.dispatch(
                showSnackbar({
                    type: "ERROR",
                    message: t("an_error_has_occurred"),
                }),
            );
            console.warn(error);
        }
    }
}
