import { ActivityModel } from "@custom-types/ActivityModel";
import { SET_ACTIVITY } from "@store/actions/activity.actions";

interface state {
    activity: Array<ActivityModel>;
}

const initialState: state = {
    activity: [],
};

const activityReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_ACTIVITY:
            return {
                ...state,
                activity: [...action.activity],
            };

        default:
            return state;
    }
};

export default activityReducer;
