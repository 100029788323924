import React, { Component } from "react";
import { BackHandler, Platform, RefreshControl, StyleSheet, FlatList, View } from "react-native";
import { colors } from "@styles/globalStyles";
import { Header } from "@components/header/Header";
import Container from "@base/Container";
import { HeaderType } from "@custom-types/HeaderType";
import { NavigationType } from "@custom-types/NavigationType";
import { connect } from "react-redux";
import store from "@store/index";
import { selectChat } from "@store/actions/chat.actions";
import { Chat } from "@custom-types/Chat";
import { ChatService } from "@core/services/ChatService";
import { Contact } from "@custom-types/Contact";
import SearchBarChat from "@components/chat/SearchBarChat";
import { Client } from "@custom-types/Client";
import { ContactService } from "@core/services/ContactService";
import { hideActions, showActions } from "@store/actions/header";
import { selectOrderedAndFilteredChats } from "@store/reducers/chat.reducer";
import ScreenWrapper from "@components/wrapper/ScreenWrapper";
import i18n from "@i18n/i18n";
import { ChatMessageCard } from "@components/cards/ChatMessageCard";
import { MessagesNavigatorScreens } from "@navigation/MessagesNavigator";
import RegularText from "@base/RegularText";
import { getColorOpacity } from "@utils/helpers/global/global";
import { trimHelper } from "@utils/helpers/chat/chat.helper";
import { loading, ready } from "@store/actions/global";

interface Props {
    status: "connected" | "disconnected" | string;
    navigation: NavigationType;
    selectedChat: Chat;
    chats: Array<Chat>;
    client: Client;
    search: string;
}

interface State {
    aliasClients: Array<any>;
    contacts: Array<Contact>;

    selectedChat: Chat;
    syncing: boolean;
    list: Array<any>;
    clearSearch: boolean;
}

const { t } = i18n;

export class _ChatListScreen extends Component<Props, State> {
    socket: any;
    alias: any;
    focusListener: any;
    chatService: ChatService;
    contactService: ContactService;
    backHandler: any;

    constructor(props: Props) {
        super(props);
        this.state = {
            contacts: [],
            aliasClients: [],
            selectedChat: null,
            syncing: true,
            list: this.props.chats,
            clearSearch: false,
        };

        this.deselectChat = this.deselectChat.bind(this);
        this.onEndReached = this.onEndReached.bind(this);
        this.onPressChat = this.onPressChat.bind(this);
        this.onLongPressChat = this.onLongPressChat.bind(this);
        this.onRefresh = this.onRefresh.bind(this);
        this.clearSearch = this.clearSearch.bind(this);
        if (Platform.OS != "web") {
            this.backHandler = BackHandler.addEventListener("hardwareBackPress", () => {
                this.setState({ selectedChat: null });
                store.dispatch(hideActions());
                return false;
            });
        }
    }

    async componentDidMount() {
        this.chatService = await ChatService.getInstance();
        this.contactService = await ContactService.getInstance();
        await this.contactService.getContactsList();
        this.setState({
            list: this.props.chats,
            syncing: false,
        });

        this.chatService.updateAllMessagesToReceived();
    }

    componentWillUnmount() {
        try {
            this.backHandler.remove();
            this.focusListener.remove();
        } catch (e) {}
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.chats != this.props.chats) {
            this.setList({ chats: this.props.chats });
        }
    }

    onRefresh = async () => {
        store.dispatch(loading());
        this.setState({ syncing: true });
        this.clearSearch();
        this.chatService = await ChatService.getInstance();
        await this.chatService.refresh();
        this.contactService = await ContactService.getInstance();
        await this.contactService.getContactsList();

        this.chatService.updateAllMessagesToReceived();
        // this.setState({ syncing: false });
        store.dispatch(ready());
        setTimeout(() => {
            this.setState({
                list: this.props.chats,
                syncing: false,
            });
            store.dispatch(ready());
        }, 500);
    };

    onPressChat(id) {
        this.clearSearch();
        const chat = this.props.chats.find((item) => item.id === id);
        if (chat) {
            this.deselectChat();
            store.dispatch(selectChat(Object.assign({}, chat)));
            this.props.navigation.navigate(MessagesNavigatorScreens.Chat.routeName);
        }
    }

    deselectChat() {
        this.setState({ selectedChat: null });
        store.dispatch(hideActions());
    }

    selectChat(chat: Chat) {
        this.setState({ selectedChat: chat });
        store.dispatch(showActions());
    }

    onLongPressChat(chat: Chat) {
        if (this.state.selectedChat && this.state.selectedChat.id === chat.id) {
            this.deselectChat();
        } else {
            this.selectChat(chat);
        }
    }

    onEndReached = (info: { distanceFromEnd: number }) => {
        if (!this.state.aliasClients.length && this.props.chats.length > 9) {
            this.chatService.getAll().then();
        }
    };

    renderItem = ({ item, index }) => (
        <View style={{ opacity: this.state.syncing ? 0 : 1 }}>
            <ChatMessageCard
                item={item}
                navigation={this.props.navigation}
                onLongPressChat={this.onLongPressChat}
                onPressChat={this.onPressChat}
                selectedChat={this.state.selectedChat}
                clearSearch={this.clearSearch}
            />
        </View>
    );

    setList(items) {
        let chats = items.chats || this.props.chats;
        let contacts = items.contacts || this.state.contacts;
        let aliasClients = items.aliasClients || this.state.aliasClients;

        let list: any = chats;

        if (contacts.length) {
            list = list.concat([{ type: "separator", title: "Contacts", id: "contact_separator" }]).concat(contacts);
        }
        if (aliasClients.length) {
            list = list
                .concat([
                    {
                        type: "separator",
                        title: t("global_search"),
                        id: "alias_separator",
                    },
                ])
                .concat(aliasClients);
        }

        this.setState({
            contacts,
            aliasClients,
            list,
        });
    }

    clearSearch() {
        this.setState({ clearSearch: true });
        setTimeout(() => {
            this.setState({ clearSearch: false });
        }, 1000);
    }

    render() {
        return (
            <ScreenWrapper>
                <Header
                    title={t("chat")}
                    label={
                        <View
                            style={{
                                position: "absolute",
                                right: -30,
                                bottom: Platform.OS == "web" ? -3 : 0,
                            }}
                        >
                            <View
                                style={{
                                    paddingVertical: 2,
                                    paddingHorizontal: 6,
                                    backgroundColor:
                                        this.props.status == "connected"
                                            ? getColorOpacity(colors.green, 0.75)
                                            : getColorOpacity(colors.red, 0.75),
                                    borderRadius: 8,
                                    borderTopLeftRadius: 2,
                                    overflow: "hidden",
                                }}
                            >
                                <RegularText color={colors.white} align="center" fontSize={8}>
                                    {trimHelper(t(`${this.props.status}`), 15, true)}
                                </RegularText>
                            </View>
                        </View>
                    }
                    type={HeaderType.Light}
                    {...this.props}
                    leftAvatar={{
                        onPress: () => {
                            null;
                            {
                                this.props.navigation.navigate(MessagesNavigatorScreens.Profile.routeName);
                            }
                        },
                        uri:
                            this.props.client &&
                            this.props.client?.profileImagePath &&
                            this.props.client?.profileImagePath !== null
                                ? this.props.client?.profileImagePath.thumbnail
                                : "",
                        alias:
                            this.props.client && this.props.client?.alias !== null && this.props.client?.alias
                                ? this.props.client?.alias
                                : "",
                        size: 35,
                    }}
                    rightBtn={
                        Platform.OS == "web"
                            ? [
                                  {
                                      onPress: () => {
                                          this.onRefresh();
                                      },
                                      icon: "refresh",
                                      size: 22,
                                  },
                                  {
                                      onPress: () => {
                                          this.props.navigation.navigate(MessagesNavigatorScreens.Contacts.routeName);
                                      },
                                      icon: "person-list",
                                      iconType: "custom",
                                      size: 24,
                                  },
                              ]
                            : [
                                  {
                                      onPress: () => {
                                          this.props.navigation.navigate(MessagesNavigatorScreens.Contacts.routeName);
                                      },
                                      icon: "person-list",
                                      iconType: "custom",
                                      size: 24,
                                  },
                              ]
                    }
                    actions={[
                        {
                            onPress: async () => {
                                await this.chatService.remove(this.state.selectedChat.id);
                                this.deselectChat();
                            },
                            icon: "trash",
                            iconType: "custom",
                        },
                    ]}
                    hideActionsCallback={this.deselectChat}
                />

                <Container style={styles.container}>
                    <SearchBarChat
                        setList={(items) => this.setList(items)}
                        // setChats={(chats) => this.setList({ chats })}
                        // setContacts={(contacts) => this.setList({ contacts })}
                        // setAliasClient={(aliasClients) => this.setList({ aliasClients })}
                        clearSearch={this.state.clearSearch}
                    />
                    <FlatList
                        showsHorizontalScrollIndicator={false}
                        data={this.state.list}
                        renderItem={this.renderItem}
                        keyExtractor={(item) => item.id || item._id}
                        contentContainerStyle={{ paddingBottom: 20 }}
                        refreshControl={
                            <RefreshControl
                                tintColor={colors.text}
                                onRefresh={this.onRefresh}
                                refreshing={this.state.syncing}
                            />
                        }
                        initialNumToRender={10}
                        onEndReached={this.onEndReached}
                        onEndReachedThreshold={1}
                    />
                </Container>
            </ScreenWrapper>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        status: state.chat.status,
        chats: selectOrderedAndFilteredChats(state),
        messages: state.message.items,
        client: state.auth.client,
        search: state.global.search,
    };
};

const mapDispatchToProps = (dispatch) => ({});

const ChatListScreen = connect(mapStateToProps, mapDispatchToProps)(_ChatListScreen);

export default ChatListScreen;

const styles = StyleSheet.create({
    hidden: {
        display: "none",
    },
    wrapper: {
        flex: 1,
        zIndex: 1,
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        alignContent: "center",
        alignItems: "center",
        justifyContent: "center",
    },
    background: {
        position: "absolute",
        backgroundColor: colors.primary,
        opacity: 0.8,
        height: "100%",
        width: "100%",
    },
    input: {
        flex: 1,
        paddingVertical: 10,
        paddingHorizontal: 40,
    },
    icon: {
        position: "absolute",
        left: 10,
    },
    button: {
        position: "absolute",
        width: 30,
        height: 30,
        right: 15,
        alignItems: "center",
        justifyContent: "center",
        borderRadius: 10,
        borderColor: "white",
        borderWidth: 1,
    },
    container: {
        flex: 1,
        marginTop: 5,
    },
});
