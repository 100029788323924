import { colors } from "@styles/globalStyles";
import React, { Component, RefObject } from "react";
import { View, StyleSheet, ViewStyle, FlatList, ScrollView } from "react-native";
import * as Device from "expo-device";
import BoldText from "./BoldText";
import CircleButton from "./CircleButton";


interface Props {
    data: Array<any>;
    renderItem: (item) => JSX.Element;
    keyExtractor: (item) => JSX.Element;
    contentContainerStyle?: ViewStyle;
    columnWrapperStyle?: ViewStyle;
    initialNumToRender?: number;
    numColumns?: number;
    horizontal?: boolean;
    maxToRenderPerBatch?: number;
    showsHorizontalScrollIndicator?: boolean;
    ListEmptyComponent?: JSX.Element;
    ListHeaderComponent?: JSX.Element;
    refreshControl?: JSX.Element;
    onEndReachedThreshold?: number;
    onEndReached?: () => void;
    extraData?: any;
    onScrollToIndexFailed?: (info) => void;
}

interface State {
    device: string;
    index: number;
}

export default class FlatListBase extends Component<Props, State> {
    private flatListRef: RefObject<FlatList<any>>;
    constructor(props: Props) {
        super(props);
        this.flatListRef = React.createRef();
        this.state = {
            device: Device.DeviceType[1],
            index: 0,
        };
    }

    async componentDidMount() {
        try {
            const device = await Device.getDeviceTypeAsync();
            const devices = Device.DeviceType;
            this.setState({ device: devices[device] });
        } catch (e) {}
    }

    scrollToIndex(data) {
        if(data && this.flatListRef){
            this.flatListRef.current.scrollToIndex(data);
        }
    }

    scrollLeft = () => {
        this.setState({ index: this.state.index - 1 }, () => {
            this.flatListRef.current.scrollToIndex({ index: this.state.index, animated: true });
        });
    };

    scrollRight = () => {
        this.setState({ index: this.state.index + 1 }, () => {
            this.flatListRef.current.scrollToIndex({ index: this.state.index, animated: true });
        });
    };

    render() {
        return (
            <View style={{ flex: 1, justifyContent: "center" }}>
                {this.props.data?.length > 0 ? (
                    <FlatList

                        ref={this.flatListRef}
                        data={this.props.data || []}
                        renderItem={(item) => this.props.renderItem(item)}
                        keyExtractor={(item) => item._id}
                        numColumns={this.props.numColumns || 1}
                        contentContainerStyle={[this.props.contentContainerStyle, {}]}
                        initialNumToRender={this.props.initialNumToRender || 2}
                        horizontal={this.props.horizontal || false}
                        
                        // maxToRenderPerBatch={this.props.maxToRenderPerBatch || 10}
                        showsHorizontalScrollIndicator={this.props.showsHorizontalScrollIndicator || false}
                        //ListEmptyComponent={this.props.ListEmptyComponent}
                        onScrollToIndexFailed={this.props.onScrollToIndexFailed}
                        columnWrapperStyle={this.props.numColumns > 1 && this.props.columnWrapperStyle}
                        ListHeaderComponent={this.props.ListHeaderComponent}
                        onEndReachedThreshold={this.props.onEndReachedThreshold}
                        onEndReached={this.props.onEndReached}
                        extraData={this.props.extraData}
                        refreshControl={this.props.refreshControl}
                    />
                ) : (
                    <ScrollView refreshControl={this.props.refreshControl} style={[this.props.contentContainerStyle]}>
                        {this.props.ListHeaderComponent}
                        {this.props.ListEmptyComponent}
                    </ScrollView>
                )}

                {this.state.index > 0 && this.state.device == Device.DeviceType[3] && this.props.horizontal && (
                    <View style={styles.arrowLeft}>
                        <CircleButton
                            style={{ width: 40, height: 40 }}
                            iconSize={20}
                            icon={"arrow-left"}
                            onPress={this.scrollLeft}
                        ></CircleButton>
                    </View>
                )}
                {this.props.data?.length > 0 &&
                    this.state.index < this.props.data?.length - 1 &&
                    this.state.device == Device.DeviceType[3] &&
                    this.props.horizontal && (
                        <View style={styles.arrowRigh}>
                            <CircleButton
                                style={{ width: 40, height: 40 }}
                                iconSize={20}
                                icon={"right"}
                                onPress={this.scrollRight}
                            ></CircleButton>
                        </View>
                    )}
            </View>
        );
    }
}

const styles = StyleSheet.create({
    arrowLeft: {
        display: "flex",
        position: "absolute",
        zIndex: 9999,
        left: -3,
        opacity: 0.8,
    },
    arrowRigh: {
        display: "flex",
        position: "absolute",
        zIndex: 9999,
        right: -3,
        opacity: 0.8,
    },
});
