import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import authReducer from "@store/reducers/auth";
import walletReducer from "@store/reducers/wallet";
import createSagaMiddleware from "redux-saga";
import rootSaga from "@store/sagas";
import globalReducer from "./reducers/global";
import chatReducer from "./reducers/chat.reducer";
import headerReducer from "./reducers/header";
import { composeWithDevTools } from "redux-devtools-extension";
import { messageReducer } from "@store/reducers/message.reducer";
import socialReducer from "./reducers/social.reducer";
import kriptonMarketReducer from "./reducers/kriptonmarket.reducer";
import { getEnv } from "@utils/helpers/global/global";
import WalletConnectReducer from "./reducers/walletconnect.reducer";
import nftsReducer from "./reducers/nfts.reducer";
import notificationReducer from "./reducers/notification.reducer";
import profileReducer from "./reducers/profile.reducer";
import experiencesReducer from "./reducers/experiences.reducer";
import blogReducer from "./reducers/blogs.reducer";
import activityReducer from "./reducers/activity.reducer";
import bannersReducer from "./reducers/banners.reducer";

const rootReducer = combineReducers({
    global: globalReducer,
    auth: authReducer,
    wallet: walletReducer,
    chat: chatReducer,
    message: messageReducer,
    header: headerReducer,
    social: socialReducer,
    nfts: nftsReducer,
    profile: profileReducer,
    kriptonMarket: kriptonMarketReducer,
    walletconnect: WalletConnectReducer,
    notificationCenter: notificationReducer,
    banners: bannersReducer,
    blogs: blogReducer,
    experiences: experiencesReducer,
    activity: activityReducer,
});

const sagaMiddleware = createSagaMiddleware();
let com: any;

if (getEnv("ENV") == "PRODUCTION") {
    com = compose(applyMiddleware(sagaMiddleware));
} else {
    com = composeWithDevTools(applyMiddleware(sagaMiddleware));
}

const store = createStore(rootReducer, com);

sagaMiddleware.run(rootSaga);

export default store;
